import React, { useEffect, useRef } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/system';
import { UserProvider } from '../../contexts/UserContext.tsx';
// import { useAuth } from '../../hooks';
import useAuth from '../../hooks/useAuth.ts';
import AppBar from './AppBar.tsx';
import Container from './Container.tsx';
import Navigation from './Navigation.tsx';
import { ThemeProvider, Typography, createTheme } from '@mui/material';
import { ConfigProvider } from 'antd';
import Footer from '../../components/Footer.tsx';

function ProtectedLayout() {
  const { user, cognitoUserInfo, checkUser } = useAuth();
  const navRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    checkUser();
  }, []);

  // if (!user)
  //   return (
  //     <div
  //       className='loader-line'
  //       style={{
  //         width: '8%',
  //         marginLeft: '46%',
  //         marginTop: '35%',
  //         alignContent: 'center',
  //         textAlign: 'center',
  //         color: 'gray',
  //       }}
  //     />
  //   );
    const theme2 = createTheme({
    palette: {
      primary: {
        main: 'rgb(0, 0, 0)',
      },
      secondary: {
        main: 'rgb(0, 0, 0)',
      },
    },
  });

  // if (userLoading) return <Typography
  //     style={{width: '100%', textAlign: 'center', marginTop: 0, fontSize: 30, alignItems: 'center', alignContent: 'center'}}>
  //       <Typography
  //         color='black'
  //         style={{fontWeight: 'bold', fontSize: 12}}
  //       >
  //         HAUTEGRID
  //       </Typography>
  //     <div className='loader-line' style={{width: '8%',
  //     marginLeft: '46%', alignContent: 'center', textAlign: 'center'}}/>
  //     </Typography>

  // If user is not logged in, re-direct to home page
  if (!user) return <Navigate to='/login' replace />;

  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: 'black',
          borderRadius: 2,

          // Alias Token
        },
      }}
    >
      <ThemeProvider theme={theme2}>
    <UserProvider cognitoUserInfo={cognitoUserInfo}>
    <div>
      <AppBar />
      <Container>
        <Outlet />
        <Footer />
      </Container>
      {/* {isSmallScreen ? <Navigation ref={navRef} /> : null} */}
    </div>
    </UserProvider>
    </ThemeProvider>
    </ConfigProvider>

  );
}

export default ProtectedLayout;
