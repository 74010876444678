import { useMediaQuery, useTheme } from '@mui/system';
import { LinkedPost, PostType } from '../API.ts';
import { TaggedPost } from './types.ts';

export const toTitleCase = (text: string) => {
  if (text == null) {
    return null;
  }
  return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
};

export const IsMobile = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return isSmallScreen;
}

export const categories: { [key: string]: string } = {
  accessory: 'Accessory',
  bag: 'Bag',
  watch: 'Watch',
  jewelry: 'Jewelry',
  shoes: 'Shoes',
  clothing: 'Clothing',
};

export const colors: { [key: string]: string } = {
  dark: 'rgb(39, 39, 41)',
  'off-white': 'rgb(227, 225, 220)',
  'medium-gray': 'rgb(227, 225, 220)',
  'light-gray': 'rgb(167, 167, 170)',
  'dark-gray': 'rgb(71, 71, 73)',
};

export const conditions: {[key: string]: string} = {
  AA: 'AA: Like New or New. No signs of wear, is brand new or looks to be new.',
  A: 'A: Excellent Condition. May have small unnoticeable imperfections.',
  AB: 'AB: Great Condition. Has minor signs of wear, but still has a long life ahead.',
  B: 'B: Good Condition. Has been worn often with moderate signs of wear.',
  BC: 'BC: Well Loved. Has been worn very often and has noticeable signs of wear.'
}

export const designers: { [key: string]: string } = {
  alaia: 'Alaia',
  'alexander-mcqueen': 'Alexander McQueen',
  'amina-muaddi': 'Amina Muaddi',
  'anita-ko': 'Anita Ko',
  'audemars-piguet': 'Audemars Piguet',
  balenciaga: 'Balenciaga',
  balmain: 'Balmain',
  'bottega-veneta': 'Bottega Veneta',
  boucheron: 'Boucheron',
  breguet: 'Breguet',
  breitling: 'Breitling',
  'brunello-cucinelli': 'Brunello Cucinelli',
  burberry: 'Burberry',
  bvlgari: 'Bvlgari',
  'canada-goose': 'Canada Goose',
  cartier: 'Cartier',
  celine: 'Celine',
  chanel: 'Chanel',
  chaumet: 'Chaumet',
  chloe: 'Chloe',
  chopard: 'Chopard',
  'christian-dior': 'Christian Dior',
  'christian-louboutin': 'Christian Louboutin',
  'common-projects': 'Common Projects',
  damiani: 'Damiani',
  'david-yurman': 'David Yurman',
  delvaux: 'Delvaux',
  'dolce-&-gabbana': 'Dolce & Gabbana',
  'elizabeth-locke': 'Elizabeth Locke',
  fendi: 'Fendi',
  foundrae: 'Foundrae',
  'franck-muller': 'Franck Muller',
  'fred-paris': 'Fred Paris',
  'gianvito-rossi': 'Gianvito Rossi',
  givenchy: 'Givenchy',
  goyard: 'Goyard',
  'grand-seiko': 'Grand Seiko',
  gucci: 'Gucci',
  'harry-winston': 'Harry Winston',
  hamilton: 'Hamilton',
  hermes: 'Hermes',
  hublot: 'Hublot',
  'iwc-schaffhausen': 'IWC Schaffhausen',
  jacquemus: 'Jacquemus',
  'jaeger-lecoultre': 'Jaeger-LeCoultre',
  'john-hardy': 'John Hardy',
  'judith-leiber': 'Judith Leiber',
  loewe: 'Loewe',
  longines: 'Longines',
  'louis-vuitton': 'Louis Vuitton',
  'mach-&-mach': 'Mach & Mach',
  mackage: 'Mackage',
  'maison-margiela': 'Maison Margiela',
  'manolo-blahnik': 'Manolo Blahnik',
  'max-mara': 'Max Mara',
  messika: 'Messika',
  'miu-miu': 'Miu Miu',
  moncler: 'Moncler',
  mulberry: 'Mulberry',
  'off-white': 'Off White',
  omega: 'Omega',
  panerai: 'Panerai',
  'patek-philippe': 'Patek Philippe',
  piaget: 'Piaget',
  pomellato: 'Pomellato',
  prada: 'Prada',
  'proenza-schouler': 'Proenza Schouler',
  'rene-caovilla': 'Rene Caovilla',
  'rick-owens': 'Rick Owens',
  'roberto-coin': 'Roberto Coin',
  reformation: 'Reformation',
  rolex: 'Rolex',
  sandro: 'Sandro',
  'saint-laurent': 'Saint Laurent',
  'salvatore-ferragamo': 'Salvatore Ferragamo',
  'stella-mccartney': 'Stella McCartney',
  'tag-heuer': 'Tag Heuer',
  telfar: 'Telfar',
  'the-row': 'The Row',
  'thom-browne': 'Thom Browne',
  'tiffany-&-co.': 'Tiffany & Co.',
  'tom-ford': 'Tom Ford',
  'vacheron-constantin': 'Vacheron Constantin',
  'valentino-garavani': 'Valentino Garavani',
  'van-cleef-&-arpels': 'Van Cleef & Arpels',
  versace: 'Versace',
  vetements: 'Vetements',
  zenith: 'Zenith',
};

export const stripeSecretKey = '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';

export const truncateString = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text;
  }
  return `${text.substring(0, maxLength)}...`;
};

export const getDateDifference = (pastDate: Date) => {
  const currDate = new Date();
  const timeDifference = currDate.valueOf() - pastDate.valueOf();
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  if (days >= 7) {
    return `${weeks}w`;
  }
  if (days >= 1) {
    return `${days}d`;
  }
  if (hours >= 1) {
    return `${hours}h`;
  }
  if (minutes >= 1) {
    return `${minutes}m`;
  }
  return `${seconds}s`;
};

export const formatNumber = (n: number): string => {
  if (n >= 1000000) {
    return `${(n / 1000000).toFixed(1)}M`;
  }
  if (n >= 1000) {
    return `${(n / 1000).toFixed(1)}K`;
  }
  return n.toString();
};

export const condition = ['Brand New', 'Rarely Worn', 'Lightly Loved', 'Loved', 'Well Loved', 'Excellent Vintage', 'Great Vintage', 'Good Vintage', 'Well Loved Vintage']

export const allFields = {
  [PostType.OUTFIT]: ['caption'],
  [PostType.ITEM]: [
    'caption',
    'category',
    'designer',
    'name',
    'color',
    'material',
    'size',
    'isCapsule',
    'isSold',
    'purchasePrice',
    'sellingPrice',
    'isCompleted',
    'height',
    'width',
    'depth',
    'chainDrop',
    'interiorCondition',
    'exteriorCondition',
    'isVintage',
    'callouts',
    'oldPrice',
    'productionYear',
    'hardware',
    'dustbag',
    'box',
    'serialNumber',
    'authCard',
    'authenticityGuarantee'
  ],
};

export const allDropFields = {
  'dropFields': [
    'name',
    'description',
    'launchTime',
  ],
};

export const requiredDropFields = {
  'dropFields': [
    'name',
    'launchTime'
  ]
}

export const requiredFields = {
  [PostType.OUTFIT]: [],
  [PostType.ITEM]: ['category', 'designer', 'name', 'sellingPrice'],
};

export const getTaggedPostFromLinkedPost = (linkedPost: LinkedPost) =>
  ({
    post: linkedPost.taggedPost,
    marker: {
      left: linkedPost.pos.x,
      top: linkedPost.pos.y,
    },
  }) as TaggedPost;

export const buildSearchRegex = (query: string) => {
  // Remove punctuation and convert to lowercase
  const cleanedString = query.replace(/[^\w\s]/g, '').toLowerCase();

  // Split the cleaned string into words
  const words = cleanedString.split(/\s+/);

  // Build a regular expression with each word separated by '|'
  const regex = `.*${words.join('.*|.*')}.*`;

  return regex;
};
