import { generateClient } from 'aws-amplify/api';
import { Cache } from 'aws-amplify/utils';
import React, {
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Comment, Order, Post, AddressInfo } from '../API.ts';
import { useAuth, useS3SignedUrl } from '../hooks/index.ts';
import { getS3SignedUrl } from '../hooks/useS3SignedUrl.ts';
import { stripeSecretKey } from '../utils/common.ts';

const client=generateClient();
const stripe = require('stripe')(stripeSecretKey);

interface ViewOrderContextType {
  readonly loading: boolean;
  readonly order?: Order;
  readonly shippingAddress?: AddressInfo;
  readonly billingAddress?: AddressInfo;
  readonly orders?: Array<Order>;
  readonly setOrder: (order: Order) => void;
  readonly loadOrder: (auth_mode: string) => void;
  readonly setOrders: (orders: Array<Order>) => void;
  readonly loadOrders: (auth_mode: string, email: string) => void;
}

export const ViewOrderContext = createContext<ViewOrderContextType>({
  loading: true,
  setOrder: () => {},
  loadOrder: () => {},
  setOrders: () => {},
  loadOrders: () => {}
});

interface ViewOrderProviderProps {
  readonly id: string;
  readonly children: ReactNode;
}

const listOrders = /* GraphQL */ `
  query ListOrders(
    $buyerEmail: String
  ) {
    listOrders(
      filter: {buyerEmail: {eq: $buyerEmail}}
      ) {
      items {
        id
        sellerID
        postID
        buyerEmail
        buyerID
        items {
          items {
            id
            name
            designer
            sellingPrice
            images
          }
        }
        stripeProductIDs
        price
        tax
        checkoutSessionID
        shipping
        currency
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
        id
        sellerID
        postID
        buyerEmail
        buyerID
        items {
          items {
            id
            name
            designer
            sellingPrice
            images
          }
        }
        stripeProductIDs
        price
        tax
        checkoutSessionID
        shipping
        currency
        createdAt
        updatedAt
        owner
        __typename
      }
      __typename
    }
`;



export function ViewOrderProvider({ id, children }: ViewOrderProviderProps) {
  const [order, setOrder] = useState<Order>();
  const {checkUser, authMode} = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<Order[]>([]);
  const [shippingAddress, setShippingAddress] = useState<AddressInfo>();
  const [billingAddress, setBillingAddress] = useState<AddressInfo>();

  const loadOrders = useCallback(async (auth_mode, email) => {
    setLoading(true);
    const response = (await client.graphql({
      query: listOrders, variables: {
        buyerEmail: email,
      }, authMode: auth_mode}
    )) as any;
    setOrders(response.data.listOrders.items);
    setLoading(false);
  }, [id]);

  const loadOrder = useCallback(async (auth_mode) => {
    setLoading(true);
    const response = (await client.graphql({
      query: getOrder, variables: {
        id: id,
      }, authMode: auth_mode}
    )) as any;
    setOrder(response.data.getOrder);
    console.log(response.data.getOrder);
    if (response.data.getOrder){
      const checkoutSession = await stripe.checkout.sessions.retrieve(
        response.data.getOrder.checkoutSessionID
      );
      console.log(checkoutSession);
      let shipTemp = checkoutSession.shipping_details.address;
      shipTemp.name = checkoutSession.shipping_details.name;
      let billTemp = checkoutSession.customer_details.address;
      billTemp.name = checkoutSession.customer_details.name;
      setShippingAddress(shipTemp);
      setBillingAddress(billTemp);
    }
    setLoading(false);
  }, [id, setShippingAddress, setBillingAddress, setOrder]);

  const value = useMemo(
    () => ({
      loading,
      order,
      shippingAddress,
      billingAddress,
      setOrder,
      loadOrder,
      orders,
      setOrders,
      loadOrders
    }),
    [
      loading,
      order,
      shippingAddress,
      billingAddress,
      setOrder,
      loadOrder,
      orders,
      setOrders,
      loadOrders
    ]
  );

  return (
    <ViewOrderContext.Provider value={value}>
      {children}
    </ViewOrderContext.Provider>
  );
}
