import { RcFile } from 'antd/es/upload';
import { generateClient } from 'aws-amplify/api';
import { uploadData } from 'aws-amplify/storage';
import React, {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  CommentableType,
  CreateLikeInput,
  CreateLinkedUserInput,
  CreateUserInput,
  DeleteLikeInput,
  DeleteLinkedUserInput,
  // Drop,
  LikeableType,
  UpdateUserInput,
  User,
} from '../API';
import * as mutations from '../graphql/mutations.js';
import {
  useAuth,
  // useComment,
  // useFollowee,
  // useLike,
  useS3SignedUrl,
} from '../hooks/index.ts';
// import useNotifications from '../hooks/useNotifications';
import {
  CognitoUserInfo,
  DatabaseUserAttributes,
  NotificationsByIsRead,
  NotificationsLoadedByIsRead,
} from '../utils/types';
import { FetchUserAttributesOutput } from 'aws-amplify/auth';
import { AuthContext } from './AuthContext.tsx';

const client = generateClient();

const getUserQuery = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(
      id: $id
    ) {
      id
      cognitoId
      email
      firstName
      middleName
      lastName
      phone
      bio
      avatar
      numFollowees
      numFollowers
      drops {
        items {
          id
          userId
          name
          description
          launchTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
    }
  }
`;


interface UserContextType {
  readonly userId?: string;
  readonly user?: User;
  readonly userLoading: boolean | undefined;
  readonly nextDrop;
  readonly avatarUrl?: string;
  readonly loadingAvatar: boolean | undefined;
  // readonly followees: { [key: string]: User };
  // readonly followeesLoading: boolean;
  // readonly likes: { [key: string]: string[] };
  // readonly likesLoading: boolean;
  // readonly notifications: NotificationsByIsRead;
  // readonly notificationsLoading: boolean;
  // readonly notificationsLoaded: NotificationsLoadedByIsRead;
  readonly updateAvatar: (file: File) => void;
  readonly updateUserAttributes: (
    attributes: Omit<DatabaseUserAttributes, 'email' | 'phone'>
  ) => void;
  // readonly follow: (followee: User) => void;
  // readonly unfollow: (followee: User) => void;
  // readonly like: (type: LikeableType, id: string) => void;
  // readonly unlike: (type: LikeableType, id: string) => void;
  // readonly postComment: (
  //   type: CommentableType,
  //   id: string,
  //   text: string
  // ) => void;
  // readonly loadReadNotifications: () => void;
  // readonly markNotificationsAsRead: (ids: string[]) => void;
}

export const UserContext = createContext<UserContextType>({
  userId: '',
  userLoading: true,
  nextDrop: {},
  loadingAvatar: false,
  // followees: {},
  // followeesLoading: true,
  // likes: {},
  // likesLoading: true,
  // notifications: { true: [], false: [] },
  // notificationsLoading: true,
  // notificationsLoaded: { true: false, false: false },
  updateAvatar: () => {},
  updateUserAttributes: () => {},
  // follow: () => {},
  // unfollow: () => {},
  // like: () => {},
  // unlike: () => {},
  // postComment: () => {},
  // loadReadNotifications: () => {},
  // markNotificationsAsRead: () => {},
});

interface UserProviderProps {
  readonly cognitoUserInfo: FetchUserAttributesOutput | null | undefined;
  readonly children: ReactNode;
}

export function UserProvider({ cognitoUserInfo, children }: UserProviderProps) {
  const { user: authUser, authMode } = useAuth();
  const [user, setUser] = useState<User>();
  const [userLoading, setUserLoading] = useState<boolean>();
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [nextDrop, setNextDrop] = useState();
  const [loadingAvatar, setLoadingAvatar] = useState<boolean>();
  // const {
  //   followees,
  //   loading: followeesLoading,
  //   loadAll: loadAllFollowees,
  //   remove: removeFollowee,
  // } = useFollowee(userId);
  // const {
  //   likes,
  //   loading: likesLoading,
  //   loadAllLikes,
  //   addLike,
  //   deleteLike,
  // } = useLike(userId);
  // const { postComment } = useComment(userId);
  // const {
  //   notifications,
  //   loaded: notificationsLoaded,
  //   loading: notificationsLoading,
  //   loadAllUnreadNotifications,
  //   loadReadNotifications,
  //   markNotificationsAsRead,
  // } = useNotifications(userId);
  const { url: avatarUrl, invalidate: invalidateAvatarUrl } = useS3SignedUrl(
    user?.avatar,
    'public'
  );

  const loadUser = useCallback(async (authMode) => {
    setUserLoading(true);
    console.log('HERE', cognitoUserInfo);
    const response = (await client.graphql({
      query: getUserQuery, variables: {
        id: cognitoUserInfo?.preferred_username,
      }, authMode: authMode}
    )) as any;

    if (response.data.getUser) {
      response.data.getUser.drops.items.forEach((d)=>{
        if (new Date(d.launchTime) > new Date()){
          setNextDrop(d);
        }
      })
    }
    setUser(response.data.getUser);
    setUserLoading(false);
    console.log('USER', response.data.getUser);
    return response.data.getUser;
  }, [cognitoUserInfo?.preferred_username, setUser, setNextDrop]);

  const createUser = useCallback(async () => {
    console.log(cognitoUserInfo)
    const input: CreateUserInput = {
      id: cognitoUserInfo?.preferred_username,
      cognitoId: cognitoUserInfo?.preferred_username || '',
      cognitoSub: cognitoUserInfo?.sub || '',
      email: cognitoUserInfo?.email || '',
      firstName: cognitoUserInfo?.given_name || '',
      lastName: cognitoUserInfo?.family_name || '',
      numFollowees: 0,
      numFollowers: 0,
      numPosts: 0,
      isSeller: false
    };
    const response = (await client.graphql({
      query: mutations.createUser, variables: { input }, authMode: 'userPool'}
    )) as any;
    return response.data.createUser;
  }, [cognitoUserInfo]);

  const uploadAvatar = useCallback(async (file: File): Promise<any> => {
    const key = `avatar/${uuidv4()}`;
    const response = (await uploadData({key: key, data: file, options: {
      accessLevel: 'guest',
      contentType: 'image/jpeg',
    }})).result;
    return response;
  }, []);

  const updateUser = useCallback(
    async (input: UpdateUserInput): Promise<any> => {
      const response = (await client.graphql({
        query: mutations.updateUser, variables: { input }, authMode: 'userPool'}
      )) as any;
      return response.data.updateUser;
    },
    []
  );

  const updateAvatar = useCallback(
    
    async (file: File) => {
      setLoadingAvatar(true);
      const uploadResponse = await uploadAvatar(file);
      await updateUser({
        id: cognitoUserInfo?.preferred_username || '',
        avatar: uploadResponse.key,
      });
      loadUser(authMode).then(setUser);
      invalidateAvatarUrl();
      setLoadingAvatar(false);
    },
    [cognitoUserInfo?.preferred_username, loadingAvatar, setUser, loadUser, uploadAvatar, updateUser, invalidateAvatarUrl]
  );

  const updateUserAttributes = useCallback(
    async (attributes: Omit<DatabaseUserAttributes, 'email' | 'phone'>) => {
      const updatedUser = await updateUser({
        id: user?.id as any,
        ...attributes,
      });
      setUser(updatedUser);
    },
    [user?.id, updateUser, setUser, loadUser]
  );

  // const follow = useCallback(
  //   async (followee: User) => {
  //     if (Object.keys(followees).includes(followee.id)) return;
  //     const input: CreateLinkedUserInput = {
  //       id: `${userId}#${followee.id}`,
  //       followerId: userId,
  //       followeeId: followee.id,
  //     };
  //     await API.graphql({
  //       ...graphqlOperation(mutations.createLinkedUser, { input }), authMode: 'AWS_IAM'}
  //     );
  //     loadAllFollowees();
  //   },
  //   [userId, followees, loadAllFollowees]
  // );

  // const unfollow = useCallback(
  //   async (followee: User) => {
  //     if (!Object.keys(followees).includes(followee.id)) return;
  //     const input: DeleteLinkedUserInput = {
  //       id: `${userId}#${followee.id}`,
  //     };
  //     await API.graphql({
  //       ...graphqlOperation(mutations.deleteLinkedUser, { input }), authMode: 'AWS_IAM'}
  //     );
  //     removeFollowee(followee.id);
  //   },
  //   [userId, followees, removeFollowee]
  // );

  // const like = useCallback(
  //   async (type: LikeableType, id: string) => {
  //     if (likes[type].includes(id)) return;
  //     addLike(type, id);
  //     const postId = type === LikeableType.POST ? id : null;
  //     const commentId = type === LikeableType.COMMENT ? id : null;
  //     const input: CreateLikeInput = {
  //       id: `${userId}#${type.toLowerCase()}#${id}`,
  //       type,
  //       userId,
  //       postId,
  //       commentId,
  //     };
  //     await API.graphql({...graphqlOperation(mutations.createLike, { input }), authMode: 'AWS_IAM'});
  //   },
  //   [userId, likes, addLike]
  // );

  // const unlike = useCallback(
  //   async (type: LikeableType, id: string) => {
  //     if (!likes[type].includes(id)) return;
  //     deleteLike(type, id);
  //     const input: DeleteLikeInput = {
  //       id: `${userId}#${type.toLowerCase()}#${id}`,
  //     };
  //     await API.graphql({...graphqlOperation(mutations.deleteLike, { input }), authMode: 'AWS_IAM'});
  //   },
  //   [userId, likes, deleteLike]
  // );

  // Autoload user
  useEffect(() => {
    if (!authUser) return;
    setUserLoading(true);
    loadUser(authMode)
      .then((fetchedUser) => {
        if (fetchedUser) setUser(fetchedUser);
        else {
          setIsNewUser(true);
        }
      })
      .catch((e) => console.error(e));
    setUserLoading(false);
  }, [loadUser, setIsNewUser]);

  // If user is new, initialize the user in our database
  useEffect(() => {
    if (!authUser) return;
    if (!isNewUser) return;
    createUser()
      .then(setUser)
      .catch((e) => console.error(e))
      .finally(() => setIsNewUser(false));
  }, [isNewUser, createUser]);

  // Autoload all unread notifications
  // useEffect(() => {
  //   loadAllUnreadNotifications();
  // }, [loadAllUnreadNotifications]);

  // Autoload all likes
  // useEffect(() => {
  //   loadAllLikes();
  // }, [loadAllLikes]);

  // Autoload all followees
  // useEffect(() => {
  //   loadAllFollowees();
  // }, [loadAllFollowees]);

  const value = useMemo(
    () => ({
      // userId,
      user,
      avatarUrl,
      userLoading,
      nextDrop,
      // followees,
      // followeesLoading,
      // likes,
      // likesLoading,
      // notifications,
      // notificationsLoading,
      // notificationsLoaded,
      updateAvatar,
      loadingAvatar,
      updateUserAttributes,
      // follow,
      // unfollow,
      // like,
      // unlike,
      // postComment,
      // loadReadNotifications,
      // markNotificationsAsRead,
    }),
    [
      // userId,
      user,
      avatarUrl,
      userLoading,
      nextDrop,
      loadingAvatar,
      // followees,
      // followeesLoading,
      // likes,
      // likesLoading,
      // notifications,
      // notificationsLoading,
      // notificationsLoaded,
      updateAvatar,
      updateUserAttributes,
      // follow,
      // unfollow,
      // like,
      // unlike,
      // postComment,
      // loadReadNotifications,
      // markNotificationsAsRead,
    ]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
