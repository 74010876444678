import React, { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/system';
// import { useAuth } from '../../contexts/AuthContext.tsx'; 
import useAuth from '../../hooks/useAuth.ts';

function RegistrationLayout() {
  const { user } = useAuth();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navRef = useRef<HTMLDivElement | null>(null);

  // If user is logged in, re-direct to explore
  // if (cognitoUserInfo) return <Navigate to='/' replace />;
    return (
      <div>
        <Outlet />
      </div>
    )
  

}

export default RegistrationLayout;
