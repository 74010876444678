import { Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo } from 'react';
import AlertDialog from './AlertDialog.tsx';
import useCheckout from '../hooks/useCheckout.ts';
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { post as amplifyPost} from 'aws-amplify/api';
import useAuth from '../hooks/useAuth.ts';
import { getPost } from '../graphql/queries.js';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

const stripePromise = loadStripe("pk_live_51OqTySASWBL41EtrfgHlFc8ucgNDKPw8kblbuMO46FaOvP5oejyFY3EEQT2RjXoHusYbEteSaMsX6YEkOJIivyA600hhgnYAmh");
const stripe = require('stripe')('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');

const url = 'http://localhost:3000/post/'

function CheckoutForm ({clientSecret}) {

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{clientSecret: clientSecret}}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}
export default function BuyButton({post, text='Buy Now', positionInput='fixed'}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const {checkReservations, newReservation, deleteReservation, reserveItem, checkSold} = useCheckout();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [sold, setSold] = React.useState<boolean>(false);
  const [reserved, setReserved] = React.useState<boolean>(false);
  const [checkout, setCheckout] = React.useState<boolean>(false);
  const {authMode} = useAuth();
  const [clientSecret, setClientSecret] = React.useState(undefined);
  const [expiresAt, setExpiresAt] = React.useState<Date|undefined>(undefined);
  const [minutes, setMinutes] = React.useState<number|undefined>();
  const [seconds, setSeconds] = React.useState<number|undefined>();
  const [reservation, setReservation] = React.useState<any|undefined>(undefined);

  const fetchClientSecret = useCallback(async (resId) => {
    // Create a Checkout Session
    const result = await(amplifyPost({
        apiName: 'stripeapi', 
        path: '/create-checkout-session/',
        options: {
          body: {
            postId: post.id,
            reservationId: resId,
            authMode: authMode || 'iam',
          }
        }
        })) as any;
    const { body } = await result.response;
    const response = await body.json();
    setClientSecret(response.clientSecret);
    return response.clientSecret;
  }, [setClientSecret, clientSecret]);

  async function handleCheckReservations(resId){
    const r = await checkReservations(authMode, post.id, resId);
    setReserved(r);
    return r;
  }

  async function handleCheckSold(){
    const s = await checkSold(authMode, post.id);
    setSold(s);
    return s;
  }

  const countdown = useCallback(async()=>{
    if (expiresAt && expiresAt>new Date()){
      await setTimeout(()=>{
        const timeLeft = (expiresAt.getTime() - new Date().getTime());
        var min = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        var sec = Math.floor((timeLeft % (1000 * 60)) / 1000);
        setMinutes(min);
        setSeconds(sec);
        if (timeLeft > 5){
          countdown();
        } else{
          setCheckout(false);
          setExpiresAt(undefined);
          setClientSecret(undefined);
          setMinutes(undefined);
          setSeconds(undefined);
        }
      }, 1000)
    } else {
      setCheckout(false);
      setExpiresAt(undefined);
      setClientSecret(undefined);
      setMinutes(undefined);
      setSeconds(undefined);
    }
  }, [expiresAt, minutes, seconds, setCheckout, setMinutes, setSeconds, setClientSecret])
  const handleBuy = useCallback(async ()=>{
    try {
      setCheckout(true);
      // const checkOut = await createCheckout();
      if ((!clientSecret && !expiresAt) || (expiresAt && expiresAt<=new Date())){
        const res = await reserveItem(authMode, post.id, 'testmode');
        setReservation(res as any);
        setExpiresAt(new Date(res.deletedAt));
        const s = await handleCheckSold();
        if (s===true){
          await deleteReservation(authMode, res?.id);
          setExpiresAt(undefined);
          setCheckout(false);
          return;
        } 
        const r = await handleCheckReservations(res?.id);
        if (r===true){
          await deleteReservation(authMode, res?.id);
          setExpiresAt(undefined);
          setCheckout(false);
          return;
        } 
        const s2 = await handleCheckSold();
        if (s2===true){
          await deleteReservation(authMode, res?.id);
          setExpiresAt(undefined);
          setCheckout(false);
          return;
        } 
        fetchClientSecret(res?.id);
      }
      
    } catch (e) {
      console.log('Error buying:', e);
    }
  }, [handleCheckSold, setClientSecret, setReservation, reservation, clientSecret, setExpiresAt, fetchClientSecret, reserveItem, deleteReservation, handleCheckReservations, newReservation, sold, reserved])

  const timer = useMemo(()=>{
    return <Typography style={{color: minutes ? 'gray' : 'red', fontStyle: 'italic', paddingBottom: 20, paddingTop: 20}}>
          {minutes ? <Typography>
            Reserved for {String(minutes)} minutes {String(seconds)} seconds
            </Typography> : undefined}
            {(!minutes && seconds) ? <Typography>
            Reserved for {String(seconds)} seconds
            </Typography> : undefined}
        </Typography>
  }, [minutes, seconds])


  useEffect(() => {
    if (clientSecret && expiresAt){
      countdown();
    }

  }, [clientSecret, setClientSecret]);

  return (
    <Stack style={{
      position: positionInput, 
      bottom: isSmallScreen ? 50 : 0, 
      width: '91%', maxWidth: '805px', 
      alignSelf: 'center'}}>
    {checkout && <Stack style={{
      zIndex: 1000, 
      position: 'fixed', 
      width: '100vw', 
      height: '80vh', 
      top: '20vh', 
      left: 0, 
      alignItems: 'center',
      backgroundColor: 'white', 
      boxShadow: `0 0 16px black`, 
      borderRadius: 10,
      paddingTop: 20, 
      paddingBottom: 70}}>
      <Typography onClick={()=>{setCheckout(false)}} style={{cursor: 'pointer', position: 'absolute', left: '4vw', top: 20, padding: 5}}>
        X
      </Typography>
      {!minutes && !seconds ? <Stack
      style={{
        width: '100vw', 
        maxWidth: '700px',
        textAlign: 'center', 
        marginTop: 50, 
        fontSize: 30,
        padding: 30, 
        alignItems: 'center', 
        alignContent: 'center'}}>
        <Typography>
            Please make sure to inspect all pictures and listing details closely before purchasing. Please take a full unboxing video with no cuts to ensure your money back if there are issues. 
          </Typography>

        <Typography style={{marginTop: 50}}>
          Please wait a moment while we load our secure payments processor.
        </Typography>
        <Typography
          color='black'
          style={{fontSize: 11, marginBottom: 10, marginTop: 20, fontWeight: 'bold', alignSelf: 'centerfontSize: 12'}}
        >
          <ShoppingCartCheckoutIcon fontSize='large'/>
        </Typography>
      <div className='loader-line' style={{width: '8%', color: 'gray',
     alignSelf: 'center', alignContent: 'center', textAlign: 'center'}}/>
      </Stack> :

      <Stack style={{width: '100%', height: '100%', alignItems: 'center', color: 'black', alignContent: 'center', overflowY: 'scroll'}}>
        {timer}
        <CheckoutForm clientSecret={clientSecret}/>
      </Stack>}
      </Stack>}
    <AlertDialog 
      title='Oh No!'
      contentText='It looks like someone has already snatched this :('
      open={sold}
      dismissButton={{
          label: 'Got it!',
          handleClick: () => {
            setSold(false)}
        }}
        confirmButton={{
          label:'Back to browse',
          handleClick: () => {
            navigate('/')
          }
        }}
    />
    <AlertDialog 
      title='Someone else is checking out :('
      contentText='It looks like someone else is checking out with this item. Please check back in a few minutes.'
      open={reserved}
      dismissButton={{
          label: 'Got it!',
          handleClick: () => {
            setReserved(false)}
        }}
        confirmButton={{
          label:'Back to browse',
          handleClick: () => {
            navigate('/')
          }
        }}
    />
    <Button 
      onClick={handleBuy}
      disabled={checkout || post.isSold}
      style={{width: '100%',
        alignSelf: 'center',
        paddingTop: 10, 
        paddingBottom: 10,
        textAlign: 'center', 
        backgroundColor: 'black', 
        border: '0.5px solid lightgray',
        color: 'white',
        cursor: 'pointer',
        zIndex: 10,
        }}>
        {post.isSold ? 'SOLD' : checkout ? 'Loading...' : text}
      </Button>
      <Typography style={{width: '100%', paddingBottom: isSmallScreen ? 15 : 20, fontSize: isSmallScreen ? 11 : 14, fontStyle: 'italic', textAlign: 'center', padding: 5, backgroundColor: 'white'}}>
        This item will be reserved for 5 minutes once you click Buy Now.
      </Typography>

      
      </Stack>
  );
}
