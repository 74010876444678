import React, { useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { Amplify } from 'aws-amplify';
import './App.css';
import config from './aws-exports';
import routes from './routes.tsx';
import { createTodo } from './graphql/mutations';
import { listTodos } from './graphql/queries';
import  {AuthContextProvider} from './contexts/AuthContext.tsx';
import useAuth from './hooks/useAuth.ts';
import { Hub, ConsoleLogger } from 'aws-amplify/utils';
import { fetchAuthSession } from 'aws-amplify/auth'

const autht = (await fetchAuthSession());

const initialState = { name: '', description: '' };

Amplify.configure(config);

const client = generateClient();

async function fetchTodos(authMode) {
  try {
    const todoData = await client.graphql({
      query: listTodos,
      authMode: authMode
    }) as any;
    // const todos = todoData?.data.listTodos.items;
  } catch (err) {
    console.log(err);
  }
}

interface Todo {
  id?: string;
  name: string;
  description: string;
}

interface Props {
  signOut: () => void;
  user: any; // Adjust the type according to your AuthContext
}

const App: React.FC = () => {
  const [formState, setFormState] = useState<Todo>(initialState);
  const [todos, setTodos] = useState<Todo[]>([]);
  const {handleSignOut, handleSignIn, user, checkUser} = useAuth();

  const logger = new ConsoleLogger('Logger', 'INFO');
  const listener = (data) => {

  switch (data.payload.event) {
    case 'signedIn':
      logger.info('user signed in');
      checkUser();
      break;
    case 'signedUp':
      logger.info('user signed up');
      checkUser();
      break;
    case 'signedOut':
      logger.info('user signed out');
      checkUser();
      break;
    case 'signIn_failure':
      logger.info('user sign in failed');
      checkUser();
      break;
    case 'configured':
      logger.info('the Auth module is configured');
      break;
    default:
      logger.error('Something went wrong, look at data object', data);
  }
  }

  Hub.listen('auth', listener);
  // useEffect(() => {
  //   checkUser();
  // }, []);


  return routes
      // <div style={styles.container}>
      //   {user ? 
      //   <div onClick={handleSignOut}>Sign Out</div>
      //   : <div onClick={()=>{handleSignIn('annicorn1', 'Westeros12345*'); console.log('hello')}}>Sign In</div>}
      //   <h2>Amplify Todos</h2>
      //   <input
      //     onChange={(event) => setInput('name', event.target.value)}
      //     style={styles.input}
      //     value={formState.name}
      //     placeholder="Name"
      //   />
      //   <input
      //     onChange={(event) => setInput('description', event.target.value)}
      //     style={styles.input}
      //     value={formState.description}
      //     placeholder="Description"
      //   />
      //   <button style={styles.button} onClick={addTodo}>
      //     Create Todo
      //   </button>
      //   {todos.map((todo, index) => (
      //     <div key={todo.id ? todo.id : index} style={styles.todo}>
      //       <p style={styles.todoName}>{todo.name}</p>
      //       <p style={styles.todoDescription}>{todo.description}</p>
      //     </div>
      //   ))}
      // </div>
  ;
};

export default function AppWrapper() {
  return (
    <AuthContextProvider>
          <App />
    </AuthContextProvider>
  );
}


// const styles: { [key: string]: React.CSSProperties } = {
//   container: {
//     width: 400,
//     margin: '0 auto',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     padding: 20
//   },
//   todo: { marginBottom: 15 },
//   input: {
//     border: 'none',
//     backgroundColor: '#ddd',
//     marginBottom: 10,
//     padding: 8,
//     fontSize: 18
//   },
//   todoName: { fontSize: 20, fontWeight: 'bold' },
//   todoDescription: { marginBottom: 0 },
//   button: {
//     backgroundColor: 'black',
//     color: 'white',
//     outline: 'none',
//     fontSize: 18,
//     padding: '12px 0px'
//   }
// };
