import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';

export default function Logo({color='black'}) {
  const navigate = useNavigate();

  return (
    <Typography
      variant='h6'
      color={color}
      component='div'
      fontWeight='500'
      style={{cursor: 'pointer'}}
      onClick={() => navigate('/')}
    >
      HAUTEGRID
    </Typography>
  );
}
