import { Cache } from 'aws-amplify/utils';
import { uploadData, downloadData, getUrl } from 'aws-amplify/storage';
import { useCallback, useEffect, useState } from 'react';

export async function getS3SignedUrl(
  key: string,
  level: 'public' = 'public'
) {
  const data = await getUrl({key: key, 
    options: {
     accessLevel: 'guest'
  }});
  return data.url.href;
}

export default function useS3SignedUrl(
  key?: string | null,
  level: 'public' = 'public'
) {
  const [url, setUrl] = useState<string>();
  const secondsToExpires = 900;

  useEffect(() => {
    if (!key) {
      setUrl(undefined);
      return;
    }
    Cache.getItem(key).then((cached) => {
      if (cached) {
        setUrl(cached as any);
        return;
      }
    })
    getS3SignedUrl(key, level)
      .then(async (data) => {
        setUrl(data as never);
        await Cache.setItem(key, data, {
          expires: new Date().getTime() + secondsToExpires * 100,
        });
        return data;
      })
      .catch((e) => console.error(e));
  }, []);

  const invalidate = useCallback(() => {
    if (key) Cache.removeItem(key);
  }, [key]);

  return { url, invalidate };
}
