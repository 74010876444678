import { generateClient} from '@aws-amplify/api';
import { useCallback, useState } from 'react';
import { Post, PostType } from '../API.ts';
import { getPost } from '../graphql/queries.js';
import { createReservation, deleteReservation as deleteRes } from '../graphql/mutations.js';

const client = generateClient();


// need to grab the user for each Drop. 
const loadReservations = /* GraphQL */ `
  query ListReservationsQuery(
    $itemId: String, 
    $time: String, 
    $resId: ID,
  ) {
    listReservations(
      filter: {
        id: {ne: $resId},
        itemID: {eq: $itemId},
        createdAt: {le: $time},
        deletedAt: {ge: $time},
      }
    ) { items {
        id
        itemID
        createdAt
        deletedAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;


function useCheckout() {
  const [reservations, setReservations] = useState([]);
  const [newReservation, setNewReservation] = useState<any>();

  const checkSold = useCallback(async (auth_mode, itemId) => {
    try {
      const item = (await client.graphql({
        query: getPost,
        variables: {
          id: itemId
        },
        authMode: auth_mode
      })) as any;
      if (item.data.getPost.isSold) {
        return true;
      } return false;
    } catch (e) {
      console.log('Check Sold Error', e);
      throw new Error('Check Sold Error');
    }
    return false;
  }, [])

  const reserveItem = useCallback(async (auth_mode, itemId, sessionId) => {
    const date = new Date();
    const end = new Date(new Date().setMinutes(new Date().getMinutes() + 5));
    const input = {
      itemID: itemId,
      createdAt: date,
      deletedAt: end,
      sessionID: sessionId
    }
    try {
    const response = (await client.graphql({
      query: createReservation,
      variables: {
        input
      },
      authMode: auth_mode
    })) as any;
    setNewReservation(response.data.createReservation as any);
    return response.data.createReservation;
  } catch (e) {
    throw new Error(`Create reservation error ${e.errors}`);
  }
  }, [setNewReservation, newReservation])

  const deleteReservation = useCallback(async (auth_mode, id) => {
    try {
      const input = {
        id: id,
        // sessionID: sessionId
      }
      const response = (await client.graphql({
        query: deleteRes,
        variables: {
          input
        }, authMode: auth_mode
      })) as any
    } catch (e) {
      console.log('Delete reservation Error', e.errors);
      throw new Error('Delete reservation error');
    }
  }, [newReservation, setNewReservation])


  const checkReservations = useCallback(async (auth_mode, itemId, resId) => {
    try {
      const reservations = (await client.graphql({
        query: loadReservations,
        variables: {
          itemId: itemId,
          time: new Date(),
          resId: resId
        },
        authMode: auth_mode
      })) as any;
      if (reservations.data.listReservations.items.length >= 1){
        setReservations(reservations.data.listReservations.items);
        return true;
      } 
      return false;
    } catch (e){
      console.log('Check Reservation Error', e);
      throw new Error('Check Reservation Error');
    }
    return false;
  }, [setReservations, newReservation, setNewReservation])

  return { checkReservations, checkSold, deleteReservation, reservations, newReservation, reserveItem };
}

export default useCheckout;
