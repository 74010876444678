import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext.tsx';

export default function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(
      '`useUser` hook must be used within a `UserProvider` component'
    );
  }
  return context;
}
