import { generateClient,  } from 'aws-amplify/api';

import {uploadData} from 'aws-amplify/storage';

import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
// import { RcFile } from 'antd/es/upload';
import {
  CreateLinkedPostInput,
  CreatePostInput,
  CreateDropInput,
  Post,
  PostType,
  Drop,
  CreateLinkedPostDropInput,
} from '../API.ts';

import { createLinkedPost, createPost, createDrop, createLinkedPostDrop } from '../graphql/mutations.js';
import { useFileReader, useFillPost, usePosts} from '../hooks/index.ts';
import { PostDetailInputs, TaggedPost } from '../utils/types.ts';
import useAuth from '../hooks/useAuth.ts';
import { stripeSecretKey } from '../utils/common.ts';

const client = generateClient();
const stripe = require('stripe')(stripeSecretKey)

interface CreatePostContextType {
  readonly loading: boolean;
  readonly styleFiles: File[];
  readonly files: File[];
  readonly video?: File;
  readonly type?: PostType;
  readonly inputs: PostDetailInputs;
  readonly dropInputs: PostDetailInputs;
  readonly itemPosts: Post[];
  readonly taggedPosts: TaggedPost[];
  readonly setStyleFiles: (file: File[]) => void;
  readonly setVideo: (file: File) => void;
  readonly setFiles: (files: File[]) => void;
  readonly setType: (type: PostType) => void;
  readonly setInputValue: (field: string, value: string) => void;
  readonly setInputError: (field: string, error: string) => void;
  readonly setDropInputValue: (field: string, value: string) => void;
  readonly setDropInputError: (field: string, error: string) => void;
  readonly setTaggedPosts: (taggedPosts: TaggedPost[]) => void;
  readonly validateInputs: () => boolean;
  readonly validateDropInputs: () => boolean;
  readonly savePost: (drop: Drop) => any;
  readonly putDrop: () => any;
  readonly getDropInputValue: (field: string) => any;
}

export const CreatePostContext = createContext<CreatePostContextType>({
  loading: false,
  inputs: {},
  dropInputs: {},
  itemPosts: [],
  taggedPosts: [],
  styleFiles: [],
  files: [],
  setStyleFiles: () => {},
  setFiles: () => {},
  setVideo: () => {},
  setType: () => {},
  setInputValue: () => {},
  setDropInputValue: () => {},
  setInputError: () => {},
  setDropInputError: () => {},
  setTaggedPosts: () => {},
  validateInputs: () => false,
  validateDropInputs: () => false,
  savePost: () => {},
  putDrop: () => {},
  getDropInputValue: () => String
});

export function CreatePostProvider({ children }: { children: ReactNode }) {
  const { user } = useAuth();
  const {
    inputs,
    dropInputs,
    initInputs,
    initDropInputs,
    setInputValue,
    setDropInputValue,
    setInputError,
    setDropInputError,
    getInputValue,
    getDropInputValue,
    validateInputs,
    validateDropInputs
  } = useFillPost();
  const [loading, setLoading] = useState<boolean>(false);
  const [styleFiles, setStyleFiles] = useState<File[]>([]);
  const [video, setVideo] = useState<File>();
  const [files, setFiles] = useState<File[]>([]);
  const [type, setType] = useState<PostType>();  
  const [taggedPosts, setTaggedPosts] = useState<TaggedPost[]>([]);
  // const { data: imgSrc } = useFileReader(file);
  const { posts: itemPosts } = usePosts(user?.username, PostType.ITEM, 'userPool');

  // When type changes, we want to reset all input fields
  useEffect(() => {
    if (type) initInputs(type);
    initDropInputs();
  }, [type, initInputs]);

  const uploadVideo = useCallback(async () => {
    if (!type || !video) return null;
    const key = `post/${type.toLowerCase()}/${uuidv4()}`;
    const r = await (uploadData({key, data: video, 
    options: {
      accessLevel: 'guest',
      
    }
    })) as any;
    return r;
  }, [type, video]);

  const uploadImages = useCallback(async () => {
    if (!type || files.length===0) return null;
    const responsesTmp = []
    const files1 = files[0]
    const files2 = files.slice(1)
    const key1 = `post/${type.toLowerCase()}/${uuidv4()}`;
    const r1 = await (uploadData({key: key1, data: files1, 
      options: {
      accessLevel: 'guest',
      contentType: 'image/jpeg',
      }})).result;
    await Promise.all(files2.map(async (f)=>{
      const key = `post/${type.toLowerCase()}/${uuidv4()}`;
      const r = await (uploadData({key, data: f, 
      options: {
      accessLevel: 'guest',
      contentType: 'image/jpeg',
      }})).result;
      responsesTmp.push(r.key as never);
    }))
    return [r1.key as never].concat(responsesTmp);
  }, [type, files]);

  const putDrop = useCallback(
    
    async () => {
      const input: CreateDropInput = {
        userId: user?.username || '',
        name: getDropInputValue('name') || '',
        description: getDropInputValue('description') || '',
        launchTime: getDropInputValue('launchTime') || '',
      };
      const response = (await client.graphql({
        query: createDrop, variables: { input }, authMode: 'userPool'
    })) as any;

      return response.data.createDrop;

    }, [user?.username, dropInputs, getDropInputValue]
  )

  const putItemPost = useCallback(
    async (imageKeys: string[], dropId: string, vid: string) => {
      // const product = await stripe.products.create({
      //   name: getInputValue('name')!,
      //   description: getInputValue('caption'),
      //   default_price_data: {
      //     currency: 'USD',
      //     tax_behavior: 'exclusive',
      //     unit_amount: 100*Number(getInputValue('sellingPrice'))!,
      //   }
      // });
      // console.log('PRODUCT', product);
      const input: CreatePostInput = {
        type: PostType.ITEM,
        userId: user?.username || '',
        numLikes: 0,
        numComments: 0,
        images: imageKeys,
        video: vid,
        dropId,
        caption: getInputValue('caption'),
        category: getInputValue('category')!,
        designer: getInputValue('designer')!,
        name: getInputValue('name')!,
        color: getInputValue('color'),
        material: getInputValue('material'),
        size: getInputValue('size'),
        sellingPrice: Number(getInputValue('sellingPrice'))!,
        height: Number(getInputValue('height')),
        width: Number(getInputValue('width')),
        depth: Number(getInputValue('depth')),
        chainDrop: Number(getInputValue('chainDrop')),
        productionYear: Number(getInputValue('productionYear')),
        interiorCondition: getInputValue('interiorCondition'),
        exteriorCondition: getInputValue('exteriorCondition'),
        isVintage: getInputValue('isVintage') === 'true',
        callouts: getInputValue('callouts'),
        hardware: getInputValue('hardware'),
        authenticityGuarantee: getInputValue('authenticityGuarantee'),
        dustbag: getInputValue('dustbag') === 'true',
        box: getInputValue('box') === 'true',
        serialNumber: getInputValue('serialNumber') === 'true',
        authCard: getInputValue('authCard') === 'true',
      };
      const response = (await client.graphql({
        query: createPost, variables: { input }, authMode: 'userPool'}
      )) as any;
      return response.data.createPost;
    },
    [user?.username, getInputValue]
  );

    const putLinkedDropPost = useCallback(
    async (dropId: string, itemId: string) => {
      const input: CreateLinkedPostDropInput = {
        userId: user?.username || '',
        dropId,
        itemId
      };
      const response = (await client.graphql({
        query: createLinkedPostDrop, variables: { input }, authMode: 'userPool'}
      )) as any;
      return response.data.createLinkedPostDrop;
    },
    [user?.username]
  );

  // const putOutfitPost = useCallback(
  //   async (imageKeys: string[], dropId: string, vid: string) => {
  //     const input: CreatePostInput = {
  //       type: PostType.OUTFIT,
  //       userId: user?.userId || '',
  //       dropId,
  //       numLikes: 0,
  //       numComments: 0,
  //       sellingPrice: 0,
  //       images: imageKeys,
  //       video: vid,
  //       caption: getInputValue('caption')!,
  //     };
  //     const response = (await API.graphql({
  //       ...graphqlOperation(createPost, { input }), authMode: 'AWS_IAM'}
  //     )) as any;
  //     return response.data.createPost;
  //   },
  //   [userId, getInputValue]
  // );

  // const putLinkedPost = useCallback(
  //   async (parentId: string, taggedPost: TaggedPost) => {
  //     const input: CreateLinkedPostInput = {
  //       useruserId,
  //       parentId,
  //       taggedId: taggedPost.post.id,
  //       pos: {
  //         x: Number(taggedPost.marker.left),
  //         y: Number(taggedPost.marker.top),
  //       },
  //     };
  //     const response = (await API.graphql({
  //       ...graphqlOperation(createLinkedPost, { input }), authMode: 'AWS_IAM'}
  //     )) as any;
  //     return response.data.createLinkedPost;
  //   },
  //   [userId]
  // );

  const savePost = useCallback(async (drop: Drop) => {
    setLoading(true);
    const images = await uploadImages();
    const vid = await uploadVideo();
    const imageKeys = [];
    images?.forEach((i)=>{
      imageKeys.push(i)
    })
    let response: Post;
    switch (type) {
      case PostType.ITEM:
        response = await putItemPost(imageKeys, drop.id, vid?.key || null);
        await putLinkedDropPost(drop.id, response.id);
        break;
      // case PostType.OUTFIT:
      //   response = await putOutfitPost(imageKeys, drop.id, vid?.key || null);
      //   await Promise.all(
      //     taggedPosts.map(async (taggedPost: TaggedPost) => {
      //       await putLinkedPost(response.id, taggedPost);
      //     })
      //   );
      //   break;
      default:
        throw new Error(`Unrecognized type: ${type}`);
    }
    setLoading(false);
    return response;
  }, [
    type,
    taggedPosts,
    uploadImages,
    putItemPost,
    // putOutfitPost,
    // putLinkedPost,
  ]);

  const value = useMemo(
    () => ({
      loading,
      styleFiles,
      files,
      video,
      // imgSrc,
      type,
      inputs,
      dropInputs,
      itemPosts,
      taggedPosts,
      setStyleFiles,
      setFiles,
      setVideo,
      setType,
      setInputValue,
      setDropInputValue,
      setInputError,
      setDropInputError,
      setTaggedPosts,
      validateInputs,
      validateDropInputs,
      putDrop,
      getDropInputValue,
      savePost,
    }),
    [
      loading,
      styleFiles,
      files,
      video,
      // imgSrc,
      type,
      inputs,
      dropInputs,
      itemPosts,
      taggedPosts,
      setStyleFiles,
      setVideo,
      setFiles,
      setType,
      setInputValue,
      setDropInputValue,
      setInputError,
      setDropInputError,
      setTaggedPosts,
      validateInputs,
      validateDropInputs,
      getDropInputValue,
      putDrop,
      savePost,
    ]
  );

  return (
    <CreatePostContext.Provider value={value}>
      {children}
    </CreatePostContext.Provider>
  );
}
