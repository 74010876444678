import { generateClient } from 'aws-amplify/api';
import React, {
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { User } from '../API.ts';
import { useS3SignedUrl } from '../hooks/index.ts';

const client = generateClient();

const getUserQuery = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoId
      email
      firstName
      lastName
      bio
      isSeller
      isBusiness
      isInfluencer
      stripeID
      avatar
      numFollowees
      numFollowers
      drops {
        items {
          id
          userId
          name
          description
          launchTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
    }
  }
`;

interface ProfileContextType {
  readonly userId: string;
  readonly user?: User;
  readonly avatarUrl?: string;
  readonly loading: boolean;
  readonly loadUser: (auth_mode: string) => void;
}

export const ProfileContext = createContext<ProfileContextType>({
  userId: '',
  loading: true,
  loadUser: () => {},
});

interface ProfileProviderProps {
  readonly id: string;
  readonly children: ReactNode;
}

export function ProfileProvider({ id, children }: ProfileProviderProps) {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState<boolean>(true);
  
  console.log('ID', id);
  const { url: avatarUrl } = useS3SignedUrl(
    user?.avatar,
    'public'
  );

  const loadUser = useCallback(async (auth_mode) => {
    console.log('HELLO');
    setLoading(true);
    console.log('IN LOAD USER');
    console.log(id);
    const response = (await client.graphql({
      query: getUserQuery, 
      variables: {
        id,
      }, authMode: auth_mode}
    )) as any;
    setUser(response.data.getUser);
    setLoading(false);
  }, [id]);

  const value = useMemo(
    () => ({
      userId: id,
      user,
      avatarUrl,
      loading,
      loadUser,
    }),
    [id, user, avatarUrl, loading, loadUser]
  );

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
}
