import { Navigate, Route, Routes } from 'react-router-dom';
import { ProtectedLayout, PublicLayout, RegistrationLayout } from './layout/index.tsx';
import React from 'react';
import {
  AccountPage,
  ConfirmationPage,
  CreateAccountPage,
  // CommentPage,
  CreatePage,
  // EditPage,
  ExplorePage,
  Info,
  // FeedPage,
  LoginPage,
  OrderPage,
  OrdersPage,
  // NotificationsPage,
  // ParentPostsPage,
  PostPage,
  ProfilePage,
  // SearchPage,
  // Selling,
  Settings
} from './pages/index.ts';
import BuyButton from './components/BuyButton.tsx';
import AuthenticityPage from './pages/authenticity/index.tsx';
import ReturnsShippingPage from './pages/returnsshipping/index.tsx';
import PrivacyTermsPage from './pages/privacyterms/index.tsx';
// import Settings from './pages/settings/Profile';
// import Security from './pages/settings/Security';

const routes = (
  <Routes>
    <Route element={<RegistrationLayout />}>
      <Route path='/login' element={<LoginPage />} />
    </Route>
    <Route element={<PublicLayout />}>
      <Route path='/' element={<ExplorePage />} />
      {/* <Route path='/info' element={<Info />} />
      <Route path='/authenticity' element={<AuthenticityPage />} />
      <Route path='/shipping-and-returns' element={<ReturnsShippingPage />} />
      <Route path='/terms-and-privacy' element={<PrivacyTermsPage />} />
      <Route path='/post/:postId' element={<PostPage />} />
      <Route path='/orders/confirmation/:sessionId' element={<ConfirmationPage />} />
      <Route path='/order/:orderId' element={<OrderPage />} />
      <Route path='/user/:userId' element={<ProfilePage />} />
      <Route path='/myaccount/createaccount' element={<CreateAccountPage />} /> */}
      
      <Route path='/login' element={<LoginPage />} />
      <Route path='*' element={<Navigate to='/login' replace />} />
      {/*<Route path='/user/:userId' element={<ProfilePage />} />
      <Route path='/post/:postId/edit' element={<EditPage />} />
      <Route path='/comments/:postId' element={<CommentPage />} />
      <Route path='/parentposts/:postId' element={<ParentPostsPage />} /> */}
      {/* <Route path='*' element={<Navigate to='/' replace />} /> */}
    </Route>
    <Route element={<ProtectedLayout />}>
      {/* <Route path='/account/settings' element={<Settings />} />
      <Route path='/myaccount/:userId' element={<AccountPage />} />
      <Route path='/orders/:userId' element={<OrdersPage /> } /> */}
      {/* <Route path='/selling/:filter' element={<Selling />} />
      <Route path='/feed' element={<FeedPage />} />
      <Route path='/search' element={<SearchPage />} /> */}
      {/* <Route path='/sell' element={<CreatePage />} /> */}
      {/* <Route path='/user/:userId' element={<ProfilePage />} />
      <Route path='/post/:postId' element={<PostPage />} />
      <Route path='/post/:postId/edit' element={<EditPage />} />
      <Route path='/comments/:postId' element={<CommentPage />} />
      <Route path='/parentposts/:postId' element={<ParentPostsPage />} />
      <Route path='/account/settings/security' element={<Security />} />
      <Route path='/notifications' element={<NotificationsPage />} /> */}
      <Route path='*' element={<Navigate to='/' replace />} />
    </Route>
    <Route path='*' element={<Navigate to='/login' replace />} />
  </Routes>
);

export default routes;
