import { generateClient } from 'aws-amplify/api';
import { Cache } from 'aws-amplify/utils';
import React, {
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Comment, Post } from '../API.ts';
import { useAuth, useS3SignedUrl } from '../hooks/index.ts';
import { getS3SignedUrl } from '../hooks/useS3SignedUrl.ts';

const client=generateClient();
interface ViewPostContextType {
  readonly loading: boolean;
  readonly loadingComments: boolean;
  readonly post?: Post;
  readonly imageUrl?: string;
  readonly imageUrls?: string[];
  readonly comments: Comment[];
  readonly setPost: (post: Post) => void;
  readonly loadPost: (auth_mode: string) => void;
  readonly loadComments: (auth_mode: string) => void;
  readonly getUrls: () => void;
  readonly addComment: (comment: Comment) => void;
}

export const ViewPostContext = createContext<ViewPostContextType>({
  loading: true,
  loadingComments: true,
  comments: [],
  setPost: () => {},
  loadPost: () => {},
  loadComments: () => {},
  addComment: () => {},
  getUrls: () => {},
});

interface ViewPostProviderProps {
  readonly id: string;
  readonly children: ReactNode;
}

const getPostQuery = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      user {
        id
        cognitoId
        avatar
        stripeID
        isSeller
      }
      createdAt
      updatedAt
      numLikes
      numComments
      type
      images
      video
      caption
      category
      designer
      name
      color
      sellingPrice
      purchasePrice
      material
      size
      isSold
      isCompleted
      height
      width
      depth
      chainDrop
      interiorCondition
      exteriorCondition
      isVintage
      callouts
      oldPrice
      productionYear
      hardware
      dustbag
      box
      serialNumber
      authCard
      authenticityGuarantee
      parentPosts {
        items {
          parentPost {
            images
            caption
            id
          }
          pos {
            x
            y
          }
        }
        nextToken
      }
      taggedPosts {
        items {
          taggedPost {
            id
            images
            caption
            designer
            name
          }
          pos {
            x
            y
          }
        }
        nextToken
      }
    }
  }
`;

const getCommentsQuery = /* GraphQL */ `
  query CommentsByPostIdAndCreatedAt($id: ID!, $nextToken: String) {
    commentsByPostIdAndCreatedAt(
      postId: $id
      nextToken: $nextToken
      sortDirection: DESC
      filter: { type: { eq: POST } }
    ) {
      items {
        id
        user {
          id
          avatar
          cognitoId
        }
        numLikes
        numReplies
        text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export function ViewPostProvider({ id, children }: ViewPostProviderProps) {
  const [post, setPost] = useState<Post>();
  const {checkUser, authMode} = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [comments, setComments] = useState<Comment[]>([]);
  const [loadingComments, setLoadingComments] = useState<boolean>(true);
  const [imageUrls, setImageUrls] = useState([]);
  const secondsToExpires = 3600;

  async function getUrls (){
    const urlsTmp = []
    if (post){
    await Promise.all(post?.images.map(async (i) => {
      const cached = await Cache.getItem(i);
        if (cached) {
          urlsTmp.push(cached as never);
        } else{
           const u = await getS3SignedUrl(i, 'public').catch((e)=>console.error(e));
           urlsTmp.push(u as never);
           Cache.setItem(i, u, {
            expires: new Date().getTime() + secondsToExpires * 100,
          });
        }
    }))
    }
    setImageUrls(urlsTmp); 
  }

  const loadPost = useCallback(async (auth_mode) => {
    setLoading(true);
    const response = (await client.graphql({
      query: getPostQuery, variables: {
        id,
      }, authMode: auth_mode}
    )) as any;
    setPost(response.data.getPost);
    setLoading(false);
  }, [id]);

  const loadComments = useCallback(async (auth_mode) => {
    setLoadingComments(true);
    const response = (await client.graphql({
      query: getCommentsQuery, variables: {
        id,
      }, authMode: auth_mode}
    )) as any;
    setComments(response.data.commentsByPostIdAndCreatedAt.items);
    setLoadingComments(false);
  }, [id]);

  const addComment = useCallback(async (comment: Comment) => {
    setComments((prevComments) => [comment, ...prevComments]);
  }, []);

  const { url: imageUrl } = useS3SignedUrl(
    post?.images[0],
    'public'
  );

  const value = useMemo(
    () => ({
      loading,
      loadingComments,
      post,
      imageUrl,
      imageUrls,
      comments,
      setPost,
      loadPost,
      getUrls,
      loadComments,
      addComment,
    }),
    [
      loading,
      loadingComments,
      post,
      imageUrl,
      imageUrls,
      comments,
      setPost,
      loadPost,
      loadComments,
      getUrls,
      addComment,
    ]
  );

  return (
    <ViewPostContext.Provider value={value}>
      {children}
    </ViewPostContext.Provider>
  );
}
