import { ImageList, Stack, useMediaQuery, useTheme, Typography, Button } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import Image from 'mui-image';
import InfiniteScroll from 'react-infinite-scroll-component';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { PostType } from '../../API.ts';
import { useNavigate } from 'react-router-dom';
import { useExplorePosts } from '../../hooks/index.ts';
import {useAuth} from '../../hooks/index.ts';
import PostPreviewNewsfeed from '../../components/PostPreviewNewsfeed.tsx';
import NoDrops from '../../components/NoDrops.tsx';
import VerifiedIcon from '@mui/icons-material/Verified';
import UserAvatar from '../../components/UserAvatar.tsx';
// interface ExplorePostTabProps {
//   readonly postType: PostType;
// }

function ExplorePostTab() {
  const { user, authMode, checkUser, userLoading, handleSignIn, handleSignOut } = useAuth();
  const { loaded } = useExplorePosts(user?.userId || '', PostType.ITEM, user);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const divRef = useRef<null | HTMLDivElement>(null); 
  const navigate = useNavigate();

  //   useEffect(() => {
  //   if (!userLoading && !loading && !loaded && posts.length === 0) {
  //     load(authMode);
  //   }

  // }, [posts.length, load, userLoading, handleSignIn, handleSignOut]);


  // if (!loading && loaded && posts.length === 0) {
  //   return <Empty description='' />;
  // }
  


  // if (posts.length === 0) {

  //   return <Typography
  //     style={{width: '100%', textAlign: 'center', marginTop: 200, fontSize: 30, alignItems: 'center', alignContent: 'center'}}>
  //       <Typography
  //         color='black'
  //         style={{fontWeight: 'bold', fontSize: 12}}
  //       >
  //         HAUTEGRID
  //       </Typography>
  //     <div className='loader-line' style={{width: '8%',
  //     marginLeft: '46%', alignContent: 'center', textAlign: 'center'}}/>
  //     </Typography> 
  // }
  // if (!loaded) {
  //   return <Stack
  //     style={{width: '100vw', minHeight: '100vh', textAlign: 'center', fontSize: 30, alignItems: 'center', alignContent: 'center'}}>
  //       <Typography
  //         color='black'
  //         style={{fontWeight: 'bold', marginTop: '40vh', alignSelf: 'centerfontSize: 12'}}
  //       >
  //         HAUTE.AI
  //       </Typography>
  //     <div className='loader-line' style={{width: '8%',
  //    alignSelf: 'center', alignContent: 'center', textAlign: 'center'}}/>
  //     </Stack> }
  

  return (
    <div style={{width: '100vw', marginTop: -70, alignItems: 'center', alignContent: 'center'}}>
    <Stack style={{width: '100vw', backgroundColor: 'white', marginLeft: 0, marginTop: 50}}>
      <Stack className='hautegrid' style={{alignContent: 'center', alignItems: 'center', top: 0, left: 0, zIndex: 10}}>

        
        {isSmallScreen ? <Typography
          variant='h3'
          color='black'
          component='div'
          fontWeight='bold'
          style={{paddingLeft: 20, paddingRight: 20, marginTop: '15vh', width: '100vw', top: 0, textAlign: 'center'}}

        >
          Replace 10 different resale apps with one.
          {/* Browse all luxury resale listings in one app. */}
        </Typography> : <Typography
          variant='h3'
          color='black'
          component='div'
          fontWeight='bold'
          style={{paddingLeft: 20, paddingRight: 20, marginTop: '15vh', width: '100vw', top: 0, textAlign: 'center'}}

        >
          Your AI shopping assistant for preloved luxury.
          {/* Browse all luxury resale listings in one app. */}
        </Typography>}
        

        <Typography
          color='black'
          component='div'
          style={{fontSize: 22, marginTop: 20, width: '90vw', maxWidth: 600, top: 0, textAlign: 'center'}}

        >
          Haute searches platforms like The RealReal, Fashionphile, and Rebag to find the best prices.
        </Typography>

        <Stack style={{marginTop: 0, width: '75%'}}>
        <NoDrops
        title=''
        subtitle=''
        backgroundColor='black'
        />
        </Stack>

        

        {/* <TextField
          fullWidth
          type='text'
          size='small'
          label='Enter your email to get notified on our launch'
          style={{marginTop: 120, textAlign: 'center', padding: 0, outline: 'none', borderRadius: 25, backgroundColor: 'white', width: '90vw', maxWidth: 400}}
          // onChange={(e) => setInputValue('height', e.target.value)}
        /> */}
        
        {/* <Stack style={{
          fontSize: 20, 
          fontWeight: 'bold', 
          color: 'white',
          borderRadius: 10,
          paddingLeft: 20,
          paddingRight: 20,
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
          marginTop: '20vh'}}> */}

        {/* <Button 
          // className='carat-down'
          endIcon={<ArrowForwardIcon className='carat-down'/>}
          style={{
            fontWeight: 'bold', 
            fontSize: 18, 
            color: 'white',
            backgroundColor: 'transparent',
            borderRadius: 15,
            maxWidth: 500,
            width: '80%',
          }}
          onClick={()=>{
            if (divRef && divRef.current){
              divRef.current.scrollIntoView({ behavior: 'smooth'})
            }
          }}
          >
            SHOP THE DROPS
          </Button> */}
        {/* <NoDrops 
        backgroundColor='transparent'
        textColor='white'
        title=''
        subtitle=''
        /> */}
          
        {/* </Stack> */}
        {/* </Stack>
    </Stack>

    

    <div ref={divRef} style={{height: 0}}/>
    <InfiniteScroll
      dataLength={posts.length}
      next={()=>{load(user ? 'userPool' : 'iam')}}
      hasMore={!loaded}  
      loader={null}    
      
      style={{
        overflowY: 'hidden',
        width: '100vw',
        alignItems: 'center', 
        alignContent: 'center'
      }}
    >
      
        {posts.map((drop) => (
          <Stack style={{width: '100%', alignItems: 'center', alignContent: 'center'}}>
            <Stack onClick={()=>{navigate(`/user/${drop.userId}`)}} style={{cursor: 'pointer', borderRadius: 10, marginBottom: 0, width: '100%', paddingTop: 15, paddingBottom: 5, textAlign: 'center', alignItems: 'center', alignContent: 'center', marginTop: 0}}>
              <Typography style={{fontWeight: 'bold', fontSize: 16}}>
              {drop.name?.toUpperCase()} 
              </Typography>
              <Typography style={{fontWeight: 'bold', fontSize: 12, paddingLeft: 10, paddingRight: 10}}>
              {drop.description}
              </Typography>  

              <Stack direction='row' style={{}}>

                <Stack direction='row' style={{marginTop: 0, padding: 5, paddingTop: 0, paddingBottom: 2, borderRadius: 15}}>
              <Typography style={{fontSize: 10, marginTop: 2}}>
                by {drop.userId}
              </Typography> */}
              {/* <Image
                alt='header'
                duration={0}
                src={'Instagram.png'}
                onClick={(e)=>{
                  e.stopPropagation(); 
                  window.open(`https://www.instagram.com/${drop.userId}/`,'_blank');}}
                style={{width: 30, zIndex: 0, marginLeft: 5, height: 30}}
            /> */}
              </Stack>
              {/* <Typography style={{fontSize: 12, marginTop: 2, padding: 2, paddingLeft: 10, paddingRight: 10, borderRadius: 15,}}>
                by {drop.userId}
              </Typography> */}
              {/* <UserAvatar user={drop?.user} size={14}/> */}
              {/* <Stack direction='row' style={{marginTop: 6, padding: 5, paddingTop: 2, paddingBottom: 2, borderRadius: 15, backgroundColor: 'rgb(240, 240, 240)'}}>
              <Typography style={{fontSize: 10, marginTop: 2}}>
                Entrupy Verified Seller 
              </Typography>
              <VerifiedIcon fontSize='small' style={{marginLeft: 5}}/>
              </Stack> */}
              {/* </Stack> */}


              
          
            {/* </Stack>
          <ImageList
                cols={isSmallScreen ? 2 : 4}
                gap={2}
                style={(() => {
                  if (isSmallScreen)
                    return {
                      padding: 0,
                      // position: 'absolute',
                      left: 0,
                      right: 0,
                      width: '100%',
                      justifyContent: 'center',
                      overflowX: 'hidden',
                      paddingBottom: 0,
                      marginTop: 20,
                      alignItems: 'center', 
                      alignContent: 'center'
                    };
                  if (isMediumScreen)
                    return {
                      width: '100%',
                      alignSelf: 'center',
                      // marginLeft: '50%',
                      // transform: 'translateX(-50%)',
                      paddingBottom: 0,
                      marginTop: 20,
                      overflowX: 'hidden', 
                      alignItems: 'center', 
                      alignContent: 'center',
                    };
                  return {
                    width: '100%',
                    alignSelf: 'center',
                    // marginLeft: '50%',
                    // transform: 'translateX(-50%)',
                    paddingBottom: 0,
                    marginTop: 20,
                    overflowX: 'hidden', 
                    alignItems: 'center', 
                    alignContent: 'center',
                  };
                })()}
              >
            {drop.posts?.items.map((p) => <PostPreviewNewsfeed key={p?.id} post={p?.itemPost} /> || ''
            )}

            </ImageList>

          </Stack>
        ))}
    </InfiniteScroll>

    <Stack style={{marginTop: 50}}>
    <NoDrops
    title='GET NOTIFIED'
    subtitle='Receive Email Notifications On New Drops'
    />
    </Stack> */}

    {/* <Typography style={{width: '100%', marginTop: 100, fontSize: 20, fontWeight: 'bold', color: 'white', backgroundColor: 'pink', textAlign: 'center', padding: 50, backgroundImage: 'linear-gradient(to bottom right, rgb(244, 156, 207), rgb(253, 248, 159))'}}>
      WE'RE LAUNCHING SOON!
    </Typography> */}

    {/* <Stack style={{width: '100%', alignItems: 'center'}}>
    <Button 
        className='carat-down'
        endIcon={<ArrowForwardIcon className='carat-down'/>}
        onClick={()=>{
          navigate('/info')
        }}
        style={{
        fontSize: 16, 
        fontWeight: 'bold',
        marginBottom: 100, 
        color: 'darkgray',
        borderRadius: 10,
        paddingLeft: 20,
        paddingRight: 20,
        cursor: 'pointer',
        marginTop: 50}}>
          Find out more about us 
        </Button>
      </Stack> */}

    {/* <Typography style={{width: '100%', marginTop: 0, fontSize: 20, fontWeight: 'bold', color: 'white', backgroundColor: 'pink', textAlign: 'center', padding: 50, backgroundImage: 'linear-gradient(to bottom right, rgb(244, 156, 207), rgb(253, 248, 159))'}}>
      Shop specially curated drops from your fave content creators and Entrupy verified small businesses. <br/> 
    </Typography> */}

    {/* <Typography style={{fontSize: 18, width: '100%', textAlign: 'center', fontWeight: 'bold'}}>
      Secure transactions through
    </Typography> */}
    {/* <Stack style={{alignContent: 'center', alignItems: 'center', marginTop: 30}}>
    <Stack direction='row' style={{marginBottom: 30, alignContent: 'center', alignItems: 'center', width: 205, maxWidth: 600, alignSelf: 'center'}}>
    <Image src={'Entrupy.jpg'} duration={0} style={{width: 100, objectFit: 'cover'}}/>
    <Image src={'stripe.jpg'} duration={0} style={{width: 100, objectFit: 'cover'}}/>
    </Stack>
    </Stack> */}
    </Stack>

    <Stack style={{alignItems: 'center', width: '100%'}}>

      {isSmallScreen ? undefined : <Typography
      variant='h5'
      style={{textAlign: 'center', fontWeight: 'bold', marginTop: 80, width: '90%', maxWidth: 800, fontSize: 35}}
      >
          Replace 10 different apps with one.
      </Typography>}

      <Stack style={{borderRadius: 10, padding: 40, border: '1px solid lightgray', backgroundColor: 'rgba(72, 104, 220, 0.08)', width: '90%', maxWidth: 1300, marginTop: 150, margin: 20}}>
        {isSmallScreen ? <Typography
        variant='h6'
        style={{fontWeight: 'bold'}}
        >
          No more tracking 10 apps.
        </Typography> : <Typography
        variant='h6'
        style={{fontWeight: 'bold'}}
        >
          No more tracking 10 different resale apps.
        </Typography>} 
        <Typography
        style={{marginBottom: 20}}
        >
          There are countless listings on different platforms at different price points. We find all of them and help you compare.
        </Typography>

    
        <Image
          alt='header'
          duration={0}
          src={'haute-chanel-2.png'}
          style={{
            objectFit: 'contain',
            borderRadius: 20
          }}
        />

    
        {/* <Image
          alt='header'
          duration={0}
          src={'haute-omega.png'}
          style={{
            objectFit: 'contain',
          }}
        /> */}

      </Stack>

        {/* <Typography
        variant='h5'
        style={{textAlign: 'center', marginTop: 0, width: '90%', fontWeight: 'bold', maxWidth: 1200, fontSize: 35}}
        >
          No more keeping track of multiple platforms.
        </Typography> */}
        <Typography
        style={{marginTop: 40, fontSize: 20, textAlign: 'center', width: '90%', maxWidth: 1300}}
        >
          Browse all relevant listings from our list of 20+ curated, trusted retailers.
        </Typography>
        <Stack 
        style={{marginTop: 20, borderRadius: 10, padding: 20, border: '1px solid lightgray', backgroundColor: 'white', width: '90%', maxWidth: 1300}}
        >
        <Stack 
        direction='row'
        >
          <Image
            alt='header'
            duration={0}
            src={'Poshmark.png' || 'placeholder-image.png'}
            style={{height: 50, objectFit: 'contain'}}
          />
          <Image
            alt='header'
            duration={0}
            src={'ebay.png' || 'placeholder-image.png'}
            style={{height: 50, objectFit: 'contain'}}
          />
          <Image
            alt='header'
            duration={0}
            src={'fashionphile.png' || 'placeholder-image.png'}
            style={{height: 50, objectFit: 'contain'}}
          />
          <Image
            alt='header'
            duration={0}
            src={'mac.png' || 'placeholder-image.png'}
            style={{height: 50, objectFit: 'contain'}}
          />
          <Image
            alt='header'
            duration={0}
            src={'yoogi.png' || 'placeholder-image.png'}
            style={{height: 50, objectFit: 'contain'}}
          />
        </Stack>
        <Stack 
        direction='row'
        >
          <Image
            alt='header'
            duration={0}
            src={'vestiaire.png' || 'placeholder-image.png'}
            style={{height: 50, objectFit: 'contain'}}
          />
          <Image
            alt='header'
            duration={0}
            src={'rebag.png' || 'placeholder-image.png'}
            style={{height: 50, objectFit: 'contain'}}
          />
          <Image
            alt='header'
            duration={0}
            src={'trr.png' || 'placeholder-image.png'}
            style={{height: 50, objectFit: 'contain'}}
          />
          <Image
            alt='header'
            duration={0}
            src={'grailed.png' || 'placeholder-image.png'}
            style={{height: 50, objectFit: 'contain'}}
          />

        </Stack>
        </Stack>

      {/* <Typography
      variant='h5'
      style={{textAlign: 'center', fontWeight: 'bold', marginTop: 50, width: '90%', maxWidth: 800, fontSize: 35}}
      >
       We'll help you identify it.
      </Typography>

      <Stack style={{borderRadius: 10, padding: 40, border: '1px solid lightgray', backgroundColor: 'rgba(0,0,0, 0.06)', width: '90%', maxWidth: 1200, marginTop: 150, margin: 20}}>
        <Typography
        variant='h6'
        style={{}}
        >
         Send in or upload your screenshots and we'll show you where to buy it.
        </Typography>
        <Typography
        style={{}}
        >
          We find listings across sellers that match what you're looking for. Even with just a picture.
        </Typography>

        XXXX
        

      </Stack> */}

      {isSmallScreen ? undefined : <Typography
      variant='h5'
      style={{textAlign: 'center', fontWeight: 'bold', marginTop: 50, width: '90%', maxWidth: 800, fontSize: 35}}
      >
       Access exclusive price data.
      </Typography>}

      <Stack style={{borderRadius: 10, padding: 40, border: '1px solid lightgray', backgroundColor: 'rgba(72, 104, 220, 0.08)', width: '90%', maxWidth: 1300, marginTop: 50, margin: 20}}>
        {isSmallScreen ? <Typography
        variant='h6'
        style={{fontWeight: 'bold'}}
        >
         See relevant pricing data.
        </Typography> : <Typography
        variant='h6'
        style={{fontWeight: 'bold'}}
        >
         See price data for listings like averages and typical price ranges.
        </Typography>}
        <Typography
        style={{}}
        >
          See average price data to make sure you're always buying at a great price and aren't accidentally buying dupes.
        </Typography>

        XXXX

      </Stack>

      {/* <Stack direction='row' style={{width: '90%', maxWidth: 1200}}>
        <Stack style={{borderRadius: 10, padding: 20, border: '1px solid lightgray', backgroundColor: 'rgba(0,0,0, 0.06)', width: '47%', marginRight: '6%'}}>

        </Stack>
        <Stack style={{borderRadius: 10, padding: 20, border: '1px solid lightgray', backgroundColor: 'rgba(0,0,0, 0.06)', width: '47%'}}>
          
        </Stack>
      </Stack> */}

       <Stack style={{marginTop: 40, marginBottom: 0, width: '75%'}}>
        <NoDrops
        title=''
        subtitle=''
        textColor='white'
        backgroundColor='black'
        />
        </Stack>

      <Typography
      variant='h5'
      style={{textAlign: 'center', fontWeight: 'bold', marginTop: 50, width: '90%', maxWidth: 800, fontSize: 35}}
      >
        No more juggling multiple open tabs.
      </Typography>
      <Stack style={{borderRadius: 10, padding: 40, border: '1px solid lightgray', backgroundColor: 'rgba(72, 104, 220, 0.08)', width: '90%', maxWidth: 1300, marginTop: 150, margin: 20}}>
        {isSmallScreen ? <Typography
        variant='h6'
        style={{fontWeight: 'bold'}}
        >
         Save and compare in one place.
        </Typography> : <Typography
        variant='h6'
        style={{fontWeight: 'bold'}}
        >
         Save and compare all listings within one app. 
        </Typography>}
        <Typography
        style={{marginBottom: 20}}
        >
          Save and compare listings across platforms within one app. Make notes on condition, wear & tear, and more.
        </Typography>

    
        <Image
          alt='header'
          duration={0}
          src={'haute-chanel-2.png'}
          style={{
            objectFit: 'contain',
          }}
        />

    
        {/* <Image
          alt='header'
          duration={0}
          src={'haute-omega.png'}
          style={{
            objectFit: 'contain',
          }}
        /> */}

      </Stack>

      <Stack style={{marginTop: 40, marginBottom: 150, width: '75%'}}>
        <NoDrops
        title=''
        subtitle=''
        textColor='black'
        backgroundColor='black'
        />
        </Stack>

    </Stack>
    
    </div>
  );
}

export default ExplorePostTab;
