import { generateClient } from 'aws-amplify/api';
import { useCallback, useState } from 'react';
import { Post, PostType, UpdatePostInput } from '../API.ts';
import { listDrops } from '../graphql/queries.js';
import * as mutations from '../graphql/mutations.js';

const client = generateClient();

const loadItemPostsQuery = /* GraphQL */ `
  query PostsByUserIdAndTypeAndCreatedAtQuery(
    $userId: ID!
    $isCapsule: Boolean
    $nextToken: String
  ) {
    postsByUserIdAndTypeAndCreatedAt(
      userId: $userId
      typeCreatedAt: { beginsWith: { type: ITEM } }
      filter: { isCapsule: { eq: $isCapsule } }
      nextToken: $nextToken
      sortDirection: DESC
      limit: 10
    ) {
      items {
        id
        type
        user {
          id
          cognitoId
        }
        createdAt
        numLikes
        numComments
        images
        video
        caption
        category
        designer
        name
        color
        material
        size
        isCapsule
        sellingPrice
        purchasePrice
        isSold
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
      }
      nextToken
    }
  }
`;

const loadTrackerPostsQuery = /* GraphQL */ `
  query PostsByUserIdAndCreatedAtQuery(
    $userId: ID!
    $nextToken: String
  ) {
    postsByUserIdAndTypeAndCreatedAt(
      userId: $userId
      typeCreatedAt: { beginsWith: { type: ITEM } }
      nextToken: $nextToken
      sortDirection: DESC
    ) {
      items {
        id
        type
        user {
          id
          cognitoId
        }
        createdAt
        numLikes
        numComments
        images
        video
        caption
        category
        designer
        name
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
      }
      nextToken
    }
  }
`;

const loadOutfitPostsQuery = /* GraphQL */ `
  query PostsByUserIdAndTypeAndCreatedAtQuery(
    $userId: ID!
    $nextToken: String
  ) {
    postsByUserIdAndTypeAndCreatedAt(
      userId: $userId
      typeCreatedAt: { beginsWith: { type: OUTFIT } }
      nextToken: $nextToken
      sortDirection: DESC
      limit: 10
    ) {
      items {
        id
        type
        user {
          id
          cognitoId
        }
        createdAt
        numLikes
        numComments
        images
        caption
        video
      }
      nextToken
    }
  }
`;

const loadDrops = /* GraphQL */ `
  query ListDropsQuery(
    $userId: ID, 
    $start: String, 
    $end: String,

  ) {
    listDrops(
      filter: {
        userId: {eq: $userId},
        launchTime: {between: [$start, $end] }
      }
    ) { items {
        id
        name
        description
        launchTime
        createdAt
        userId
        user {
          id,
          cognitoId,
          email,
          firstName,
          middleName,
          lastName,
          phone,
          bio,
          avatar,
          createdAt,
          updatedAt
        }
        posts {
          items {
            id,
            userId,
            dropId,
            itemId,
            itemPost {
              id
              user {
                id
                avatar
                cognitoId
              }
              numLikes
              numComments
              type
              dropId
              images
              video
              caption
              category
              designer
              name
              color
              material
              sellingPrice
              purchasePrice
              isSold
              isCompleted
              height
              width
              depth
              chainDrop
              interiorCondition
              exteriorCondition
              isVintage
              callouts
              oldPrice
              productionYear
              hardware
              dustbag
              box
              serialNumber
              authCard
              authenticityGuarantee
              size
              createdAt
            },
            createdAt,
            updatedAt,
            __typename
          }
        }

    }
      }
    }
`;

export default function usePosts(
  userId: string | undefined,
  tab: string,
  auth_mode,
  isCapsule?: boolean,
) {
  const [posts, setPosts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [trackerPosts, setTrackerPosts] = useState<Post[]>([]);
  
  function addWeeks(date, weeks) {
    date.setDate(date.getDate() + 7 * weeks);
    return date;
  }

  const loadPosts = useCallback(async (tab) => {
    if (!userId) return;
    setLoading(true);
    let response;
    let newPosts;
    if (tab==='current'){
    response = (await client.graphql({
      query: loadDrops,
      variables: {
          userId: userId,
          start: addWeeks(new Date(), -1),
          end: addWeeks(new Date(), 6),
          // end: new Date(),
          }
          // isCapsule,
          // nextToken,
        
      , authMode: auth_mode}
    )) as any;
     newPosts =
      [response.data.listDrops.items[0]];
    } else{
      response = (await client.graphql({
      query: loadDrops,
      variables: {
          userId: userId,
          start: addWeeks(new Date(), -1),
          end: addWeeks(new Date(), 6),
          // end: new Date(),
          }
          // isCapsule,
          // nextToken,
        
      , authMode: auth_mode}
    )) as any;
     newPosts =
      response.data.listDrops.items.splice(1);
    }
   
    setPosts(newPosts);
    // setNextToken(newToken);
    setLoading(false);
  }, [userId, isCapsule, nextToken]);

  const loadTrackerPosts = useCallback(async () => {
    if (!userId) return null;
    setLoading(true);
    setTrackerPosts([]);
    const response = (await client.graphql({
      query: 
        loadTrackerPostsQuery,
      variables: 
        {
          userId,
          nextToken,
        }
      , authMode: auth_mode}
    )) as any;

    const { items: newPosts, nextToken: newToken } =
      response.data.postsByUserIdAndTypeAndCreatedAt;
    setTrackerPosts((prevPosts) => [...prevPosts, ...newPosts]);
    setNextToken(newToken);
    setLoading(false);
    return newPosts;
  }, [userId, nextToken]);

  const addNumWears = useCallback(async (list) => {
    setLoading(true);
    list.map(async (listObj)=>{
      const input: UpdatePostInput = listObj;
      (await client.graphql({
        query: mutations.updatePost, variables: { input }, authMode: auth_mode}
      )) as any;
      })
    setLoading(false);
  }, []);

  // const loadAllPosts = useCallback(async () => {
  //   if (!userId) return;
  //   setLoading(true);
  //   let allPosts: Post[] = [];
  //   let token = null;
  //   do {
  //     // eslint-disable-next-line no-await-in-loop
  //     const response = (await client.graphql({
  //       query: (
  //         postType === PostType.ITEM
  //           ? loadItemPostsQuery
  //           : loadOutfitPostsQuery),
  //         variables:
  //         {
  //           userId,
  //           isCapsule,
  //           nextToken: token,
  //         }
  //       , authMode: auth_mode}
  //     )) as any;
  //     const { items: newPosts, nextToken: newToken } =
  //       response.data.postsByUserIdAndTypeAndCreatedAt;
  //     allPosts = [...allPosts, ...newPosts];
  //     token = newToken;
  //   } while (token !== null);
  //   setPosts(allPosts);
  //   setNextToken(token);
  //   setLoading(false);
  // }, [userId, postType, isCapsule]);

  return { posts, trackerPosts, loading, loadPosts, loadTrackerPosts, addNumWears };
}
