import { ChevronLeftOutlined, Logout, Settings } from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, Button, Drawer, ListItemIcon, Stack, Typography, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Image from 'mui-image';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { styled, useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import { Notification } from '../../API';
// import { UserAvatar } from '../../components';
import useAuth from '../../hooks/useAuth.ts';
import Logo from '../../components/Logo.tsx';
import useUser from '../../hooks/useUser.ts';


// {isSmallScreen ?  undefined :
//           <Box sx={{ flexGrow: 0, paddingRight: 1 }}>
//           <Button onClick={()=>toggleDrawer(true)}><MenuIcon fontSize='large' /></Button>
//           <Drawer open={open} onClose={()=>toggleDrawer(false)} PaperProps={{
//             sx: {
//               backgroundColor: 'rgba(250, 250, 250, 0.8)',
//               padding: 5,
//               paddingTop: 2
//             }
//           }}>
            
//             <Typography style={{padding: 3}} onClick={()=>{navigate('/')}}>
//               Shop Drops
//             </Typography>
//             <Typography style={{padding: 3}} onClick={()=>{user ? navigate(`/myaccount/${user?.username}`) : navigate(`/myaccount/createaccount`)}}>
//               My Account
//             </Typography>
//             <Typography style={{padding: 3}} onClick={()=>{navigate('/sell')}}>
//               Sell
//             </Typography>
//           </Drawer> 
//           </Box>}

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

export default function MainAppBar() {
  const navigate = useNavigate();
  const { handleSignOut, user } = useAuth();
  const { user: profileUser } = useUser();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const location = useLocation();
  const paths = ['', 'search', 'explore', 'home'];
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, toggleDrawer] = React.useState(false);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleSettingsClick = useCallback(() => {
    setAnchorEl(null);
    navigate('/account/settings');
  }, [navigate]);

  const handleSignOutClick = useCallback(async () => {
    setAnchorEl(null);
    handleSignOut();
  }, [handleSignOut]);

  // const handleNotificationsClick = useCallback(() => {
  //   navigate('/notifications');
  //   markNotificationsAsRead(notifications.false.map((n: Notification) => n.id));
  // }, [navigate, markNotificationsAsRead, notifications.false]);

  const handleNavigation = useCallback(() => {
    window.history.back();
  }, []);

  const menu = useMemo(
    () => (
      <Menu
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleSettingsClick}>
          <ListItemIcon>
            <Settings fontSize='small' />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleSignOutClick}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    ),
    [anchorEl, handleSignOutClick, handleSettingsClick]
  );

  
  return (
    <Box sx={{ justifyContent: 'space-around'}}>
      <AppBar
        position='fixed'
        sx={{
          backgroundColor: 'white',
          paddingBottom: 1,
          boxShadow: 'none', //isSmallScreen ? '0px 2px 4px rgba(0, 0, 0, 0.3);' : 'none',
        }}
      >
        

        <Toolbar>
        <Stack style={{position: 'absolute', left: '5%', top: 10}}>
        <Image
          alt='header'
          duration={0}
          src={'LetterLogo.png'}
          style={{
            objectFit: 'contain',
            width: 40
          }}
        />
        </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ flexGrow: 0, paddingLeft: 1, marginRight: '3%'}}>
          <Stack direction='row' style={{cursor: 'pointer'}}>
              <Typography style={{cursor: 'pointer', fontWeight: 'bold', padding: 10, marginTop: 5, backgroundColor: 'rgb(72, 104, 220)', color: 'white', fontSize: 18, paddingLeft: 40, paddingRight: 40, borderRadius: 10}} onClick={()=>{navigate('/login')}}>Sign In</Typography>
          {/* <Typography variant='h5' style={{cursor: 'pointer', fontWeight: 'bold', padding: 15, marginTop: 5, backgroundColor: 'blue', color: 'white', fontSize: 18, paddingLeft: 20, paddingRight: 20, borderRadius: 10}}>
            Get free access →
          </Typography> */}
          </Stack>
          </Box>
        </Toolbar>
      </AppBar>
      <Offset />
      {menu}
    </Box>
  );
}
