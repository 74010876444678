/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateNotifications = /* GraphQL */ `
  mutation UpdateNotifications($input: UpdateNotificationsInput!) {
    updateNotifications(input: $input)
  }
`;
export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createLike = /* GraphQL */ `
  mutation CreateLike(
    $input: CreateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    createLike(input: $input, condition: $condition) {
      id
      type
      userId
      user {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      postId
      post {
        id
        type
        userId
        createdAt
        numLikes
        numComments
        images
        styleImages
        caption
        inactive
        category
        designer
        dropId
        name
        orderId
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        video
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        updatedAt
        owner
        __typename
      }
      commentId
      comment {
        id
        type
        userId
        postId
        numLikes
        commentId
        numReplies
        text
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateLike = /* GraphQL */ `
  mutation UpdateLike(
    $input: UpdateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    updateLike(input: $input, condition: $condition) {
      id
      type
      userId
      user {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      postId
      post {
        id
        type
        userId
        createdAt
        numLikes
        numComments
        images
        styleImages
        caption
        inactive
        category
        designer
        dropId
        name
        orderId
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        video
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        updatedAt
        owner
        __typename
      }
      commentId
      comment {
        id
        type
        userId
        postId
        numLikes
        commentId
        numReplies
        text
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteLike = /* GraphQL */ `
  mutation DeleteLike(
    $input: DeleteLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    deleteLike(input: $input, condition: $condition) {
      id
      type
      userId
      user {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      postId
      post {
        id
        type
        userId
        createdAt
        numLikes
        numComments
        images
        styleImages
        caption
        inactive
        category
        designer
        dropId
        name
        orderId
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        video
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        updatedAt
        owner
        __typename
      }
      commentId
      comment {
        id
        type
        userId
        postId
        numLikes
        commentId
        numReplies
        text
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      type
      userId
      user {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      postId
      post {
        id
        type
        userId
        createdAt
        numLikes
        numComments
        images
        styleImages
        caption
        inactive
        category
        designer
        dropId
        name
        orderId
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        video
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        updatedAt
        owner
        __typename
      }
      likes {
        nextToken
        __typename
      }
      numLikes
      commentId
      replies {
        nextToken
        __typename
      }
      numReplies
      text
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      type
      userId
      user {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      postId
      post {
        id
        type
        userId
        createdAt
        numLikes
        numComments
        images
        styleImages
        caption
        inactive
        category
        designer
        dropId
        name
        orderId
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        video
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        updatedAt
        owner
        __typename
      }
      likes {
        nextToken
        __typename
      }
      numLikes
      commentId
      replies {
        nextToken
        __typename
      }
      numReplies
      text
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      type
      userId
      user {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      postId
      post {
        id
        type
        userId
        createdAt
        numLikes
        numComments
        images
        styleImages
        caption
        inactive
        category
        designer
        dropId
        name
        orderId
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        video
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        updatedAt
        owner
        __typename
      }
      likes {
        nextToken
        __typename
      }
      numLikes
      commentId
      replies {
        nextToken
        __typename
      }
      numReplies
      text
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createLinkedPost = /* GraphQL */ `
  mutation CreateLinkedPost(
    $input: CreateLinkedPostInput!
    $condition: ModelLinkedPostConditionInput
  ) {
    createLinkedPost(input: $input, condition: $condition) {
      id
      userId
      parentId
      parentPost {
        id
        type
        userId
        createdAt
        numLikes
        numComments
        images
        styleImages
        caption
        inactive
        category
        designer
        dropId
        name
        orderId
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        video
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        updatedAt
        owner
        __typename
      }
      taggedId
      taggedPost {
        id
        type
        userId
        createdAt
        numLikes
        numComments
        images
        styleImages
        caption
        inactive
        category
        designer
        dropId
        name
        orderId
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        video
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        updatedAt
        owner
        __typename
      }
      pos {
        x
        y
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateLinkedPost = /* GraphQL */ `
  mutation UpdateLinkedPost(
    $input: UpdateLinkedPostInput!
    $condition: ModelLinkedPostConditionInput
  ) {
    updateLinkedPost(input: $input, condition: $condition) {
      id
      userId
      parentId
      parentPost {
        id
        type
        userId
        createdAt
        numLikes
        numComments
        images
        styleImages
        caption
        inactive
        category
        designer
        dropId
        name
        orderId
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        video
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        updatedAt
        owner
        __typename
      }
      taggedId
      taggedPost {
        id
        type
        userId
        createdAt
        numLikes
        numComments
        images
        styleImages
        caption
        inactive
        category
        designer
        dropId
        name
        orderId
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        video
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        updatedAt
        owner
        __typename
      }
      pos {
        x
        y
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteLinkedPost = /* GraphQL */ `
  mutation DeleteLinkedPost(
    $input: DeleteLinkedPostInput!
    $condition: ModelLinkedPostConditionInput
  ) {
    deleteLinkedPost(input: $input, condition: $condition) {
      id
      userId
      parentId
      parentPost {
        id
        type
        userId
        createdAt
        numLikes
        numComments
        images
        styleImages
        caption
        inactive
        category
        designer
        dropId
        name
        orderId
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        video
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        updatedAt
        owner
        __typename
      }
      taggedId
      taggedPost {
        id
        type
        userId
        createdAt
        numLikes
        numComments
        images
        styleImages
        caption
        inactive
        category
        designer
        dropId
        name
        orderId
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        video
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        updatedAt
        owner
        __typename
      }
      pos {
        x
        y
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createLinkedPostDrop = /* GraphQL */ `
  mutation CreateLinkedPostDrop(
    $input: CreateLinkedPostDropInput!
    $condition: ModelLinkedPostDropConditionInput
  ) {
    createLinkedPostDrop(input: $input, condition: $condition) {
      id
      userId
      dropId
      dropItem {
        id
        userId
        name
        description
        launchTime
        createdAt
        updatedAt
        owner
        __typename
      }
      itemId
      itemPost {
        id
        type
        userId
        createdAt
        numLikes
        numComments
        images
        styleImages
        caption
        inactive
        category
        designer
        dropId
        name
        orderId
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        video
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateLinkedPostDrop = /* GraphQL */ `
  mutation UpdateLinkedPostDrop(
    $input: UpdateLinkedPostDropInput!
    $condition: ModelLinkedPostDropConditionInput
  ) {
    updateLinkedPostDrop(input: $input, condition: $condition) {
      id
      userId
      dropId
      dropItem {
        id
        userId
        name
        description
        launchTime
        createdAt
        updatedAt
        owner
        __typename
      }
      itemId
      itemPost {
        id
        type
        userId
        createdAt
        numLikes
        numComments
        images
        styleImages
        caption
        inactive
        category
        designer
        dropId
        name
        orderId
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        video
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteLinkedPostDrop = /* GraphQL */ `
  mutation DeleteLinkedPostDrop(
    $input: DeleteLinkedPostDropInput!
    $condition: ModelLinkedPostDropConditionInput
  ) {
    deleteLinkedPostDrop(input: $input, condition: $condition) {
      id
      userId
      dropId
      dropItem {
        id
        userId
        name
        description
        launchTime
        createdAt
        updatedAt
        owner
        __typename
      }
      itemId
      itemPost {
        id
        type
        userId
        createdAt
        numLikes
        numComments
        images
        styleImages
        caption
        inactive
        category
        designer
        dropId
        name
        orderId
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        video
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createNewsletter = /* GraphQL */ `
  mutation CreateNewsletter(
    $input: CreateNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    createNewsletter(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateNewsletter = /* GraphQL */ `
  mutation UpdateNewsletter(
    $input: UpdateNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    updateNewsletter(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteNewsletter = /* GraphQL */ `
  mutation DeleteNewsletter(
    $input: DeleteNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    deleteNewsletter(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDrop = /* GraphQL */ `
  mutation CreateDrop(
    $input: CreateDropInput!
    $condition: ModelDropConditionInput
  ) {
    createDrop(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      name
      posts {
        nextToken
        __typename
      }
      description
      launchTime
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDrop = /* GraphQL */ `
  mutation UpdateDrop(
    $input: UpdateDropInput!
    $condition: ModelDropConditionInput
  ) {
    updateDrop(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      name
      posts {
        nextToken
        __typename
      }
      description
      launchTime
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDrop = /* GraphQL */ `
  mutation DeleteDrop(
    $input: DeleteDropInput!
    $condition: ModelDropConditionInput
  ) {
    deleteDrop(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      name
      posts {
        nextToken
        __typename
      }
      description
      launchTime
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createReservation = /* GraphQL */ `
  mutation CreateReservation(
    $input: CreateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    createReservation(input: $input, condition: $condition) {
      id
      itemID
      createdAt
      deletedAt
      sessionID
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateReservation = /* GraphQL */ `
  mutation UpdateReservation(
    $input: UpdateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    updateReservation(input: $input, condition: $condition) {
      id
      itemID
      createdAt
      deletedAt
      sessionID
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteReservation = /* GraphQL */ `
  mutation DeleteReservation(
    $input: DeleteReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    deleteReservation(input: $input, condition: $condition) {
      id
      itemID
      createdAt
      deletedAt
      sessionID
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      type
      userId
      user {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      likes {
        nextToken
        __typename
      }
      numLikes
      comments {
        nextToken
        __typename
      }
      numComments
      images
      styleImages
      caption
      inactive
      taggedPosts {
        nextToken
        __typename
      }
      parentPosts {
        nextToken
        __typename
      }
      category
      designer
      drop {
        nextToken
        __typename
      }
      dropId
      name
      orderId
      color
      material
      size
      purchasePrice
      sellingPrice
      isSold
      video
      isCompleted
      height
      width
      depth
      chainDrop
      interiorCondition
      exteriorCondition
      isVintage
      callouts
      oldPrice
      productionYear
      hardware
      dustbag
      box
      serialNumber
      authCard
      authenticityGuarantee
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      type
      userId
      user {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      likes {
        nextToken
        __typename
      }
      numLikes
      comments {
        nextToken
        __typename
      }
      numComments
      images
      styleImages
      caption
      inactive
      taggedPosts {
        nextToken
        __typename
      }
      parentPosts {
        nextToken
        __typename
      }
      category
      designer
      drop {
        nextToken
        __typename
      }
      dropId
      name
      orderId
      color
      material
      size
      purchasePrice
      sellingPrice
      isSold
      video
      isCompleted
      height
      width
      depth
      chainDrop
      interiorCondition
      exteriorCondition
      isVintage
      callouts
      oldPrice
      productionYear
      hardware
      dustbag
      box
      serialNumber
      authCard
      authenticityGuarantee
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      type
      userId
      user {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      likes {
        nextToken
        __typename
      }
      numLikes
      comments {
        nextToken
        __typename
      }
      numComments
      images
      styleImages
      caption
      inactive
      taggedPosts {
        nextToken
        __typename
      }
      parentPosts {
        nextToken
        __typename
      }
      category
      designer
      drop {
        nextToken
        __typename
      }
      dropId
      name
      orderId
      color
      material
      size
      purchasePrice
      sellingPrice
      isSold
      video
      isCompleted
      height
      width
      depth
      chainDrop
      interiorCondition
      exteriorCondition
      isVintage
      callouts
      oldPrice
      productionYear
      hardware
      dustbag
      box
      serialNumber
      authCard
      authenticityGuarantee
      updatedAt
      owner
      __typename
    }
  }
`;
export const createFeed = /* GraphQL */ `
  mutation CreateFeed(
    $input: CreateFeedInput!
    $condition: ModelFeedConditionInput
  ) {
    createFeed(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      followeeId
      followee {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      postId
      post {
        id
        type
        userId
        createdAt
        numLikes
        numComments
        images
        styleImages
        caption
        inactive
        category
        designer
        dropId
        name
        orderId
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        video
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        updatedAt
        owner
        __typename
      }
      postCreatedAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateFeed = /* GraphQL */ `
  mutation UpdateFeed(
    $input: UpdateFeedInput!
    $condition: ModelFeedConditionInput
  ) {
    updateFeed(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      followeeId
      followee {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      postId
      post {
        id
        type
        userId
        createdAt
        numLikes
        numComments
        images
        styleImages
        caption
        inactive
        category
        designer
        dropId
        name
        orderId
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        video
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        updatedAt
        owner
        __typename
      }
      postCreatedAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteFeed = /* GraphQL */ `
  mutation DeleteFeed(
    $input: DeleteFeedInput!
    $condition: ModelFeedConditionInput
  ) {
    deleteFeed(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      followeeId
      followee {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      postId
      post {
        id
        type
        userId
        createdAt
        numLikes
        numComments
        images
        styleImages
        caption
        inactive
        category
        designer
        dropId
        name
        orderId
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        video
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        updatedAt
        owner
        __typename
      }
      postCreatedAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createLinkedUser = /* GraphQL */ `
  mutation CreateLinkedUser(
    $input: CreateLinkedUserInput!
    $condition: ModelLinkedUserConditionInput
  ) {
    createLinkedUser(input: $input, condition: $condition) {
      id
      followeeId
      followee {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      followerId
      follower {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateLinkedUser = /* GraphQL */ `
  mutation UpdateLinkedUser(
    $input: UpdateLinkedUserInput!
    $condition: ModelLinkedUserConditionInput
  ) {
    updateLinkedUser(input: $input, condition: $condition) {
      id
      followeeId
      followee {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      followerId
      follower {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteLinkedUser = /* GraphQL */ `
  mutation DeleteLinkedUser(
    $input: DeleteLinkedUserInput!
    $condition: ModelLinkedUserConditionInput
  ) {
    deleteLinkedUser(input: $input, condition: $condition) {
      id
      followeeId
      followee {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      followerId
      follower {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      sellerID
      postID
      buyerEmail
      buyerID
      items {
        nextToken
        __typename
      }
      stripeProductIDs
      price
      tax
      checkoutSessionID
      shipping
      currency
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      sellerID
      postID
      buyerEmail
      buyerID
      items {
        nextToken
        __typename
      }
      stripeProductIDs
      price
      tax
      checkoutSessionID
      shipping
      currency
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      sellerID
      postID
      buyerEmail
      buyerID
      items {
        nextToken
        __typename
      }
      stripeProductIDs
      price
      tax
      checkoutSessionID
      shipping
      currency
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognitoId
      cognitoSub
      email
      firstName
      middleName
      lastName
      phone
      bio
      avatar
      followees {
        nextToken
        __typename
      }
      followers {
        nextToken
        __typename
      }
      numFollowees
      numFollowers
      posts {
        nextToken
        __typename
      }
      feeds {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      drops {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      numPosts
      stripeID
      isSeller
      isBusiness
      isInfluencer
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoId
      cognitoSub
      email
      firstName
      middleName
      lastName
      phone
      bio
      avatar
      followees {
        nextToken
        __typename
      }
      followers {
        nextToken
        __typename
      }
      numFollowees
      numFollowers
      posts {
        nextToken
        __typename
      }
      feeds {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      drops {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      numPosts
      stripeID
      isSeller
      isBusiness
      isInfluencer
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cognitoId
      cognitoSub
      email
      firstName
      middleName
      lastName
      phone
      bio
      avatar
      followees {
        nextToken
        __typename
      }
      followers {
        nextToken
        __typename
      }
      numFollowees
      numFollowers
      posts {
        nextToken
        __typename
      }
      feeds {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      drops {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      numPosts
      stripeID
      isSeller
      isBusiness
      isInfluencer
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDesigner = /* GraphQL */ `
  mutation CreateDesigner(
    $input: CreateDesignerInput!
    $condition: ModelDesignerConditionInput
  ) {
    createDesigner(input: $input, condition: $condition) {
      id
      name
      logo
      description
      website
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDesigner = /* GraphQL */ `
  mutation UpdateDesigner(
    $input: UpdateDesignerInput!
    $condition: ModelDesignerConditionInput
  ) {
    updateDesigner(input: $input, condition: $condition) {
      id
      name
      logo
      description
      website
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDesigner = /* GraphQL */ `
  mutation DeleteDesigner(
    $input: DeleteDesignerInput!
    $condition: ModelDesignerConditionInput
  ) {
    deleteDesigner(input: $input, condition: $condition) {
      id
      name
      logo
      description
      website
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      isRead
      type
      senderId
      sender {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      likeId
      like {
        id
        type
        userId
        postId
        commentId
        createdAt
        updatedAt
        owner
        __typename
      }
      commentId
      comment {
        id
        type
        userId
        postId
        numLikes
        commentId
        numReplies
        text
        createdAt
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      isRead
      type
      senderId
      sender {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      likeId
      like {
        id
        type
        userId
        postId
        commentId
        createdAt
        updatedAt
        owner
        __typename
      }
      commentId
      comment {
        id
        type
        userId
        postId
        numLikes
        commentId
        numReplies
        text
        createdAt
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      isRead
      type
      senderId
      sender {
        id
        cognitoId
        cognitoSub
        email
        firstName
        middleName
        lastName
        phone
        bio
        avatar
        numFollowees
        numFollowers
        numPosts
        stripeID
        isSeller
        isBusiness
        isInfluencer
        createdAt
        updatedAt
        owner
        __typename
      }
      likeId
      like {
        id
        type
        userId
        postId
        commentId
        createdAt
        updatedAt
        owner
        __typename
      }
      commentId
      comment {
        id
        type
        userId
        postId
        numLikes
        commentId
        numReplies
        text
        createdAt
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
