import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext.tsx';

export default function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(
      '`userAuth` hook must be used within a `AuthProvider` component'
    );
  }
  return context;
}