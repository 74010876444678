import { Grid, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Stack, useMediaQuery } from '@mui/system';
import Logo from './Logo.tsx';

export default function Footer() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack
      fontWeight='500'
      style={{
        width: '100vw', 
        marginTop: 0, 
        padding: 30, 
        height: 100, 
        backgroundColor: 'black', 
        position: 'absolute', 
        zIndex: -1,
        marginBottom: -100}}
    >
      <Grid container columns={2} justifyContent='flex-start'>
        <Grid style={{color: 'white', width: '50%', fontWeight: '400', fontSize: 9, position: 'absolute', top: isSmallScreen ? 20 : 30, left: 30}}>
          <Typography>
            💌 hi@hautegrid.com
          </Typography>          
        </Grid>

        <Grid style={{color: 'white', textAlign: 'right', width: '50%', fontWeight: '400', fontSize: 9, position: 'absolute', top: isSmallScreen ? 20 : 30, right: 30}}>
          <Typography>
            ❤️ Made with love in NYC 
          </Typography>

          {/* <Typography style={{cursor: 'pointer'}} onClick={()=>{navigate('/shipping-and-returns')}}>
            Returns/Shipping
          </Typography>
          <Typography style={{cursor: 'pointer'}} onClick={()=>{navigate('/authenticity')}}>
            Authenticity
          </Typography>
          {/* <Typography style={{cursor: 'pointer'}} onClick={()=>{navigate('/terms-and-privacy')}}>
            Privacy/Terms
          </Typography> */}
          {/* <Typography style={{cursor: 'pointer'}} onClick={()=>{navigate('/info')}}>
            About Us
          </Typography>  */}
        </Grid>
      </Grid>
      
    </Stack>
  );
}
