import { generateClient} from '@aws-amplify/api';
import { useCallback, useState } from 'react';
import { Drop, Post, PostType } from '../API.ts';

const client = generateClient();

// const loadPostsByTypeQuery = /* GraphQL */ `
//   query PostsByTypeAndCreatedAt($type: PostType!, $nextToken: String) {
//     postsByTypeAndCreatedAt(
//       type: $type
//       nextToken: $nextToken
//       sortDirection: DESC
//       limit: 18
//     ) {
//       items {
//         id
//         user {
//           id
//           avatar
//           cognitoId
//         }
//         numLikes
//         numComments
//         type
//         images
//         video
//         caption
//         category
//         designer
//         name
//         color
//         material
//         sellingPrice
//         purchasePrice
//         isSold
//         isCompleted
//         height
//         width
//         depth
//         chainDrop
//         interiorCondition
//         exteriorCondition
//         isVintage
//         callouts
//         oldPrice
//         productionYear
//         hardware
//         dustbag
//         box
//         serialNumber
//         authCard
//         authenticityGuarantee
//         size
//         createdAt
//         parentPosts {
//           items {
//             parentPost {
//               images
//               caption
//             }
//             pos {
//               x
//               y
//             }
//           }
//         }
//         taggedPosts {
//           items {
//             taggedPost {
//               id
//               images
//               caption
//               designer
//               name
//             }
//             pos {
//               x
//               y
//             }
//           }
//         }
//       }
//       nextToken
//     }
//   }
// `;

// const loadPostsByDropQuery = /* GraphQL */ `
//   query ListPosts($dropId: String!) {
//     listPosts(
//       filter: {dropId: {eq: $dropId}}
//     ) {
//       items {
//         id
//         user {
//           id
//           avatar
//           cognitoId
//         }
//         numLikes
//         numComments
//         type
//         dropId
//         images
//         video
//         caption
//         category
//         designer
//         name
//         color
//         material
//         sellingPrice
//         purchasePrice
//         isSold
//         isCompleted
//         height
//         width
//         depth
//         chainDrop
//         interiorCondition
//         exteriorCondition
//         isVintage
//         callouts
//         oldPrice
//         productionYear
//         hardware
//         dustbag
//         box
//         serialNumber
//         authCard
//         authenticityGuarantee
//         size
//         createdAt
//       }
//     }
//   }
// `;
// need to grab the user for each Drop. 
const loadDrops = /* GraphQL */ `
  query ListDropsQuery(
    $start: String, 
    $end: String, 
  ) {
    listDrops(
      filter: {launchTime: {ge: $start, le: $end}}
    ) { items {
        id
        name
        description
        launchTime
        createdAt
        userId
        user {
          id,
          cognitoId,
          email,
          firstName,
          middleName,
          lastName,
          phone,
          bio,
          avatar,
          createdAt,
          updatedAt
        }
        posts {
          items {
            id,
            userId,
            dropId,
            itemId,
            itemPost {
              id
              user {
                id
                avatar
                cognitoId
              }
              numLikes
              numComments
              type
              dropId
              images
              video
              caption
              category
              designer
              name
              color
              material
              sellingPrice
              purchasePrice
              isSold
              isCompleted
              height
              width
              depth
              chainDrop
              interiorCondition
              exteriorCondition
              isVintage
              callouts
              oldPrice
              productionYear
              hardware
              dustbag
              box
              serialNumber
              authCard
              authenticityGuarantee
              size
              createdAt
            },
            createdAt,
            updatedAt,
            __typename
          }
        }

    }
      }
    }
`;


const loadPostsByTypePreviewQuery = /* GraphQL */ `
  query PostsByTypeAndCreatedAt($type: PostType!, $nextToken: String) {
    postsByTypeAndCreatedAt(
      type: $type
      nextToken: $nextToken
      sortDirection: DESC
      limit: 6
    ) {
      items {
        id
        user {
          id
          avatar
          cognitoId
        }
        numLikes
        numComments
        type
        images
        video
        caption
        category
        designer
        name
        color
        material
        size
        purchasePrice
        sellingPrice
        isSold
        isCompleted
        height
        width
        depth
        chainDrop
        interiorCondition
        exteriorCondition
        isVintage
        callouts
        oldPrice
        productionYear
        hardware
        dustbag
        box
        serialNumber
        authCard
        authenticityGuarantee
        createdAt
        parentPosts {
          items {
            parentPost {
              images
              caption
            }
            pos {
              x
              y
            }
          }
        }
        taggedPosts {
          items {
            taggedPost {
              id
              images
              caption
              designer
              name
            }
            pos {
              x
              y
            }
          }
        }
      }
      nextToken
    }
  }
`;

function useExplorePosts(userId: string, postType: PostType, user) {
  const [posts, setPosts] = useState<Drop[]>([]);
  const [postsPreview, setPostsPreview] = useState<Post[]>([]);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  function addWeeks(date, weeks) {
    date.setDate(date.getDate() + 7 * weeks);
    return date;
  }

  const load = useCallback(async (auth_mode) => {
    setLoading(true);
    try {
      const drops = (await client.graphql({
        query: loadDrops, 
        variables: {
          start: addWeeks(new Date(), -52),
          end: new Date(),
        }, authMode: auth_mode}
      )) as any;
    

      // await Promise.all(
      //   drops.data.listDrops.items.map(async (i, index) => {
      //     const p = (await API.graphql(
      //       graphqlOperation(loadPostsByDropQuery, {
      //         // Do not retrieve user's posts
      //         dropId: i.id
      //       })
      //     )) as any;
      //     console.log('post', p);
      //     console.log(drops.data.listDrops.items[index].posts.items);
      //     // drops.data.listDrops.items[index].posts.items.push(p.data.listPosts.items)
      //   })
      // )

      // console.log('NEW DROPS', drops);


      setPosts(drops.data.listDrops.items);
      setLoaded(true);
    } finally {
      setLoading(false);
    } 
  }, [postType, nextToken]);

  const loadPreview = useCallback(async () => {
    setLoading(true);
    try {
      const response = (await client.graphql({
        query: loadPostsByTypePreviewQuery, 
        variables: {
          type: postType,
          filter: {}, // Do not retrieve user's posts
          nextToken,
        }, authMode: user ? 'userPool' : 'iam'}
      )) as any;
      const { items: newPosts, nextToken: newToken } =
        response.data.postsByTypeAndCreatedAt;
      setPostsPreview((prevPosts) => [...prevPosts, ...newPosts]);
      setNextToken(newToken);
      if (!newToken) setLoaded(true);
    } finally {
      setLoading(false);
    }
  }, [postType, nextToken]);

  return { posts, postsPreview, loading, loaded, load, loadPreview };
}

export default useExplorePosts;
