import { Button, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useMemo, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { generateClient } from 'aws-amplify/api';
import { createNewsletter } from '../graphql/mutations';
import { useAuth } from '../hooks/index.ts';
import { Email } from '@mui/icons-material';

const client = generateClient();

export default function NoDrops({
  backgroundColor='darkgray', 
  textColor='white',
  title='Get Notified When We Launch!',
  subtitle='Join our newsletter and get updates on new drops/listings.'}) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const {authMode} = useAuth();
  const isValidEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;



  const handleSubmit = useCallback(async (authMode) => {
    setSubmitting(true);
    if(email && email.length && isValidEmail.test(email) &&!submitted){
      const input = {
      email: email
    }
    const response = (await client.graphql({
        query: createNewsletter, variables: { input }, authMode: authMode as any}
      )) as any;
      setMessage(`We'll notify you as soon as we open up access!`);
      setSubmitted(true);
    }else if (!submitted){
      setMessage('Please enter a valid email.');
    }
    setSubmitting(false);
    
  }, [setMessage, message, email, submitted, setSubmitting, setSubmitted])

  const enterEmail = useMemo(()=>(
    <TextField
        style={{width: '90%', 
        maxWidth: 500, 
        backgroundColor: 'white',
        borderRadius: 5, 
        border: 'none',
        outline: 'none',
        marginTop: 25}}
        type='email'
        label='Enter Your Email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            handleSubmit(authMode);
            ev.preventDefault();
          }
        }}
        onSubmit={()=>{handleSubmit(authMode)}}
        // InputProps={{
        //   endAdornment: (
        //     <InputAdornment position='end'>
        //       <Button 
        //         disabled={submitting}
        //         style={{color: 'gray', fontWeight: 'bold', padding: 5, borderRadius: 5, border: `2px solid gray`}} onClick={()=>{handleSubmit(authMode)}}>
        //           Submit
        //       </Button>
        //     </InputAdornment>
        //   )
        // }}
      /> 
  ), [setEmail, email, submitted])

  return (
    <Stack style={{width: '100%'}}>
    <Stack  style={{backgroundColor: 'transparent', width: '100%', color: textColor, paddingTop: 20, paddingBottom: 20, textAlign: 'center', alignItems: 'center', alignContent: 'center'}}>
      <Typography style={{WebkitTextStroke: '0.5px black', fontWeight: 'bold', fontSize: 20, color: textColor}}>
      {title}
      </Typography>
      <Typography style={{fontWeight: 'bold', fontSize: 14, color: textColor}}>
       {subtitle}
      </Typography>
      {enterEmail} 
      
      <Typography style={{marginTop: 10, fontWeight: 'bold', color: 'rgb(72, 104, 220)'}}>
        {message}
      </Typography>

      <Typography onClick={()=>{handleSubmit(authMode)}} variant='h5' style={{cursor: 'pointer', fontWeight: 'bold', padding: 15, marginTop: 20, backgroundColor: 'rgb(72, 104, 220)', color: 'white', fontSize: 18, paddingLeft: 20, paddingRight: 20, borderRadius: 10}}>
        Try Haute free →
      </Typography>
    </Stack>
    </Stack>
  );
}
