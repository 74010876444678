import { useContext } from 'react';
import { CreatePostContext } from '../contexts/CreatePostContext.tsx';

const useCreatePost = () => {
  const context = useContext(CreatePostContext);
  if (context === undefined) {
    throw new Error(
      '`useCreatePost` hook must be used within a `CreatePostProvider` component'
    );
  }
  return context;
};

export default useCreatePost;
