/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type UpdateNotificationsInput = {
  ids: Array< string >,
  isRead: boolean,
};

export type CreateNewsletterInput = {
  id?: string | null,
  email: string,
};

export type CheckoutSession = {
  id?: string | null,

}

export type AddressInfo = {
  city?: string | null,
  country?: string | null,
  line1?: string | null,
  line2?: string | null,
  postal_code?: string | null,
  state?: string | null,
  name?: string | null
}

export type CustomerDetails = {
  email?: string | null,
  name?: string | null,
  phone?: string | null
}

export type ModelNewsletterConditionInput = {
  email?: ModelStringInput | null,
  and?: Array< ModelNewsletterConditionInput | null > | null,
  or?: Array< ModelNewsletterConditionInput | null > | null,
  not?: ModelNewsletterConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type Newsletter = {
  __typename: "Newsletter",
  id: string,
  email: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateNewsletterInput = {
  id: string,
  email?: string | null,
};

export type DeleteNewsletterInput = {
  id: string,
};

export type CreateLikeInput = {
  id?: string | null,
  type: LikeableType,
  userId: string,
  postId?: string | null,
  commentId?: string | null,
};

export enum LikeableType {
  COMMENT = "COMMENT",
  POST = "POST",
}


export type ModelLikeConditionInput = {
  type?: ModelLikeableTypeInput | null,
  userId?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  commentId?: ModelIDInput | null,
  and?: Array< ModelLikeConditionInput | null > | null,
  or?: Array< ModelLikeConditionInput | null > | null,
  not?: ModelLikeConditionInput | null,
};

export type ModelLikeableTypeInput = {
  eq?: LikeableType | null,
  ne?: LikeableType | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Like = {
  __typename: "Like",
  id: string,
  type: LikeableType,
  userId: string,
  user: User,
  postId?: string | null,
  post?: Post | null,
  commentId?: string | null,
  comment?: Comment | null,
  createdAt: string,
  updatedAt: string,
};

export type User = {
  __typename: "User",
  id?: string,
  cognitoId: string,
  cognitoSub: string,
  email: string,
  firstName: string,
  middleName?: string | null,
  lastName: string,
  phone?: string | null,
  bio?: string | null,
  avatar?: string | null,
  followees?: ModelLinkedUserConnection | null,
  followers?: ModelLinkedUserConnection | null,
  numFollowees: number,
  numFollowers: number,
  posts?: ModelPostConnection | null,
  feeds?: ModelFeedConnection | null,
  likes?: ModelLikeConnection | null,
  drops?: ModelDropConnection | null,
  comments?: ModelCommentConnection | null,
  notifications?: ModelNotificationConnection | null,
  numPosts: number,
  createdAt: string,
  updatedAt: string,
  isSeller: boolean,
  stripeID?: string,
  isBusiness?: boolean,
  isInfluencer?: boolean
};

export type ModelLinkedUserConnection = {
  __typename: "ModelLinkedUserConnection",
  items:  Array<LinkedUser | null >,
  nextToken?: string | null,
};

export type LinkedUser = {
  __typename: "LinkedUser",
  id: string,
  followeeId: string,
  followee: User,
  followerId: string,
  follower: User,
  createdAt: string,
  updatedAt: string,
};

export type ModelPostConnection = {
  __typename: "ModelPostConnection",
  items:  Array<Post | null >,
  nextToken?: string | null,
};

export type Post = {
  __typename: "Post",
  id: string,
  type: PostType,
  userId: string,
  user: User,
  condition?: string,
  createdAt: string,
  likes?: ModelLikeConnection | null,
  numLikes: number,
  comments?: ModelCommentConnection | null,
  numComments: number,
  images: Array< string >,
  caption?: string | null,
  taggedPosts?: ModelLinkedPostConnection | null,
  parentPosts?: ModelLinkedPostConnection | null,
  category?: string | null,
  designer?: string | null,
  drop?: ModelLinkedPostDropConnection | null,
  dropId: string,
  name?: string | null,
  color?: string | null,
  material?: string | null,
  size?: string | null,
  purchasePrice?: number | null,
  sellingPrice: number,
  isSold?: boolean | null,
  video?: string | null,
  isCompleted?: boolean | null,
  height?: number | null,
  width?: number | null,
  depth?: number | null,
  chainDrop?: number | null,
  interiorCondition?: string | null,
  exteriorCondition?: string | null,
  isVintage?: boolean | null,
  callouts?: string | null,
  oldPrice?: number | null,
  productionYear?: number | null,
  hardware?: string | null,
  dustbag?: boolean | null,
  box?: boolean | null,
  serialNumber?: boolean | null,
  authCard?: boolean | null,
  authenticityGuarantee?: string | null,
  updatedAt: string,
};

export type OrderItem = {
  __typename: "Post",
  id: string,
  images: Array< string >,
  designer?: string | null,
  name?: string | null,
  sellingPrice: number,
};

export type Order = {
  __typename: "Order",
  id: string,
  sellerID: string,
  postID: string,
  buyerEmail: string,
  buyerID?: string | null,
  items: ModelOrderItemsConnection,
  stripeProductIDs?: Array<string> | null,
  price: number,
  tax: number,
  checkoutSessionID: string,
  shipping: number,
  currency: string,
  createdAt: string
};

export enum PostType {
  ITEM = "ITEM",
  OUTFIT = "OUTFIT",
}


export type ModelLikeConnection = {
  __typename: "ModelLikeConnection",
  items:  Array<Like | null >,
  nextToken?: string | null,
};

export type ModelOrderItemsConnection = {
  _typename: "ModelOrderItemsConnection",
  items: Array<Post | null >,
  nextToken?: string | null
}

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
};

export type Comment = {
  __typename: "Comment",
  id: string,
  type: CommentableType,
  userId: string,
  user: User,
  postId?: string | null,
  post?: Post | null,
  likes?: ModelLikeConnection | null,
  numLikes: number,
  commentId?: string | null,
  replies?: ModelCommentConnection | null,
  numReplies: number,
  text: string,
  createdAt: string,
  updatedAt: string,
};

export enum CommentableType {
  COMMENT = "COMMENT",
  POST = "POST",
}


export type ModelLinkedPostConnection = {
  __typename: "ModelLinkedPostConnection",
  items:  Array<LinkedPost | null >,
  nextToken?: string | null,
};

export type LinkedPost = {
  __typename: "LinkedPost",
  id: string,
  userId: string,
  parentId: string,
  parentPost: Post,
  taggedId: string,
  taggedPost: Post,
  pos: TagPos,
  createdAt: string,
  updatedAt: string,
};

export type TagPos = {
  __typename: "TagPos",
  x: number,
  y: number,
};

export type ModelLinkedPostDropConnection = {
  __typename: "ModelLinkedPostDropConnection",
  items:  Array<LinkedPostDrop | null >,
  nextToken?: string | null,
};

export type LinkedPostDrop = {
  __typename: "LinkedPostDrop",
  id: string,
  userId: string,
  dropId: string,
  dropItem: Drop,
  itemId: string,
  itemPost: Post,
  createdAt: string,
  updatedAt: string,
};

export type Drop = {
  __typename: "Drop",
  id: string,
  userId: string,
  user: User,
  name: string,
  posts?: ModelLinkedPostDropConnection | null,
  description?: string | null,
  launchTime: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelFeedConnection = {
  __typename: "ModelFeedConnection",
  items:  Array<Feed | null >,
  nextToken?: string | null,
};

export type Feed = {
  __typename: "Feed",
  id: string,
  userId: string,
  user: User,
  followeeId: string,
  followee: User,
  postId: string,
  post: Post,
  postCreatedAt: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelDropConnection = {
  __typename: "ModelDropConnection",
  items:  Array<Drop | null >,
  nextToken?: string | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
};

export type Notification = {
  __typename: "Notification",
  id: string,
  userId: string,
  user: User,
  createdAt: string,
  isRead: boolean,
  type: NotificationType,
  senderId?: string | null,
  sender?: User | null,
  likeId?: string | null,
  like?: Like | null,
  commentId?: string | null,
  comment?: Comment | null,
  updatedAt: string,
};

export enum NotificationType {
  FOLLOW = "FOLLOW",
  LIKE = "LIKE",
  COMMENT = "COMMENT",
}


export type UpdateLikeInput = {
  id: string,
  type?: LikeableType | null,
  userId?: string | null,
  postId?: string | null,
  commentId?: string | null,
};

export type DeleteLikeInput = {
  id: string,
};

export type CreateCommentInput = {
  id?: string | null,
  type: CommentableType,
  userId: string,
  postId?: string | null,
  numLikes: number,
  commentId?: string | null,
  numReplies: number,
  text: string,
  createdAt?: string | null,
};

export type ModelCommentConditionInput = {
  type?: ModelCommentableTypeInput | null,
  userId?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  numLikes?: ModelIntInput | null,
  commentId?: ModelIDInput | null,
  numReplies?: ModelIntInput | null,
  text?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCommentConditionInput | null > | null,
  or?: Array< ModelCommentConditionInput | null > | null,
  not?: ModelCommentConditionInput | null,
};

export type ModelCommentableTypeInput = {
  eq?: CommentableType | null,
  ne?: CommentableType | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateCommentInput = {
  id: string,
  type?: CommentableType | null,
  userId?: string | null,
  postId?: string | null,
  numLikes?: number | null,
  commentId?: string | null,
  numReplies?: number | null,
  text?: string | null,
  createdAt?: string | null,
};

export type DeleteCommentInput = {
  id: string,
};

export type CreateLinkedPostInput = {
  id?: string | null,
  userId: string,
  parentId: string,
  taggedId: string,
  pos: TagPosInput,
};

export type TagPosInput = {
  x: number,
  y: number,
};

export type ModelLinkedPostConditionInput = {
  userId?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  taggedId?: ModelIDInput | null,
  and?: Array< ModelLinkedPostConditionInput | null > | null,
  or?: Array< ModelLinkedPostConditionInput | null > | null,
  not?: ModelLinkedPostConditionInput | null,
};

export type UpdateLinkedPostInput = {
  id: string,
  userId?: string | null,
  parentId?: string | null,
  taggedId?: string | null,
  pos?: TagPosInput | null,
};

export type DeleteLinkedPostInput = {
  id: string,
};

export type CreateLinkedPostDropInput = {
  id?: string | null,
  userId: string,
  dropId: string,
  itemId: string,
};

export type ModelLinkedPostDropConditionInput = {
  userId?: ModelIDInput | null,
  dropId?: ModelIDInput | null,
  itemId?: ModelIDInput | null,
  and?: Array< ModelLinkedPostDropConditionInput | null > | null,
  or?: Array< ModelLinkedPostDropConditionInput | null > | null,
  not?: ModelLinkedPostDropConditionInput | null,
};

export type UpdateLinkedPostDropInput = {
  id: string,
  userId?: string | null,
  dropId?: string | null,
  itemId?: string | null,
};

export type DeleteLinkedPostDropInput = {
  id: string,
};

export type CreateDropInput = {
  id?: string | null,
  userId: string,
  name: string,
  description?: string | null,
  launchTime: string,
  createdAt?: string | null,
};

export type ModelDropConditionInput = {
  userId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  launchTime?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelDropConditionInput | null > | null,
  or?: Array< ModelDropConditionInput | null > | null,
  not?: ModelDropConditionInput | null,
};

export type UpdateDropInput = {
  id: string,
  userId?: string | null,
  name?: string | null,
  description?: string | null,
  launchTime?: string | null,
  createdAt?: string | null,
};

export type DeleteDropInput = {
  id: string,
};

export type CreatePostInput = {
  id?: string | null,
  type: PostType,
  userId: string,
  createdAt?: string | null,
  numLikes: number,
  styleImages?: Array<string> | null,
  inactive?: boolean | null,
  numComments: number,
  images: Array< string >,
  caption?: string | null,
  category?: string | null,
  designer?: string | null,
  dropId: string,
  name?: string | null,
  color?: string | null,
  material?: string | null,
  size?: string | null,
  purchasePrice?: number | null,
  sellingPrice: number,
  isSold?: boolean | null,
  video?: string | null,
  isCompleted?: boolean | null,
  height?: number | null,
  width?: number | null,
  depth?: number | null,
  chainDrop?: number | null,
  interiorCondition?: string | null,
  exteriorCondition?: string | null,
  isVintage?: boolean | null,
  callouts?: string | null,
  oldPrice?: number | null,
  productionYear?: number | null,
  hardware?: string | null,
  dustbag?: boolean | null,
  box?: boolean | null,
  serialNumber?: boolean | null,
  authCard?: boolean | null,
  authenticityGuarantee?: string | null,
};

export type ModelPostConditionInput = {
  type?: ModelPostTypeInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  numLikes?: ModelIntInput | null,
  numComments?: ModelIntInput | null,
  images?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  category?: ModelStringInput | null,
  designer?: ModelStringInput | null,
  dropId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  color?: ModelStringInput | null,
  material?: ModelStringInput | null,
  size?: ModelStringInput | null,
  purchasePrice?: ModelIntInput | null,
  sellingPrice?: ModelIntInput | null,
  isSold?: ModelBooleanInput | null,
  video?: ModelStringInput | null,
  isCompleted?: ModelBooleanInput | null,
  height?: ModelFloatInput | null,
  width?: ModelFloatInput | null,
  depth?: ModelFloatInput | null,
  chainDrop?: ModelFloatInput | null,
  interiorCondition?: ModelStringInput | null,
  exteriorCondition?: ModelStringInput | null,
  isVintage?: ModelBooleanInput | null,
  callouts?: ModelStringInput | null,
  oldPrice?: ModelIntInput | null,
  productionYear?: ModelIntInput | null,
  hardware?: ModelStringInput | null,
  dustbag?: ModelBooleanInput | null,
  box?: ModelBooleanInput | null,
  serialNumber?: ModelBooleanInput | null,
  authCard?: ModelBooleanInput | null,
  authenticityGuarantee?: ModelStringInput | null,
  and?: Array< ModelPostConditionInput | null > | null,
  or?: Array< ModelPostConditionInput | null > | null,
  not?: ModelPostConditionInput | null,
};

export type ModelPostTypeInput = {
  eq?: PostType | null,
  ne?: PostType | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdatePostInput = {
  id: string,
  type?: PostType | null,
  userId?: string | null,
  createdAt?: string | null,
  numLikes?: number | null,
  numComments?: number | null,
  images?: Array< string > | null,
  caption?: string | null,
  category?: string | null,
  designer?: string | null,
  dropId?: string | null,
  name?: string | null,
  color?: string | null,
  material?: string | null,
  size?: string | null,
  purchasePrice?: number | null,
  sellingPrice?: number | null,
  isSold?: boolean | null,
  video?: string | null,
  isCompleted?: boolean | null,
  height?: number | null,
  width?: number | null,
  depth?: number | null,
  chainDrop?: number | null,
  interiorCondition?: string | null,
  exteriorCondition?: string | null,
  isVintage?: boolean | null,
  callouts?: string | null,
  oldPrice?: number | null,
  productionYear?: number | null,
  hardware?: string | null,
  dustbag?: boolean | null,
  box?: boolean | null,
  serialNumber?: boolean | null,
  authCard?: boolean | null,
  authenticityGuarantee?: string | null,
};

export type DeletePostInput = {
  id: string,
};

export type CreateFeedInput = {
  id?: string | null,
  userId: string,
  followeeId: string,
  postId: string,
  postCreatedAt: string,
};

export type ModelFeedConditionInput = {
  userId?: ModelIDInput | null,
  followeeId?: ModelIDInput | null,
  postCreatedAt?: ModelStringInput | null,
  and?: Array< ModelFeedConditionInput | null > | null,
  or?: Array< ModelFeedConditionInput | null > | null,
  not?: ModelFeedConditionInput | null,
};

export type UpdateFeedInput = {
  id: string,
  userId?: string | null,
  followeeId?: string | null,
  postId: string,
  postCreatedAt?: string | null,
};

export type DeleteFeedInput = {
  id: string,
  postId: string,
};

export type CreateLinkedUserInput = {
  id?: string | null,
  followeeId: string,
  followerId: string,
};

export type ModelLinkedUserConditionInput = {
  followeeId?: ModelIDInput | null,
  followerId?: ModelIDInput | null,
  and?: Array< ModelLinkedUserConditionInput | null > | null,
  or?: Array< ModelLinkedUserConditionInput | null > | null,
  not?: ModelLinkedUserConditionInput | null,
};

export type UpdateLinkedUserInput = {
  id: string,
  followeeId?: string | null,
  followerId?: string | null,
};

export type DeleteLinkedUserInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  cognitoId: string,
  cognitoSub: string,
  email: string,
  firstName: string,
  middleName?: string | null,
  lastName: string,
  phone?: string | null,
  bio?: string | null,
  avatar?: string | null,
  numFollowees: number,
  numFollowers: number,
  numPosts: number,
  isSeller: boolean,
};

export type ModelUserConditionInput = {
  cognitoId?: ModelStringInput | null,
  cognitoSub?: ModelStringInput | null,
  email?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  middleName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  numFollowees?: ModelIntInput | null,
  numFollowers?: ModelIntInput | null,
  numPosts?: ModelIntInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type UpdateUserInput = {
  id: string,
  cognitoId?: string | null,
  cognitoSub?: string | null,
  email?: string | null,
  firstName?: string | null,
  middleName?: string | null,
  lastName?: string | null,
  phone?: string | null,
  bio?: string | null,
  avatar?: string | null,
  numFollowees?: number | null,
  numFollowers?: number | null,
  numPosts?: number | null,
  stripeID?: string,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateDesignerInput = {
  id?: string | null,
  name: string,
  logo?: string | null,
  description?: string | null,
  website?: string | null,
};

export type ModelDesignerConditionInput = {
  name?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  description?: ModelStringInput | null,
  website?: ModelStringInput | null,
  and?: Array< ModelDesignerConditionInput | null > | null,
  or?: Array< ModelDesignerConditionInput | null > | null,
  not?: ModelDesignerConditionInput | null,
};

export type Designer = {
  __typename: "Designer",
  id: string,
  name: string,
  logo?: string | null,
  description?: string | null,
  website?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDesignerInput = {
  id: string,
  name?: string | null,
  logo?: string | null,
  description?: string | null,
  website?: string | null,
};

export type DeleteDesignerInput = {
  id: string,
};

export type CreateNotificationInput = {
  id?: string | null,
  userId: string,
  createdAt?: string | null,
  isRead: boolean,
  type: NotificationType,
  senderId?: string | null,
  likeId?: string | null,
  commentId?: string | null,
};

export type ModelNotificationConditionInput = {
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  isRead?: ModelBooleanInput | null,
  type?: ModelNotificationTypeInput | null,
  senderId?: ModelIDInput | null,
  likeId?: ModelIDInput | null,
  commentId?: ModelIDInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
};

export type ModelNotificationTypeInput = {
  eq?: NotificationType | null,
  ne?: NotificationType | null,
};

export type UpdateNotificationInput = {
  id: string,
  userId?: string | null,
  createdAt?: string | null,
  isRead?: boolean | null,
  type?: NotificationType | null,
  senderId?: string | null,
  likeId?: string | null,
  commentId?: string | null,
};

export type DeleteNotificationInput = {
  id: string,
};

export type ModelNewsletterFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelNewsletterFilterInput | null > | null,
  or?: Array< ModelNewsletterFilterInput | null > | null,
  not?: ModelNewsletterFilterInput | null,
};

export type ModelNewsletterConnection = {
  __typename: "ModelNewsletterConnection",
  items:  Array<Newsletter | null >,
  nextToken?: string | null,
};

export type ModelLikeFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelLikeableTypeInput | null,
  userId?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  commentId?: ModelIDInput | null,
  and?: Array< ModelLikeFilterInput | null > | null,
  or?: Array< ModelLikeFilterInput | null > | null,
  not?: ModelLikeFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelCommentFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelCommentableTypeInput | null,
  userId?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  numLikes?: ModelIntInput | null,
  commentId?: ModelIDInput | null,
  numReplies?: ModelIntInput | null,
  text?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCommentFilterInput | null > | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  not?: ModelCommentFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelLinkedPostFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  taggedId?: ModelIDInput | null,
  and?: Array< ModelLinkedPostFilterInput | null > | null,
  or?: Array< ModelLinkedPostFilterInput | null > | null,
  not?: ModelLinkedPostFilterInput | null,
};

export type ModelLinkedPostDropFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  dropId?: ModelIDInput | null,
  itemId?: ModelIDInput | null,
  and?: Array< ModelLinkedPostDropFilterInput | null > | null,
  or?: Array< ModelLinkedPostDropFilterInput | null > | null,
  not?: ModelLinkedPostDropFilterInput | null,
};

export type ModelDropFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  launchTime?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelDropFilterInput | null > | null,
  or?: Array< ModelDropFilterInput | null > | null,
  not?: ModelDropFilterInput | null,
};

export type SearchableDropFilterInput = {
  id?: SearchableIDFilterInput | null,
  userId?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  launchTime?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableDropFilterInput | null > | null,
  or?: Array< SearchableDropFilterInput | null > | null,
  not?: SearchableDropFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableDropSortInput = {
  field?: SearchableDropSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableDropSortableFields {
  id = "id",
  userId = "userId",
  name = "name",
  description = "description",
  launchTime = "launchTime",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableDropAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableDropAggregateField,
};

export enum SearchableAggregateType {
  terms = "terms",
  avg = "avg",
  min = "min",
  max = "max",
  sum = "sum",
}


export enum SearchableDropAggregateField {
  id = "id",
  userId = "userId",
  name = "name",
  description = "description",
  launchTime = "launchTime",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableDropConnection = {
  __typename: "SearchableDropConnection",
  items:  Array<Drop | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type SearchableAggregateResult = {
  __typename: "SearchableAggregateResult",
  name: string,
  result?: SearchableAggregateGenericResult | null,
};

export type SearchableAggregateGenericResult = SearchableAggregateScalarResult | SearchableAggregateBucketResult


export type SearchableAggregateScalarResult = {
  __typename: "SearchableAggregateScalarResult",
  value: number,
};

export type SearchableAggregateBucketResult = {
  __typename: "SearchableAggregateBucketResult",
  buckets?:  Array<SearchableAggregateBucketResultItem | null > | null,
};

export type SearchableAggregateBucketResultItem = {
  __typename: "SearchableAggregateBucketResultItem",
  key: string,
  doc_count: number,
};

export type ModelPostFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelPostTypeInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  numLikes?: ModelIntInput | null,
  numComments?: ModelIntInput | null,
  images?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  category?: ModelStringInput | null,
  designer?: ModelStringInput | null,
  dropId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  color?: ModelStringInput | null,
  material?: ModelStringInput | null,
  size?: ModelStringInput | null,
  purchasePrice?: ModelIntInput | null,
  sellingPrice?: ModelIntInput | null,
  isSold?: ModelBooleanInput | null,
  video?: ModelStringInput | null,
  isCompleted?: ModelBooleanInput | null,
  height?: ModelFloatInput | null,
  width?: ModelFloatInput | null,
  depth?: ModelFloatInput | null,
  chainDrop?: ModelFloatInput | null,
  interiorCondition?: ModelStringInput | null,
  exteriorCondition?: ModelStringInput | null,
  isVintage?: ModelBooleanInput | null,
  callouts?: ModelStringInput | null,
  oldPrice?: ModelIntInput | null,
  productionYear?: ModelIntInput | null,
  hardware?: ModelStringInput | null,
  dustbag?: ModelBooleanInput | null,
  box?: ModelBooleanInput | null,
  serialNumber?: ModelBooleanInput | null,
  authCard?: ModelBooleanInput | null,
  authenticityGuarantee?: ModelStringInput | null,
  and?: Array< ModelPostFilterInput | null > | null,
  or?: Array< ModelPostFilterInput | null > | null,
  not?: ModelPostFilterInput | null,
};

export type ModelPostByUserAndTypeCompositeKeyConditionInput = {
  eq?: ModelPostByUserAndTypeCompositeKeyInput | null,
  le?: ModelPostByUserAndTypeCompositeKeyInput | null,
  lt?: ModelPostByUserAndTypeCompositeKeyInput | null,
  ge?: ModelPostByUserAndTypeCompositeKeyInput | null,
  gt?: ModelPostByUserAndTypeCompositeKeyInput | null,
  between?: Array< ModelPostByUserAndTypeCompositeKeyInput | null > | null,
  beginsWith?: ModelPostByUserAndTypeCompositeKeyInput | null,
};

export type ModelPostByUserAndTypeCompositeKeyInput = {
  type?: PostType | null,
  createdAt?: string | null,
};

export type SearchablePostFilterInput = {
  id?: SearchableIDFilterInput | null,
  userId?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  numLikes?: SearchableIntFilterInput | null,
  numComments?: SearchableIntFilterInput | null,
  images?: SearchableStringFilterInput | null,
  caption?: SearchableStringFilterInput | null,
  category?: SearchableStringFilterInput | null,
  designer?: SearchableStringFilterInput | null,
  dropId?: SearchableStringFilterInput | null,
  name?: SearchableStringFilterInput | null,
  color?: SearchableStringFilterInput | null,
  material?: SearchableStringFilterInput | null,
  size?: SearchableStringFilterInput | null,
  purchasePrice?: SearchableIntFilterInput | null,
  sellingPrice?: SearchableIntFilterInput | null,
  isSold?: SearchableBooleanFilterInput | null,
  video?: SearchableStringFilterInput | null,
  isCompleted?: SearchableBooleanFilterInput | null,
  height?: SearchableFloatFilterInput | null,
  width?: SearchableFloatFilterInput | null,
  depth?: SearchableFloatFilterInput | null,
  chainDrop?: SearchableFloatFilterInput | null,
  interiorCondition?: SearchableStringFilterInput | null,
  exteriorCondition?: SearchableStringFilterInput | null,
  isVintage?: SearchableBooleanFilterInput | null,
  callouts?: SearchableStringFilterInput | null,
  oldPrice?: SearchableIntFilterInput | null,
  productionYear?: SearchableIntFilterInput | null,
  hardware?: SearchableStringFilterInput | null,
  dustbag?: SearchableBooleanFilterInput | null,
  box?: SearchableBooleanFilterInput | null,
  serialNumber?: SearchableBooleanFilterInput | null,
  authCard?: SearchableBooleanFilterInput | null,
  authenticityGuarantee?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  and?: Array< SearchablePostFilterInput | null > | null,
  or?: Array< SearchablePostFilterInput | null > | null,
  not?: SearchablePostFilterInput | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableFloatFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchablePostSortInput = {
  field?: SearchablePostSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePostSortableFields {
  id = "id",
  userId = "userId",
  createdAt = "createdAt",
  numLikes = "numLikes",
  numComments = "numComments",
  images = "images",
  caption = "caption",
  category = "category",
  designer = "designer",
  dropId = "dropId",
  name = "name",
  color = "color",
  material = "material",
  size = "size",
  purchasePrice = "purchasePrice",
  sellingPrice = "sellingPrice",
  isSold = "isSold",
  video = "video",
  isCompleted = "isCompleted",
  height = "height",
  width = "width",
  depth = "depth",
  chainDrop = "chainDrop",
  interiorCondition = "interiorCondition",
  exteriorCondition = "exteriorCondition",
  isVintage = "isVintage",
  callouts = "callouts",
  oldPrice = "oldPrice",
  productionYear = "productionYear",
  hardware = "hardware",
  dustbag = "dustbag",
  box = "box",
  serialNumber = "serialNumber",
  authCard = "authCard",
  authenticityGuarantee = "authenticityGuarantee",
  updatedAt = "updatedAt",
}


export type SearchablePostAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablePostAggregateField,
};

export enum SearchablePostAggregateField {
  id = "id",
  type = "type",
  userId = "userId",
  createdAt = "createdAt",
  numLikes = "numLikes",
  numComments = "numComments",
  images = "images",
  caption = "caption",
  category = "category",
  designer = "designer",
  dropId = "dropId",
  name = "name",
  color = "color",
  material = "material",
  size = "size",
  purchasePrice = "purchasePrice",
  sellingPrice = "sellingPrice",
  isSold = "isSold",
  video = "video",
  isCompleted = "isCompleted",
  height = "height",
  width = "width",
  depth = "depth",
  chainDrop = "chainDrop",
  interiorCondition = "interiorCondition",
  exteriorCondition = "exteriorCondition",
  isVintage = "isVintage",
  callouts = "callouts",
  oldPrice = "oldPrice",
  productionYear = "productionYear",
  hardware = "hardware",
  dustbag = "dustbag",
  box = "box",
  serialNumber = "serialNumber",
  authCard = "authCard",
  authenticityGuarantee = "authenticityGuarantee",
  updatedAt = "updatedAt",
}


export type SearchablePostConnection = {
  __typename: "SearchablePostConnection",
  items:  Array<Post | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelFeedFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  followeeId?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  postCreatedAt?: ModelStringInput | null,
  and?: Array< ModelFeedFilterInput | null > | null,
  or?: Array< ModelFeedFilterInput | null > | null,
  not?: ModelFeedFilterInput | null,
};

export type ModelLinkedUserFilterInput = {
  id?: ModelIDInput | null,
  followeeId?: ModelIDInput | null,
  followerId?: ModelIDInput | null,
  and?: Array< ModelLinkedUserFilterInput | null > | null,
  or?: Array< ModelLinkedUserFilterInput | null > | null,
  not?: ModelLinkedUserFilterInput | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  cognitoId?: ModelStringInput | null,
  cognitoSub?: ModelStringInput | null,
  email?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  middleName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  numFollowees?: ModelIntInput | null,
  numFollowers?: ModelIntInput | null,
  numPosts?: ModelIntInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type SearchableUserFilterInput = {
  id?: SearchableIDFilterInput | null,
  cognitoId?: SearchableStringFilterInput | null,
  cognitoSub?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  firstName?: SearchableStringFilterInput | null,
  middleName?: SearchableStringFilterInput | null,
  lastName?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  bio?: SearchableStringFilterInput | null,
  avatar?: SearchableStringFilterInput | null,
  numFollowees?: SearchableIntFilterInput | null,
  numFollowers?: SearchableIntFilterInput | null,
  numPosts?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableUserFilterInput | null > | null,
  or?: Array< SearchableUserFilterInput | null > | null,
  not?: SearchableUserFilterInput | null,
};

export type SearchableUserSortInput = {
  field?: SearchableUserSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableUserSortableFields {
  id = "id",
  cognitoId = "cognitoId",
  cognitoSub = "cognitoSub",
  email = "email",
  firstName = "firstName",
  middleName = "middleName",
  lastName = "lastName",
  phone = "phone",
  bio = "bio",
  avatar = "avatar",
  numFollowees = "numFollowees",
  numFollowers = "numFollowers",
  numPosts = "numPosts",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableUserAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableUserAggregateField,
};

export enum SearchableUserAggregateField {
  id = "id",
  cognitoId = "cognitoId",
  cognitoSub = "cognitoSub",
  email = "email",
  firstName = "firstName",
  middleName = "middleName",
  lastName = "lastName",
  phone = "phone",
  bio = "bio",
  avatar = "avatar",
  numFollowees = "numFollowees",
  numFollowers = "numFollowers",
  numPosts = "numPosts",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableUserConnection = {
  __typename: "SearchableUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelDesignerFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  description?: ModelStringInput | null,
  website?: ModelStringInput | null,
  and?: Array< ModelDesignerFilterInput | null > | null,
  or?: Array< ModelDesignerFilterInput | null > | null,
  not?: ModelDesignerFilterInput | null,
};

export type ModelDesignerConnection = {
  __typename: "ModelDesignerConnection",
  items:  Array<Designer | null >,
  nextToken?: string | null,
};

export type SearchableDesignerFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  logo?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  website?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableDesignerFilterInput | null > | null,
  or?: Array< SearchableDesignerFilterInput | null > | null,
  not?: SearchableDesignerFilterInput | null,
};

export type SearchableDesignerSortInput = {
  field?: SearchableDesignerSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableDesignerSortableFields {
  id = "id",
  name = "name",
  logo = "logo",
  description = "description",
  website = "website",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableDesignerAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableDesignerAggregateField,
};

export enum SearchableDesignerAggregateField {
  id = "id",
  name = "name",
  logo = "logo",
  description = "description",
  website = "website",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableDesignerConnection = {
  __typename: "SearchableDesignerConnection",
  items:  Array<Designer | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  isRead?: ModelBooleanInput | null,
  type?: ModelNotificationTypeInput | null,
  senderId?: ModelIDInput | null,
  likeId?: ModelIDInput | null,
  commentId?: ModelIDInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelSubscriptionNewsletterFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNewsletterFilterInput | null > | null,
  or?: Array< ModelSubscriptionNewsletterFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionLikeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  postId?: ModelSubscriptionIDInput | null,
  commentId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionLikeFilterInput | null > | null,
  or?: Array< ModelSubscriptionLikeFilterInput | null > | null,
};

export type ModelSubscriptionCommentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  postId?: ModelSubscriptionIDInput | null,
  numLikes?: ModelSubscriptionIntInput | null,
  commentId?: ModelSubscriptionIDInput | null,
  numReplies?: ModelSubscriptionIntInput | null,
  text?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCommentFilterInput | null > | null,
  or?: Array< ModelSubscriptionCommentFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionLinkedPostFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  parentId?: ModelSubscriptionIDInput | null,
  taggedId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionLinkedPostFilterInput | null > | null,
  or?: Array< ModelSubscriptionLinkedPostFilterInput | null > | null,
};

export type ModelSubscriptionLinkedPostDropFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  dropId?: ModelSubscriptionIDInput | null,
  itemId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionLinkedPostDropFilterInput | null > | null,
  or?: Array< ModelSubscriptionLinkedPostDropFilterInput | null > | null,
};

export type ModelSubscriptionDropFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  launchTime?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDropFilterInput | null > | null,
  or?: Array< ModelSubscriptionDropFilterInput | null > | null,
};

export type ModelSubscriptionPostFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  numLikes?: ModelSubscriptionIntInput | null,
  numComments?: ModelSubscriptionIntInput | null,
  images?: ModelSubscriptionStringInput | null,
  caption?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  designer?: ModelSubscriptionStringInput | null,
  dropId?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  color?: ModelSubscriptionStringInput | null,
  material?: ModelSubscriptionStringInput | null,
  size?: ModelSubscriptionStringInput | null,
  purchasePrice?: ModelSubscriptionIntInput | null,
  sellingPrice?: ModelSubscriptionIntInput | null,
  isSold?: ModelSubscriptionBooleanInput | null,
  video?: ModelSubscriptionStringInput | null,
  isCompleted?: ModelSubscriptionBooleanInput | null,
  height?: ModelSubscriptionFloatInput | null,
  width?: ModelSubscriptionFloatInput | null,
  depth?: ModelSubscriptionFloatInput | null,
  chainDrop?: ModelSubscriptionFloatInput | null,
  interiorCondition?: ModelSubscriptionStringInput | null,
  exteriorCondition?: ModelSubscriptionStringInput | null,
  isVintage?: ModelSubscriptionBooleanInput | null,
  callouts?: ModelSubscriptionStringInput | null,
  oldPrice?: ModelSubscriptionIntInput | null,
  productionYear?: ModelSubscriptionIntInput | null,
  hardware?: ModelSubscriptionStringInput | null,
  dustbag?: ModelSubscriptionBooleanInput | null,
  box?: ModelSubscriptionBooleanInput | null,
  serialNumber?: ModelSubscriptionBooleanInput | null,
  authCard?: ModelSubscriptionBooleanInput | null,
  authenticityGuarantee?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPostFilterInput | null > | null,
  or?: Array< ModelSubscriptionPostFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionFeedFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  followeeId?: ModelSubscriptionIDInput | null,
  postId?: ModelSubscriptionIDInput | null,
  postCreatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFeedFilterInput | null > | null,
  or?: Array< ModelSubscriptionFeedFilterInput | null > | null,
};

export type ModelSubscriptionLinkedUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  followeeId?: ModelSubscriptionIDInput | null,
  followerId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionLinkedUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionLinkedUserFilterInput | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  cognitoId?: ModelSubscriptionStringInput | null,
  cognitoSub?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  middleName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  bio?: ModelSubscriptionStringInput | null,
  avatar?: ModelSubscriptionStringInput | null,
  numFollowees?: ModelSubscriptionIntInput | null,
  numFollowers?: ModelSubscriptionIntInput | null,
  numPosts?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionDesignerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  logo?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  website?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDesignerFilterInput | null > | null,
  or?: Array< ModelSubscriptionDesignerFilterInput | null > | null,
};

export type ModelSubscriptionNotificationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  isRead?: ModelSubscriptionBooleanInput | null,
  type?: ModelSubscriptionStringInput | null,
  senderId?: ModelSubscriptionIDInput | null,
  likeId?: ModelSubscriptionIDInput | null,
  commentId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
  or?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
};

export type UpdateNotificationsMutationVariables = {
  input: UpdateNotificationsInput,
};

export type UpdateNotificationsMutation = {
  updateNotifications: string,
};

export type CreateNewsletterMutationVariables = {
  input: CreateNewsletterInput,
  condition?: ModelNewsletterConditionInput | null,
};

export type CreateNewsletterMutation = {
  createNewsletter?:  {
    __typename: "Newsletter",
    id: string,
    email: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNewsletterMutationVariables = {
  input: UpdateNewsletterInput,
  condition?: ModelNewsletterConditionInput | null,
};

export type UpdateNewsletterMutation = {
  updateNewsletter?:  {
    __typename: "Newsletter",
    id: string,
    email: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNewsletterMutationVariables = {
  input: DeleteNewsletterInput,
  condition?: ModelNewsletterConditionInput | null,
};

export type DeleteNewsletterMutation = {
  deleteNewsletter?:  {
    __typename: "Newsletter",
    id: string,
    email: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLikeMutationVariables = {
  input: CreateLikeInput,
  condition?: ModelLikeConditionInput | null,
};

export type CreateLikeMutation = {
  createLike?:  {
    __typename: "Like",
    id: string,
    type: LikeableType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null,
    commentId?: string | null,
    comment?:  {
      __typename: "Comment",
      id: string,
      type: CommentableType,
      userId: string,
      postId?: string | null,
      numLikes: number,
      commentId?: string | null,
      numReplies: number,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLikeMutationVariables = {
  input: UpdateLikeInput,
  condition?: ModelLikeConditionInput | null,
};

export type UpdateLikeMutation = {
  updateLike?:  {
    __typename: "Like",
    id: string,
    type: LikeableType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null,
    commentId?: string | null,
    comment?:  {
      __typename: "Comment",
      id: string,
      type: CommentableType,
      userId: string,
      postId?: string | null,
      numLikes: number,
      commentId?: string | null,
      numReplies: number,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLikeMutationVariables = {
  input: DeleteLikeInput,
  condition?: ModelLikeConditionInput | null,
};

export type DeleteLikeMutation = {
  deleteLike?:  {
    __typename: "Like",
    id: string,
    type: LikeableType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null,
    commentId?: string | null,
    comment?:  {
      __typename: "Comment",
      id: string,
      type: CommentableType,
      userId: string,
      postId?: string | null,
      numLikes: number,
      commentId?: string | null,
      numReplies: number,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    id: string,
    type: CommentableType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    numLikes: number,
    commentId?: string | null,
    replies?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    numReplies: number,
    text: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCommentMutationVariables = {
  input: UpdateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type UpdateCommentMutation = {
  updateComment?:  {
    __typename: "Comment",
    id: string,
    type: CommentableType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    numLikes: number,
    commentId?: string | null,
    replies?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    numReplies: number,
    text: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCommentMutationVariables = {
  input: DeleteCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type DeleteCommentMutation = {
  deleteComment?:  {
    __typename: "Comment",
    id: string,
    type: CommentableType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    numLikes: number,
    commentId?: string | null,
    replies?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    numReplies: number,
    text: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLinkedPostMutationVariables = {
  input: CreateLinkedPostInput,
  condition?: ModelLinkedPostConditionInput | null,
};

export type CreateLinkedPostMutation = {
  createLinkedPost?:  {
    __typename: "LinkedPost",
    id: string,
    userId: string,
    parentId: string,
    parentPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    taggedId: string,
    taggedPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    pos:  {
      __typename: "TagPos",
      x: number,
      y: number,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLinkedPostMutationVariables = {
  input: UpdateLinkedPostInput,
  condition?: ModelLinkedPostConditionInput | null,
};

export type UpdateLinkedPostMutation = {
  updateLinkedPost?:  {
    __typename: "LinkedPost",
    id: string,
    userId: string,
    parentId: string,
    parentPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    taggedId: string,
    taggedPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    pos:  {
      __typename: "TagPos",
      x: number,
      y: number,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLinkedPostMutationVariables = {
  input: DeleteLinkedPostInput,
  condition?: ModelLinkedPostConditionInput | null,
};

export type DeleteLinkedPostMutation = {
  deleteLinkedPost?:  {
    __typename: "LinkedPost",
    id: string,
    userId: string,
    parentId: string,
    parentPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    taggedId: string,
    taggedPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    pos:  {
      __typename: "TagPos",
      x: number,
      y: number,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLinkedPostDropMutationVariables = {
  input: CreateLinkedPostDropInput,
  condition?: ModelLinkedPostDropConditionInput | null,
};

export type CreateLinkedPostDropMutation = {
  createLinkedPostDrop?:  {
    __typename: "LinkedPostDrop",
    id: string,
    userId: string,
    dropId: string,
    dropItem:  {
      __typename: "Drop",
      id: string,
      userId: string,
      name: string,
      description?: string | null,
      launchTime: string,
      createdAt: string,
      updatedAt: string,
    },
    itemId: string,
    itemPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLinkedPostDropMutationVariables = {
  input: UpdateLinkedPostDropInput,
  condition?: ModelLinkedPostDropConditionInput | null,
};

export type UpdateLinkedPostDropMutation = {
  updateLinkedPostDrop?:  {
    __typename: "LinkedPostDrop",
    id: string,
    userId: string,
    dropId: string,
    dropItem:  {
      __typename: "Drop",
      id: string,
      userId: string,
      name: string,
      description?: string | null,
      launchTime: string,
      createdAt: string,
      updatedAt: string,
    },
    itemId: string,
    itemPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLinkedPostDropMutationVariables = {
  input: DeleteLinkedPostDropInput,
  condition?: ModelLinkedPostDropConditionInput | null,
};

export type DeleteLinkedPostDropMutation = {
  deleteLinkedPostDrop?:  {
    __typename: "LinkedPostDrop",
    id: string,
    userId: string,
    dropId: string,
    dropItem:  {
      __typename: "Drop",
      id: string,
      userId: string,
      name: string,
      description?: string | null,
      launchTime: string,
      createdAt: string,
      updatedAt: string,
    },
    itemId: string,
    itemPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDropMutationVariables = {
  input: CreateDropInput,
  condition?: ModelDropConditionInput | null,
};

export type CreateDropMutation = {
  createDrop?:  {
    __typename: "Drop",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    posts?:  {
      __typename: "ModelLinkedPostDropConnection",
      nextToken?: string | null,
    } | null,
    description?: string | null,
    launchTime: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDropMutationVariables = {
  input: UpdateDropInput,
  condition?: ModelDropConditionInput | null,
};

export type UpdateDropMutation = {
  updateDrop?:  {
    __typename: "Drop",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    posts?:  {
      __typename: "ModelLinkedPostDropConnection",
      nextToken?: string | null,
    } | null,
    description?: string | null,
    launchTime: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDropMutationVariables = {
  input: DeleteDropInput,
  condition?: ModelDropConditionInput | null,
};

export type DeleteDropMutation = {
  deleteDrop?:  {
    __typename: "Drop",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    posts?:  {
      __typename: "ModelLinkedPostDropConnection",
      nextToken?: string | null,
    } | null,
    description?: string | null,
    launchTime: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePostMutationVariables = {
  input: CreatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type CreatePostMutation = {
  createPost?:  {
    __typename: "Post",
    id: string,
    type: PostType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    numLikes: number,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    numComments: number,
    images: Array< string >,
    caption?: string | null,
    taggedPosts?:  {
      __typename: "ModelLinkedPostConnection",
      nextToken?: string | null,
    } | null,
    parentPosts?:  {
      __typename: "ModelLinkedPostConnection",
      nextToken?: string | null,
    } | null,
    category?: string | null,
    designer?: string | null,
    drop?:  {
      __typename: "ModelLinkedPostDropConnection",
      nextToken?: string | null,
    } | null,
    dropId: string,
    name?: string | null,
    color?: string | null,
    material?: string | null,
    size?: string | null,
    purchasePrice?: number | null,
    sellingPrice: number,
    isSold?: boolean | null,
    video?: string | null,
    isCompleted?: boolean | null,
    height?: number | null,
    width?: number | null,
    depth?: number | null,
    chainDrop?: number | null,
    interiorCondition?: string | null,
    exteriorCondition?: string | null,
    isVintage?: boolean | null,
    callouts?: string | null,
    oldPrice?: number | null,
    productionYear?: number | null,
    hardware?: string | null,
    dustbag?: boolean | null,
    box?: boolean | null,
    serialNumber?: boolean | null,
    authCard?: boolean | null,
    authenticityGuarantee?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdatePostMutationVariables = {
  input: UpdatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type UpdatePostMutation = {
  updatePost?:  {
    __typename: "Post",
    id: string,
    type: PostType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    numLikes: number,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    numComments: number,
    images: Array< string >,
    caption?: string | null,
    taggedPosts?:  {
      __typename: "ModelLinkedPostConnection",
      nextToken?: string | null,
    } | null,
    parentPosts?:  {
      __typename: "ModelLinkedPostConnection",
      nextToken?: string | null,
    } | null,
    category?: string | null,
    designer?: string | null,
    drop?:  {
      __typename: "ModelLinkedPostDropConnection",
      nextToken?: string | null,
    } | null,
    dropId: string,
    name?: string | null,
    color?: string | null,
    material?: string | null,
    size?: string | null,
    purchasePrice?: number | null,
    sellingPrice: number,
    isSold?: boolean | null,
    video?: string | null,
    isCompleted?: boolean | null,
    height?: number | null,
    width?: number | null,
    depth?: number | null,
    chainDrop?: number | null,
    interiorCondition?: string | null,
    exteriorCondition?: string | null,
    isVintage?: boolean | null,
    callouts?: string | null,
    oldPrice?: number | null,
    productionYear?: number | null,
    hardware?: string | null,
    dustbag?: boolean | null,
    box?: boolean | null,
    serialNumber?: boolean | null,
    authCard?: boolean | null,
    authenticityGuarantee?: string | null,
    updatedAt: string,
  } | null,
};

export type DeletePostMutationVariables = {
  input: DeletePostInput,
  condition?: ModelPostConditionInput | null,
};

export type DeletePostMutation = {
  deletePost?:  {
    __typename: "Post",
    id: string,
    type: PostType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    numLikes: number,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    numComments: number,
    images: Array< string >,
    caption?: string | null,
    taggedPosts?:  {
      __typename: "ModelLinkedPostConnection",
      nextToken?: string | null,
    } | null,
    parentPosts?:  {
      __typename: "ModelLinkedPostConnection",
      nextToken?: string | null,
    } | null,
    category?: string | null,
    designer?: string | null,
    drop?:  {
      __typename: "ModelLinkedPostDropConnection",
      nextToken?: string | null,
    } | null,
    dropId: string,
    name?: string | null,
    color?: string | null,
    material?: string | null,
    size?: string | null,
    purchasePrice?: number | null,
    sellingPrice: number,
    isSold?: boolean | null,
    video?: string | null,
    isCompleted?: boolean | null,
    height?: number | null,
    width?: number | null,
    depth?: number | null,
    chainDrop?: number | null,
    interiorCondition?: string | null,
    exteriorCondition?: string | null,
    isVintage?: boolean | null,
    callouts?: string | null,
    oldPrice?: number | null,
    productionYear?: number | null,
    hardware?: string | null,
    dustbag?: boolean | null,
    box?: boolean | null,
    serialNumber?: boolean | null,
    authCard?: boolean | null,
    authenticityGuarantee?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateFeedMutationVariables = {
  input: CreateFeedInput,
  condition?: ModelFeedConditionInput | null,
};

export type CreateFeedMutation = {
  createFeed?:  {
    __typename: "Feed",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    followeeId: string,
    followee:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId: string,
    post:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    postCreatedAt: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFeedMutationVariables = {
  input: UpdateFeedInput,
  condition?: ModelFeedConditionInput | null,
};

export type UpdateFeedMutation = {
  updateFeed?:  {
    __typename: "Feed",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    followeeId: string,
    followee:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId: string,
    post:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    postCreatedAt: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFeedMutationVariables = {
  input: DeleteFeedInput,
  condition?: ModelFeedConditionInput | null,
};

export type DeleteFeedMutation = {
  deleteFeed?:  {
    __typename: "Feed",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    followeeId: string,
    followee:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId: string,
    post:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    postCreatedAt: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLinkedUserMutationVariables = {
  input: CreateLinkedUserInput,
  condition?: ModelLinkedUserConditionInput | null,
};

export type CreateLinkedUserMutation = {
  createLinkedUser?:  {
    __typename: "LinkedUser",
    id: string,
    followeeId: string,
    followee:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    followerId: string,
    follower:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLinkedUserMutationVariables = {
  input: UpdateLinkedUserInput,
  condition?: ModelLinkedUserConditionInput | null,
};

export type UpdateLinkedUserMutation = {
  updateLinkedUser?:  {
    __typename: "LinkedUser",
    id: string,
    followeeId: string,
    followee:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    followerId: string,
    follower:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLinkedUserMutationVariables = {
  input: DeleteLinkedUserInput,
  condition?: ModelLinkedUserConditionInput | null,
};

export type DeleteLinkedUserMutation = {
  deleteLinkedUser?:  {
    __typename: "LinkedUser",
    id: string,
    followeeId: string,
    followee:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    followerId: string,
    follower:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    cognitoId: string,
    cognitoSub: string,
    email: string,
    firstName: string,
    middleName?: string | null,
    lastName: string,
    phone?: string | null,
    bio?: string | null,
    avatar?: string | null,
    followees?:  {
      __typename: "ModelLinkedUserConnection",
      nextToken?: string | null,
    } | null,
    followers?:  {
      __typename: "ModelLinkedUserConnection",
      nextToken?: string | null,
    } | null,
    numFollowees: number,
    numFollowers: number,
    posts?:  {
      __typename: "ModelPostConnection",
      nextToken?: string | null,
    } | null,
    feeds?:  {
      __typename: "ModelFeedConnection",
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    drops?:  {
      __typename: "ModelDropConnection",
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      nextToken?: string | null,
    } | null,
    numPosts: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    cognitoId: string,
    cognitoSub: string,
    email: string,
    firstName: string,
    middleName?: string | null,
    lastName: string,
    phone?: string | null,
    bio?: string | null,
    avatar?: string | null,
    followees?:  {
      __typename: "ModelLinkedUserConnection",
      nextToken?: string | null,
    } | null,
    followers?:  {
      __typename: "ModelLinkedUserConnection",
      nextToken?: string | null,
    } | null,
    numFollowees: number,
    numFollowers: number,
    posts?:  {
      __typename: "ModelPostConnection",
      nextToken?: string | null,
    } | null,
    feeds?:  {
      __typename: "ModelFeedConnection",
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    drops?:  {
      __typename: "ModelDropConnection",
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      nextToken?: string | null,
    } | null,
    numPosts: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    cognitoId: string,
    cognitoSub: string,
    email: string,
    firstName: string,
    middleName?: string | null,
    lastName: string,
    phone?: string | null,
    bio?: string | null,
    avatar?: string | null,
    followees?:  {
      __typename: "ModelLinkedUserConnection",
      nextToken?: string | null,
    } | null,
    followers?:  {
      __typename: "ModelLinkedUserConnection",
      nextToken?: string | null,
    } | null,
    numFollowees: number,
    numFollowers: number,
    posts?:  {
      __typename: "ModelPostConnection",
      nextToken?: string | null,
    } | null,
    feeds?:  {
      __typename: "ModelFeedConnection",
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    drops?:  {
      __typename: "ModelDropConnection",
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      nextToken?: string | null,
    } | null,
    numPosts: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDesignerMutationVariables = {
  input: CreateDesignerInput,
  condition?: ModelDesignerConditionInput | null,
};

export type CreateDesignerMutation = {
  createDesigner?:  {
    __typename: "Designer",
    id: string,
    name: string,
    logo?: string | null,
    description?: string | null,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDesignerMutationVariables = {
  input: UpdateDesignerInput,
  condition?: ModelDesignerConditionInput | null,
};

export type UpdateDesignerMutation = {
  updateDesigner?:  {
    __typename: "Designer",
    id: string,
    name: string,
    logo?: string | null,
    description?: string | null,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDesignerMutationVariables = {
  input: DeleteDesignerInput,
  condition?: ModelDesignerConditionInput | null,
};

export type DeleteDesignerMutation = {
  deleteDesigner?:  {
    __typename: "Designer",
    id: string,
    name: string,
    logo?: string | null,
    description?: string | null,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    isRead: boolean,
    type: NotificationType,
    senderId?: string | null,
    sender?:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    likeId?: string | null,
    like?:  {
      __typename: "Like",
      id: string,
      type: LikeableType,
      userId: string,
      postId?: string | null,
      commentId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    commentId?: string | null,
    comment?:  {
      __typename: "Comment",
      id: string,
      type: CommentableType,
      userId: string,
      postId?: string | null,
      numLikes: number,
      commentId?: string | null,
      numReplies: number,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    isRead: boolean,
    type: NotificationType,
    senderId?: string | null,
    sender?:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    likeId?: string | null,
    like?:  {
      __typename: "Like",
      id: string,
      type: LikeableType,
      userId: string,
      postId?: string | null,
      commentId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    commentId?: string | null,
    comment?:  {
      __typename: "Comment",
      id: string,
      type: CommentableType,
      userId: string,
      postId?: string | null,
      numLikes: number,
      commentId?: string | null,
      numReplies: number,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    isRead: boolean,
    type: NotificationType,
    senderId?: string | null,
    sender?:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    likeId?: string | null,
    like?:  {
      __typename: "Like",
      id: string,
      type: LikeableType,
      userId: string,
      postId?: string | null,
      commentId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    commentId?: string | null,
    comment?:  {
      __typename: "Comment",
      id: string,
      type: CommentableType,
      userId: string,
      postId?: string | null,
      numLikes: number,
      commentId?: string | null,
      numReplies: number,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type GetNewsletterQueryVariables = {
  id: string,
};

export type GetNewsletterQuery = {
  getNewsletter?:  {
    __typename: "Newsletter",
    id: string,
    email: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNewslettersQueryVariables = {
  filter?: ModelNewsletterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNewslettersQuery = {
  listNewsletters?:  {
    __typename: "ModelNewsletterConnection",
    items:  Array< {
      __typename: "Newsletter",
      id: string,
      email: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLikeQueryVariables = {
  id: string,
};

export type GetLikeQuery = {
  getLike?:  {
    __typename: "Like",
    id: string,
    type: LikeableType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null,
    commentId?: string | null,
    comment?:  {
      __typename: "Comment",
      id: string,
      type: CommentableType,
      userId: string,
      postId?: string | null,
      numLikes: number,
      commentId?: string | null,
      numReplies: number,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLikesQueryVariables = {
  filter?: ModelLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLikesQuery = {
  listLikes?:  {
    __typename: "ModelLikeConnection",
    items:  Array< {
      __typename: "Like",
      id: string,
      type: LikeableType,
      userId: string,
      postId?: string | null,
      commentId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LikesByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LikesByUserIdQuery = {
  likesByUserId?:  {
    __typename: "ModelLikeConnection",
    items:  Array< {
      __typename: "Like",
      id: string,
      type: LikeableType,
      userId: string,
      postId?: string | null,
      commentId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LikesByPostIdAndUserIdQueryVariables = {
  postId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LikesByPostIdAndUserIdQuery = {
  likesByPostIdAndUserId?:  {
    __typename: "ModelLikeConnection",
    items:  Array< {
      __typename: "Like",
      id: string,
      type: LikeableType,
      userId: string,
      postId?: string | null,
      commentId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LikesByCommentIdAndUserIdQueryVariables = {
  commentId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LikesByCommentIdAndUserIdQuery = {
  likesByCommentIdAndUserId?:  {
    __typename: "ModelLikeConnection",
    items:  Array< {
      __typename: "Like",
      id: string,
      type: LikeableType,
      userId: string,
      postId?: string | null,
      commentId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommentQueryVariables = {
  id: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    id: string,
    type: CommentableType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    numLikes: number,
    commentId?: string | null,
    replies?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    numReplies: number,
    text: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCommentsQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsQuery = {
  listComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      type: CommentableType,
      userId: string,
      postId?: string | null,
      numLikes: number,
      commentId?: string | null,
      numReplies: number,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommentsByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByUserIdQuery = {
  commentsByUserId?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      type: CommentableType,
      userId: string,
      postId?: string | null,
      numLikes: number,
      commentId?: string | null,
      numReplies: number,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommentsByPostIdAndCreatedAtQueryVariables = {
  postId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByPostIdAndCreatedAtQuery = {
  commentsByPostIdAndCreatedAt?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      type: CommentableType,
      userId: string,
      postId?: string | null,
      numLikes: number,
      commentId?: string | null,
      numReplies: number,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommentsByCommentIdAndCreatedAtQueryVariables = {
  commentId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByCommentIdAndCreatedAtQuery = {
  commentsByCommentIdAndCreatedAt?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      type: CommentableType,
      userId: string,
      postId?: string | null,
      numLikes: number,
      commentId?: string | null,
      numReplies: number,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLinkedPostQueryVariables = {
  id: string,
};

export type GetLinkedPostQuery = {
  getLinkedPost?:  {
    __typename: "LinkedPost",
    id: string,
    userId: string,
    parentId: string,
    parentPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    taggedId: string,
    taggedPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    pos:  {
      __typename: "TagPos",
      x: number,
      y: number,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLinkedPostsQueryVariables = {
  filter?: ModelLinkedPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLinkedPostsQuery = {
  listLinkedPosts?:  {
    __typename: "ModelLinkedPostConnection",
    items:  Array< {
      __typename: "LinkedPost",
      id: string,
      userId: string,
      parentId: string,
      taggedId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LinkedPostsByParentIdQueryVariables = {
  parentId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLinkedPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LinkedPostsByParentIdQuery = {
  linkedPostsByParentId?:  {
    __typename: "ModelLinkedPostConnection",
    items:  Array< {
      __typename: "LinkedPost",
      id: string,
      userId: string,
      parentId: string,
      taggedId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LinkedPostsByTaggedIdQueryVariables = {
  taggedId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLinkedPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LinkedPostsByTaggedIdQuery = {
  linkedPostsByTaggedId?:  {
    __typename: "ModelLinkedPostConnection",
    items:  Array< {
      __typename: "LinkedPost",
      id: string,
      userId: string,
      parentId: string,
      taggedId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLinkedPostDropQueryVariables = {
  id: string,
};

export type GetLinkedPostDropQuery = {
  getLinkedPostDrop?:  {
    __typename: "LinkedPostDrop",
    id: string,
    userId: string,
    dropId: string,
    dropItem:  {
      __typename: "Drop",
      id: string,
      userId: string,
      name: string,
      description?: string | null,
      launchTime: string,
      createdAt: string,
      updatedAt: string,
    },
    itemId: string,
    itemPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLinkedPostDropsQueryVariables = {
  filter?: ModelLinkedPostDropFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLinkedPostDropsQuery = {
  listLinkedPostDrops?:  {
    __typename: "ModelLinkedPostDropConnection",
    items:  Array< {
      __typename: "LinkedPostDrop",
      id: string,
      userId: string,
      dropId: string,
      itemId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LinkedPostDropsByDropIdQueryVariables = {
  dropId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLinkedPostDropFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LinkedPostDropsByDropIdQuery = {
  linkedPostDropsByDropId?:  {
    __typename: "ModelLinkedPostDropConnection",
    items:  Array< {
      __typename: "LinkedPostDrop",
      id: string,
      userId: string,
      dropId: string,
      itemId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LinkedPostDropsByItemIdQueryVariables = {
  itemId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLinkedPostDropFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LinkedPostDropsByItemIdQuery = {
  linkedPostDropsByItemId?:  {
    __typename: "ModelLinkedPostDropConnection",
    items:  Array< {
      __typename: "LinkedPostDrop",
      id: string,
      userId: string,
      dropId: string,
      itemId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDropQueryVariables = {
  id: string,
};

export type GetDropQuery = {
  getDrop?:  {
    __typename: "Drop",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    posts?:  {
      __typename: "ModelLinkedPostDropConnection",
      nextToken?: string | null,
    } | null,
    description?: string | null,
    launchTime: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDropsQueryVariables = {
  filter?: ModelDropFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDropsQuery = {
  listDrops?:  {
    __typename: "ModelDropConnection",
    items:  Array< {
      __typename: "Drop",
      id: string,
      userId: string,
      name: string,
      description?: string | null,
      launchTime: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DropsByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDropFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DropsByUserIdQuery = {
  dropsByUserId?:  {
    __typename: "ModelDropConnection",
    items:  Array< {
      __typename: "Drop",
      id: string,
      userId: string,
      name: string,
      description?: string | null,
      launchTime: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchDropsQueryVariables = {
  filter?: SearchableDropFilterInput | null,
  sort?: Array< SearchableDropSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableDropAggregationInput | null > | null,
};

export type SearchDropsQuery = {
  searchDrops?:  {
    __typename: "SearchableDropConnection",
    items:  Array< {
      __typename: "Drop",
      id: string,
      userId: string,
      name: string,
      description?: string | null,
      launchTime: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetPostQueryVariables = {
  id: string,
};

export type GetPostQuery = {
  getPost?:  {
    __typename: "Post",
    id: string,
    type: PostType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    numLikes: number,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    numComments: number,
    images: Array< string >,
    caption?: string | null,
    taggedPosts?:  {
      __typename: "ModelLinkedPostConnection",
      nextToken?: string | null,
    } | null,
    parentPosts?:  {
      __typename: "ModelLinkedPostConnection",
      nextToken?: string | null,
    } | null,
    category?: string | null,
    designer?: string | null,
    drop?:  {
      __typename: "ModelLinkedPostDropConnection",
      nextToken?: string | null,
    } | null,
    dropId: string,
    name?: string | null,
    color?: string | null,
    material?: string | null,
    size?: string | null,
    purchasePrice?: number | null,
    sellingPrice: number,
    isSold?: boolean | null,
    video?: string | null,
    isCompleted?: boolean | null,
    height?: number | null,
    width?: number | null,
    depth?: number | null,
    chainDrop?: number | null,
    interiorCondition?: string | null,
    exteriorCondition?: string | null,
    isVintage?: boolean | null,
    callouts?: string | null,
    oldPrice?: number | null,
    productionYear?: number | null,
    hardware?: string | null,
    dustbag?: boolean | null,
    box?: boolean | null,
    serialNumber?: boolean | null,
    authCard?: boolean | null,
    authenticityGuarantee?: string | null,
    updatedAt: string,
  } | null,
};

export type ListPostsQueryVariables = {
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPostsQuery = {
  listPosts?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PostsByTypeAndCreatedAtQueryVariables = {
  type: PostType,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PostsByTypeAndCreatedAtQuery = {
  postsByTypeAndCreatedAt?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PostsByUserIdAndCreatedAtQueryVariables = {
  userId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PostsByUserIdAndCreatedAtQuery = {
  postsByUserIdAndCreatedAt?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PostsByUserIdAndTypeAndCreatedAtQueryVariables = {
  userId: string,
  typeCreatedAt?: ModelPostByUserAndTypeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PostsByUserIdAndTypeAndCreatedAtQuery = {
  postsByUserIdAndTypeAndCreatedAt?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PostsByCategoryAndCreatedAtQueryVariables = {
  category: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PostsByCategoryAndCreatedAtQuery = {
  postsByCategoryAndCreatedAt?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PostsByDesignerAndCreatedAtQueryVariables = {
  designer: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PostsByDesignerAndCreatedAtQuery = {
  postsByDesignerAndCreatedAt?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPostsQueryVariables = {
  filter?: SearchablePostFilterInput | null,
  sort?: Array< SearchablePostSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablePostAggregationInput | null > | null,
};

export type SearchPostsQuery = {
  searchPosts?:  {
    __typename: "SearchablePostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetFeedQueryVariables = {
  id: string,
  postId: string,
};

export type GetFeedQuery = {
  getFeed?:  {
    __typename: "Feed",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    followeeId: string,
    followee:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId: string,
    post:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    postCreatedAt: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFeedsQueryVariables = {
  id?: string | null,
  postId?: ModelIDKeyConditionInput | null,
  filter?: ModelFeedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFeedsQuery = {
  listFeeds?:  {
    __typename: "ModelFeedConnection",
    items:  Array< {
      __typename: "Feed",
      id: string,
      userId: string,
      followeeId: string,
      postId: string,
      postCreatedAt: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FeedsByUserIdAndPostCreatedAtQueryVariables = {
  userId: string,
  postCreatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedsByUserIdAndPostCreatedAtQuery = {
  feedsByUserIdAndPostCreatedAt?:  {
    __typename: "ModelFeedConnection",
    items:  Array< {
      __typename: "Feed",
      id: string,
      userId: string,
      followeeId: string,
      postId: string,
      postCreatedAt: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FeedsByFolloweeIdAndPostIdQueryVariables = {
  followeeId: string,
  postId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedsByFolloweeIdAndPostIdQuery = {
  feedsByFolloweeIdAndPostId?:  {
    __typename: "ModelFeedConnection",
    items:  Array< {
      __typename: "Feed",
      id: string,
      userId: string,
      followeeId: string,
      postId: string,
      postCreatedAt: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FeedsByPostIdQueryVariables = {
  postId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedsByPostIdQuery = {
  feedsByPostId?:  {
    __typename: "ModelFeedConnection",
    items:  Array< {
      __typename: "Feed",
      id: string,
      userId: string,
      followeeId: string,
      postId: string,
      postCreatedAt: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLinkedUserQueryVariables = {
  id: string,
};

export type GetLinkedUserQuery = {
  getLinkedUser?:  {
    __typename: "LinkedUser",
    id: string,
    followeeId: string,
    followee:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    followerId: string,
    follower:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLinkedUsersQueryVariables = {
  filter?: ModelLinkedUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLinkedUsersQuery = {
  listLinkedUsers?:  {
    __typename: "ModelLinkedUserConnection",
    items:  Array< {
      __typename: "LinkedUser",
      id: string,
      followeeId: string,
      followerId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LinkedUsersByFolloweeIdQueryVariables = {
  followeeId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLinkedUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LinkedUsersByFolloweeIdQuery = {
  linkedUsersByFolloweeId?:  {
    __typename: "ModelLinkedUserConnection",
    items:  Array< {
      __typename: "LinkedUser",
      id: string,
      followeeId: string,
      followerId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LinkedUsersByFollowerIdQueryVariables = {
  followerId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLinkedUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LinkedUsersByFollowerIdQuery = {
  linkedUsersByFollowerId?:  {
    __typename: "ModelLinkedUserConnection",
    items:  Array< {
      __typename: "LinkedUser",
      id: string,
      followeeId: string,
      followerId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    cognitoId: string,
    cognitoSub: string,
    email: string,
    firstName: string,
    middleName?: string | null,
    lastName: string,
    phone?: string | null,
    bio?: string | null,
    avatar?: string | null,
    followees?:  {
      __typename: "ModelLinkedUserConnection",
      nextToken?: string | null,
    } | null,
    followers?:  {
      __typename: "ModelLinkedUserConnection",
      nextToken?: string | null,
    } | null,
    numFollowees: number,
    numFollowers: number,
    posts?:  {
      __typename: "ModelPostConnection",
      nextToken?: string | null,
    } | null,
    feeds?:  {
      __typename: "ModelFeedConnection",
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    drops?:  {
      __typename: "ModelDropConnection",
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      nextToken?: string | null,
    } | null,
    numPosts: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchUsersQueryVariables = {
  filter?: SearchableUserFilterInput | null,
  sort?: Array< SearchableUserSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableUserAggregationInput | null > | null,
};

export type SearchUsersQuery = {
  searchUsers?:  {
    __typename: "SearchableUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetDesignerQueryVariables = {
  id: string,
};

export type GetDesignerQuery = {
  getDesigner?:  {
    __typename: "Designer",
    id: string,
    name: string,
    logo?: string | null,
    description?: string | null,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDesignersQueryVariables = {
  filter?: ModelDesignerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDesignersQuery = {
  listDesigners?:  {
    __typename: "ModelDesignerConnection",
    items:  Array< {
      __typename: "Designer",
      id: string,
      name: string,
      logo?: string | null,
      description?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchDesignersQueryVariables = {
  filter?: SearchableDesignerFilterInput | null,
  sort?: Array< SearchableDesignerSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableDesignerAggregationInput | null > | null,
};

export type SearchDesignersQuery = {
  searchDesigners?:  {
    __typename: "SearchableDesignerConnection",
    items:  Array< {
      __typename: "Designer",
      id: string,
      name: string,
      logo?: string | null,
      description?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    isRead: boolean,
    type: NotificationType,
    senderId?: string | null,
    sender?:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    likeId?: string | null,
    like?:  {
      __typename: "Like",
      id: string,
      type: LikeableType,
      userId: string,
      postId?: string | null,
      commentId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    commentId?: string | null,
    comment?:  {
      __typename: "Comment",
      id: string,
      type: CommentableType,
      userId: string,
      postId?: string | null,
      numLikes: number,
      commentId?: string | null,
      numReplies: number,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      userId: string,
      createdAt: string,
      isRead: boolean,
      type: NotificationType,
      senderId?: string | null,
      likeId?: string | null,
      commentId?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsByUserIdAndCreatedAtQueryVariables = {
  userId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByUserIdAndCreatedAtQuery = {
  notificationsByUserIdAndCreatedAt?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      userId: string,
      createdAt: string,
      isRead: boolean,
      type: NotificationType,
      senderId?: string | null,
      likeId?: string | null,
      commentId?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsBySenderIdQueryVariables = {
  senderId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsBySenderIdQuery = {
  notificationsBySenderId?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      userId: string,
      createdAt: string,
      isRead: boolean,
      type: NotificationType,
      senderId?: string | null,
      likeId?: string | null,
      commentId?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsByLikeIdQueryVariables = {
  likeId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByLikeIdQuery = {
  notificationsByLikeId?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      userId: string,
      createdAt: string,
      isRead: boolean,
      type: NotificationType,
      senderId?: string | null,
      likeId?: string | null,
      commentId?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsByCommentIdQueryVariables = {
  commentId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByCommentIdQuery = {
  notificationsByCommentId?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      userId: string,
      createdAt: string,
      isRead: boolean,
      type: NotificationType,
      senderId?: string | null,
      likeId?: string | null,
      commentId?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateNewsletterSubscriptionVariables = {
  filter?: ModelSubscriptionNewsletterFilterInput | null,
};

export type OnCreateNewsletterSubscription = {
  onCreateNewsletter?:  {
    __typename: "Newsletter",
    id: string,
    email: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNewsletterSubscriptionVariables = {
  filter?: ModelSubscriptionNewsletterFilterInput | null,
};

export type OnUpdateNewsletterSubscription = {
  onUpdateNewsletter?:  {
    __typename: "Newsletter",
    id: string,
    email: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNewsletterSubscriptionVariables = {
  filter?: ModelSubscriptionNewsletterFilterInput | null,
};

export type OnDeleteNewsletterSubscription = {
  onDeleteNewsletter?:  {
    __typename: "Newsletter",
    id: string,
    email: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLikeSubscriptionVariables = {
  filter?: ModelSubscriptionLikeFilterInput | null,
};

export type OnCreateLikeSubscription = {
  onCreateLike?:  {
    __typename: "Like",
    id: string,
    type: LikeableType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null,
    commentId?: string | null,
    comment?:  {
      __typename: "Comment",
      id: string,
      type: CommentableType,
      userId: string,
      postId?: string | null,
      numLikes: number,
      commentId?: string | null,
      numReplies: number,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLikeSubscriptionVariables = {
  filter?: ModelSubscriptionLikeFilterInput | null,
};

export type OnUpdateLikeSubscription = {
  onUpdateLike?:  {
    __typename: "Like",
    id: string,
    type: LikeableType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null,
    commentId?: string | null,
    comment?:  {
      __typename: "Comment",
      id: string,
      type: CommentableType,
      userId: string,
      postId?: string | null,
      numLikes: number,
      commentId?: string | null,
      numReplies: number,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLikeSubscriptionVariables = {
  filter?: ModelSubscriptionLikeFilterInput | null,
};

export type OnDeleteLikeSubscription = {
  onDeleteLike?:  {
    __typename: "Like",
    id: string,
    type: LikeableType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null,
    commentId?: string | null,
    comment?:  {
      __typename: "Comment",
      id: string,
      type: CommentableType,
      userId: string,
      postId?: string | null,
      numLikes: number,
      commentId?: string | null,
      numReplies: number,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
};

export type OnCreateCommentSubscription = {
  onCreateComment?:  {
    __typename: "Comment",
    id: string,
    type: CommentableType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    numLikes: number,
    commentId?: string | null,
    replies?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    numReplies: number,
    text: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
};

export type OnUpdateCommentSubscription = {
  onUpdateComment?:  {
    __typename: "Comment",
    id: string,
    type: CommentableType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    numLikes: number,
    commentId?: string | null,
    replies?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    numReplies: number,
    text: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
};

export type OnDeleteCommentSubscription = {
  onDeleteComment?:  {
    __typename: "Comment",
    id: string,
    type: CommentableType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    numLikes: number,
    commentId?: string | null,
    replies?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    numReplies: number,
    text: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLinkedPostSubscriptionVariables = {
  filter?: ModelSubscriptionLinkedPostFilterInput | null,
};

export type OnCreateLinkedPostSubscription = {
  onCreateLinkedPost?:  {
    __typename: "LinkedPost",
    id: string,
    userId: string,
    parentId: string,
    parentPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    taggedId: string,
    taggedPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    pos:  {
      __typename: "TagPos",
      x: number,
      y: number,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLinkedPostSubscriptionVariables = {
  filter?: ModelSubscriptionLinkedPostFilterInput | null,
};

export type OnUpdateLinkedPostSubscription = {
  onUpdateLinkedPost?:  {
    __typename: "LinkedPost",
    id: string,
    userId: string,
    parentId: string,
    parentPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    taggedId: string,
    taggedPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    pos:  {
      __typename: "TagPos",
      x: number,
      y: number,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLinkedPostSubscriptionVariables = {
  filter?: ModelSubscriptionLinkedPostFilterInput | null,
};

export type OnDeleteLinkedPostSubscription = {
  onDeleteLinkedPost?:  {
    __typename: "LinkedPost",
    id: string,
    userId: string,
    parentId: string,
    parentPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    taggedId: string,
    taggedPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    pos:  {
      __typename: "TagPos",
      x: number,
      y: number,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLinkedPostDropSubscriptionVariables = {
  filter?: ModelSubscriptionLinkedPostDropFilterInput | null,
};

export type OnCreateLinkedPostDropSubscription = {
  onCreateLinkedPostDrop?:  {
    __typename: "LinkedPostDrop",
    id: string,
    userId: string,
    dropId: string,
    dropItem:  {
      __typename: "Drop",
      id: string,
      userId: string,
      name: string,
      description?: string | null,
      launchTime: string,
      createdAt: string,
      updatedAt: string,
    },
    itemId: string,
    itemPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLinkedPostDropSubscriptionVariables = {
  filter?: ModelSubscriptionLinkedPostDropFilterInput | null,
};

export type OnUpdateLinkedPostDropSubscription = {
  onUpdateLinkedPostDrop?:  {
    __typename: "LinkedPostDrop",
    id: string,
    userId: string,
    dropId: string,
    dropItem:  {
      __typename: "Drop",
      id: string,
      userId: string,
      name: string,
      description?: string | null,
      launchTime: string,
      createdAt: string,
      updatedAt: string,
    },
    itemId: string,
    itemPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLinkedPostDropSubscriptionVariables = {
  filter?: ModelSubscriptionLinkedPostDropFilterInput | null,
};

export type OnDeleteLinkedPostDropSubscription = {
  onDeleteLinkedPostDrop?:  {
    __typename: "LinkedPostDrop",
    id: string,
    userId: string,
    dropId: string,
    dropItem:  {
      __typename: "Drop",
      id: string,
      userId: string,
      name: string,
      description?: string | null,
      launchTime: string,
      createdAt: string,
      updatedAt: string,
    },
    itemId: string,
    itemPost:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDropSubscriptionVariables = {
  filter?: ModelSubscriptionDropFilterInput | null,
};

export type OnCreateDropSubscription = {
  onCreateDrop?:  {
    __typename: "Drop",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    posts?:  {
      __typename: "ModelLinkedPostDropConnection",
      nextToken?: string | null,
    } | null,
    description?: string | null,
    launchTime: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDropSubscriptionVariables = {
  filter?: ModelSubscriptionDropFilterInput | null,
};

export type OnUpdateDropSubscription = {
  onUpdateDrop?:  {
    __typename: "Drop",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    posts?:  {
      __typename: "ModelLinkedPostDropConnection",
      nextToken?: string | null,
    } | null,
    description?: string | null,
    launchTime: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDropSubscriptionVariables = {
  filter?: ModelSubscriptionDropFilterInput | null,
};

export type OnDeleteDropSubscription = {
  onDeleteDrop?:  {
    __typename: "Drop",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    posts?:  {
      __typename: "ModelLinkedPostDropConnection",
      nextToken?: string | null,
    } | null,
    description?: string | null,
    launchTime: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePostSubscriptionVariables = {
  filter?: ModelSubscriptionPostFilterInput | null,
};

export type OnCreatePostSubscription = {
  onCreatePost?:  {
    __typename: "Post",
    id: string,
    type: PostType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    numLikes: number,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    numComments: number,
    images: Array< string >,
    caption?: string | null,
    taggedPosts?:  {
      __typename: "ModelLinkedPostConnection",
      nextToken?: string | null,
    } | null,
    parentPosts?:  {
      __typename: "ModelLinkedPostConnection",
      nextToken?: string | null,
    } | null,
    category?: string | null,
    designer?: string | null,
    drop?:  {
      __typename: "ModelLinkedPostDropConnection",
      nextToken?: string | null,
    } | null,
    dropId: string,
    name?: string | null,
    color?: string | null,
    material?: string | null,
    size?: string | null,
    purchasePrice?: number | null,
    sellingPrice: number,
    isSold?: boolean | null,
    video?: string | null,
    isCompleted?: boolean | null,
    height?: number | null,
    width?: number | null,
    depth?: number | null,
    chainDrop?: number | null,
    interiorCondition?: string | null,
    exteriorCondition?: string | null,
    isVintage?: boolean | null,
    callouts?: string | null,
    oldPrice?: number | null,
    productionYear?: number | null,
    hardware?: string | null,
    dustbag?: boolean | null,
    box?: boolean | null,
    serialNumber?: boolean | null,
    authCard?: boolean | null,
    authenticityGuarantee?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdatePostSubscriptionVariables = {
  filter?: ModelSubscriptionPostFilterInput | null,
};

export type OnUpdatePostSubscription = {
  onUpdatePost?:  {
    __typename: "Post",
    id: string,
    type: PostType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    numLikes: number,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    numComments: number,
    images: Array< string >,
    caption?: string | null,
    taggedPosts?:  {
      __typename: "ModelLinkedPostConnection",
      nextToken?: string | null,
    } | null,
    parentPosts?:  {
      __typename: "ModelLinkedPostConnection",
      nextToken?: string | null,
    } | null,
    category?: string | null,
    designer?: string | null,
    drop?:  {
      __typename: "ModelLinkedPostDropConnection",
      nextToken?: string | null,
    } | null,
    dropId: string,
    name?: string | null,
    color?: string | null,
    material?: string | null,
    size?: string | null,
    purchasePrice?: number | null,
    sellingPrice: number,
    isSold?: boolean | null,
    video?: string | null,
    isCompleted?: boolean | null,
    height?: number | null,
    width?: number | null,
    depth?: number | null,
    chainDrop?: number | null,
    interiorCondition?: string | null,
    exteriorCondition?: string | null,
    isVintage?: boolean | null,
    callouts?: string | null,
    oldPrice?: number | null,
    productionYear?: number | null,
    hardware?: string | null,
    dustbag?: boolean | null,
    box?: boolean | null,
    serialNumber?: boolean | null,
    authCard?: boolean | null,
    authenticityGuarantee?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeletePostSubscriptionVariables = {
  filter?: ModelSubscriptionPostFilterInput | null,
};

export type OnDeletePostSubscription = {
  onDeletePost?:  {
    __typename: "Post",
    id: string,
    type: PostType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    numLikes: number,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    numComments: number,
    images: Array< string >,
    caption?: string | null,
    taggedPosts?:  {
      __typename: "ModelLinkedPostConnection",
      nextToken?: string | null,
    } | null,
    parentPosts?:  {
      __typename: "ModelLinkedPostConnection",
      nextToken?: string | null,
    } | null,
    category?: string | null,
    designer?: string | null,
    drop?:  {
      __typename: "ModelLinkedPostDropConnection",
      nextToken?: string | null,
    } | null,
    dropId: string,
    name?: string | null,
    color?: string | null,
    material?: string | null,
    size?: string | null,
    purchasePrice?: number | null,
    sellingPrice: number,
    isSold?: boolean | null,
    video?: string | null,
    isCompleted?: boolean | null,
    height?: number | null,
    width?: number | null,
    depth?: number | null,
    chainDrop?: number | null,
    interiorCondition?: string | null,
    exteriorCondition?: string | null,
    isVintage?: boolean | null,
    callouts?: string | null,
    oldPrice?: number | null,
    productionYear?: number | null,
    hardware?: string | null,
    dustbag?: boolean | null,
    box?: boolean | null,
    serialNumber?: boolean | null,
    authCard?: boolean | null,
    authenticityGuarantee?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateFeedSubscriptionVariables = {
  filter?: ModelSubscriptionFeedFilterInput | null,
  userId?: string | null,
};

export type OnCreateFeedSubscription = {
  onCreateFeed?:  {
    __typename: "Feed",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    followeeId: string,
    followee:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId: string,
    post:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    postCreatedAt: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFeedSubscriptionVariables = {
  filter?: ModelSubscriptionFeedFilterInput | null,
  userId?: string | null,
};

export type OnUpdateFeedSubscription = {
  onUpdateFeed?:  {
    __typename: "Feed",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    followeeId: string,
    followee:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId: string,
    post:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    postCreatedAt: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFeedSubscriptionVariables = {
  filter?: ModelSubscriptionFeedFilterInput | null,
  userId?: string | null,
};

export type OnDeleteFeedSubscription = {
  onDeleteFeed?:  {
    __typename: "Feed",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    followeeId: string,
    followee:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    postId: string,
    post:  {
      __typename: "Post",
      id: string,
      type: PostType,
      userId: string,
      createdAt: string,
      numLikes: number,
      numComments: number,
      images: Array< string >,
      caption?: string | null,
      category?: string | null,
      designer?: string | null,
      dropId: string,
      name?: string | null,
      color?: string | null,
      material?: string | null,
      size?: string | null,
      purchasePrice?: number | null,
      sellingPrice: number,
      isSold?: boolean | null,
      video?: string | null,
      isCompleted?: boolean | null,
      height?: number | null,
      width?: number | null,
      depth?: number | null,
      chainDrop?: number | null,
      interiorCondition?: string | null,
      exteriorCondition?: string | null,
      isVintage?: boolean | null,
      callouts?: string | null,
      oldPrice?: number | null,
      productionYear?: number | null,
      hardware?: string | null,
      dustbag?: boolean | null,
      box?: boolean | null,
      serialNumber?: boolean | null,
      authCard?: boolean | null,
      authenticityGuarantee?: string | null,
      updatedAt: string,
    },
    postCreatedAt: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLinkedUserSubscriptionVariables = {
  filter?: ModelSubscriptionLinkedUserFilterInput | null,
};

export type OnCreateLinkedUserSubscription = {
  onCreateLinkedUser?:  {
    __typename: "LinkedUser",
    id: string,
    followeeId: string,
    followee:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    followerId: string,
    follower:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLinkedUserSubscriptionVariables = {
  filter?: ModelSubscriptionLinkedUserFilterInput | null,
};

export type OnUpdateLinkedUserSubscription = {
  onUpdateLinkedUser?:  {
    __typename: "LinkedUser",
    id: string,
    followeeId: string,
    followee:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    followerId: string,
    follower:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLinkedUserSubscriptionVariables = {
  filter?: ModelSubscriptionLinkedUserFilterInput | null,
};

export type OnDeleteLinkedUserSubscription = {
  onDeleteLinkedUser?:  {
    __typename: "LinkedUser",
    id: string,
    followeeId: string,
    followee:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    followerId: string,
    follower:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    cognitoId: string,
    cognitoSub: string,
    email: string,
    firstName: string,
    middleName?: string | null,
    lastName: string,
    phone?: string | null,
    bio?: string | null,
    avatar?: string | null,
    followees?:  {
      __typename: "ModelLinkedUserConnection",
      nextToken?: string | null,
    } | null,
    followers?:  {
      __typename: "ModelLinkedUserConnection",
      nextToken?: string | null,
    } | null,
    numFollowees: number,
    numFollowers: number,
    posts?:  {
      __typename: "ModelPostConnection",
      nextToken?: string | null,
    } | null,
    feeds?:  {
      __typename: "ModelFeedConnection",
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    drops?:  {
      __typename: "ModelDropConnection",
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      nextToken?: string | null,
    } | null,
    numPosts: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    cognitoId: string,
    cognitoSub: string,
    email: string,
    firstName: string,
    middleName?: string | null,
    lastName: string,
    phone?: string | null,
    bio?: string | null,
    avatar?: string | null,
    followees?:  {
      __typename: "ModelLinkedUserConnection",
      nextToken?: string | null,
    } | null,
    followers?:  {
      __typename: "ModelLinkedUserConnection",
      nextToken?: string | null,
    } | null,
    numFollowees: number,
    numFollowers: number,
    posts?:  {
      __typename: "ModelPostConnection",
      nextToken?: string | null,
    } | null,
    feeds?:  {
      __typename: "ModelFeedConnection",
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    drops?:  {
      __typename: "ModelDropConnection",
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      nextToken?: string | null,
    } | null,
    numPosts: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    cognitoId: string,
    cognitoSub: string,
    email: string,
    firstName: string,
    middleName?: string | null,
    lastName: string,
    phone?: string | null,
    bio?: string | null,
    avatar?: string | null,
    followees?:  {
      __typename: "ModelLinkedUserConnection",
      nextToken?: string | null,
    } | null,
    followers?:  {
      __typename: "ModelLinkedUserConnection",
      nextToken?: string | null,
    } | null,
    numFollowees: number,
    numFollowers: number,
    posts?:  {
      __typename: "ModelPostConnection",
      nextToken?: string | null,
    } | null,
    feeds?:  {
      __typename: "ModelFeedConnection",
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    drops?:  {
      __typename: "ModelDropConnection",
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      nextToken?: string | null,
    } | null,
    numPosts: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDesignerSubscriptionVariables = {
  filter?: ModelSubscriptionDesignerFilterInput | null,
};

export type OnCreateDesignerSubscription = {
  onCreateDesigner?:  {
    __typename: "Designer",
    id: string,
    name: string,
    logo?: string | null,
    description?: string | null,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDesignerSubscriptionVariables = {
  filter?: ModelSubscriptionDesignerFilterInput | null,
};

export type OnUpdateDesignerSubscription = {
  onUpdateDesigner?:  {
    __typename: "Designer",
    id: string,
    name: string,
    logo?: string | null,
    description?: string | null,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDesignerSubscriptionVariables = {
  filter?: ModelSubscriptionDesignerFilterInput | null,
};

export type OnDeleteDesignerSubscription = {
  onDeleteDesigner?:  {
    __typename: "Designer",
    id: string,
    name: string,
    logo?: string | null,
    description?: string | null,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    isRead: boolean,
    type: NotificationType,
    senderId?: string | null,
    sender?:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    likeId?: string | null,
    like?:  {
      __typename: "Like",
      id: string,
      type: LikeableType,
      userId: string,
      postId?: string | null,
      commentId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    commentId?: string | null,
    comment?:  {
      __typename: "Comment",
      id: string,
      type: CommentableType,
      userId: string,
      postId?: string | null,
      numLikes: number,
      commentId?: string | null,
      numReplies: number,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    isRead: boolean,
    type: NotificationType,
    senderId?: string | null,
    sender?:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    likeId?: string | null,
    like?:  {
      __typename: "Like",
      id: string,
      type: LikeableType,
      userId: string,
      postId?: string | null,
      commentId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    commentId?: string | null,
    comment?:  {
      __typename: "Comment",
      id: string,
      type: CommentableType,
      userId: string,
      postId?: string | null,
      numLikes: number,
      commentId?: string | null,
      numReplies: number,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    isRead: boolean,
    type: NotificationType,
    senderId?: string | null,
    sender?:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      cognitoSub: string,
      email: string,
      firstName: string,
      middleName?: string | null,
      lastName: string,
      phone?: string | null,
      bio?: string | null,
      avatar?: string | null,
      numFollowees: number,
      numFollowers: number,
      numPosts: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    likeId?: string | null,
    like?:  {
      __typename: "Like",
      id: string,
      type: LikeableType,
      userId: string,
      postId?: string | null,
      commentId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    commentId?: string | null,
    comment?:  {
      __typename: "Comment",
      id: string,
      type: CommentableType,
      userId: string,
      postId?: string | null,
      numLikes: number,
      commentId?: string | null,
      numReplies: number,
      text: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};
