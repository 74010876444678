import { Button, Grid, Paper, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import UserAvatar from '../../components/UserAvatar.tsx';
import { useAuth, useProfile, useUser } from '../../hooks/index.ts';
import { colors } from '../../utils/common.ts';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import AlertDialog from '../../components/AlertDialog.tsx';
import { generateClient } from 'aws-amplify/api';
import * as mutations from '../../graphql/mutations.js'
import SettingsIcon from '@mui/icons-material/Settings';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SellIcon from '@mui/icons-material/Sell';

const client = generateClient();

export default function Header() {
  const navigate = useNavigate();
  const { user: currentUser, updateAvatar } = useUser();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { handleSignOut } = useAuth();
  const [signOutModal, setSignOutmodal] = React.useState<boolean>(false);
  const [registerSellerModal, setRegisterSellerModal] = React.useState<boolean>(false);
  const [registeringStripe, setRegisteringStripe] = React.useState<boolean>(false);
  const { userId: profileUserId, user: profileUser } = useProfile();
  const stripe = require('stripe')('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');

  useEffect(() => {
  }, []);

  const isCurrentUser = useMemo(
    () => currentUser?.id === profileUserId,
    [currentUser?.id, profileUserId]
  );

  const handleSignOutClick = useCallback(async () => {
    setSignOutmodal(true);
    // setAnchorEl(null);
    // handleSignOut();
  }, [handleSignOut]);

  const handleCreateListing = useCallback(async () => {
    if (profileUser?.stripeID) {
      const status = await stripe.accounts.retrieve(profileUser?.stripeID);
      if (!status.details_submitted){
        const accountLink = await stripe.accountLinks.create({
          account: profileUser.stripeID,
          refresh_url: `https://www.hautegrid.com/myaccount/${profileUser.id}`,
          return_url: `https://www.hautegrid.com/sell`,
          type: 'account_onboarding',
        });
        window.location.href = accountLink.url;
      } else{
        navigate('/sell')
      }
    } 
    else {
      setRegisterSellerModal(true);
    }
  }, []) //profileUser

  const handleRegisterStripe = useCallback(async () => {
    let stripeID;
    if (!profileUser?.stripeID){
      const account = await stripe?.accounts.create({type: 'express'});
      const input = {
      id: profileUser?.id,
      stripeID: account.id
    };
    const response = (await client.graphql({
      query: mutations.updateUser, variables: { input }, authMode: 'userPool'}
    )) as any;
    stripeID = response.data.updateUser.stripeID;
    } else {
      stripeID = profileUser.stripeID
    }

    const status = await stripe.accounts.retrieve(stripeID);
  }, [profileUser])

  const confirmLogout = useMemo(
    () => (
      <AlertDialog
        open={signOutModal}
        title=''
        contentText='Are you sure you want to logout?'
        dismissButton={{
          label: 'Cancel',
          handleClick: () => setSignOutmodal(false)
        }}
        confirmButton={{
          label:'Logout',
          handleClick: () => {
            setSignOutmodal(false);
            handleSignOut();
            navigate('/login');

          }
        }}
      />
    ),
    [signOutModal, handleSignOut, navigate]
  )

  const registerSellerAlert = useMemo(
    () => (
      <AlertDialog
        open={registerSellerModal}
        title='Register with Stripe'
        contentText='Please first create an account with our payments processing platform, Stripe. We use Stripe to handle payments and to ensure that payouts and user information are handled securely.'
        dismissButton={{
          label: 'Cancel',
          handleClick: () => setRegisterSellerModal(false)
        }}
        confirmButton={{
          label:'Register With Stripe',
          handleClick: async () => {
            if (!registeringStripe) {
              setRegisteringStripe(true);
              await handleRegisterStripe();
              setRegisterSellerModal(false);
              setRegisteringStripe(false);
            }
          }
        }}
      />
    ),
    [setRegisterSellerModal, registerSellerModal, registeringStripe, setRegisteringStripe]
  )

    const updateAvatarButton = useMemo(
    () => (
      <ImgCrop quality={1} aspectSlider cropShape='round' showReset>
        <Upload
          accept='image/*'
          showUploadList={false}
          customRequest={({ file }) => updateAvatar(file as RcFile)}
        >
          <Button
            endIcon={<UploadOutlinedIcon />}
            style={{
              textTransform: 'capitalize',
              marginTop: 1,
              marginBottom: 10,
            }}
          >
            Update
          </Button>
        </Upload>
      </ImgCrop>
    ),
    [updateAvatar]
  );


  if (!profileUser) return null;

  return (
    <Stack style={{minHeight: '100vh', marginTop: 5, width: '92vw', alignSelf: 'center', alignItems: 'center', maxWidth: 800, fontSize: 18 }}>
      <Stack
        style={{
          alignItems: 'center',
          fontSize: 18,
          alignContent: 'center',
          alignSelf: 'center',
          cursor: 'pointer',
          width: '100%',
        }}
        onClick={()=>navigate('/account/settings')}
      >      
        <UserAvatar user={profileUser} size={75} border/>
        <Typography style={{paddingLeft: 5, fontSize: 20, marginTop: 10, marginRight: 6, fontWeight: 'bold'}}>
          {profileUser?.id?.toUpperCase()}
        </Typography>
      </Stack>
      
      <Stack onClick={()=>navigate('/account/settings')} style={{padding: 10, cursor: 'pointer', marginTop: 15, width: '100%', borderBottom: '0.5px solid lightgray'}} direction='row'>
        {/* <SettingsIcon/> */}
        <Typography style={{marginLeft: 10, fontSize: 16}}>
          Settings
        </Typography>
      </Stack>
      {/* <Stack style={{padding: 10, }} direction='row'>
        <Typography style={{fontSize: 16}}>
          Payments
        </Typography>
      </Stack> */}
      <Stack onClick={()=>navigate(`/orders/${profileUser.id}`)} style={{padding: 10, cursor: 'pointer', width: '100%', borderBottom: '0.5px solid lightgray'}} direction='row'>
        {/* <ListAltIcon/> */}
        <Typography style={{marginLeft: 10, fontSize: 16}}>
          My Hautegrid Orders
        </Typography>
      </Stack>
      {profileUser.isSeller ? 
      <Stack style={{padding: 10, cursor: 'pointer', width: '100%', borderBottom: '0.5px solid lightgray'}} direction='row'>
        {/* <SellIcon/> */}
        <Typography style={{marginLeft: 10, fontSize: 16}}>
          My Hautegrid Listings
        </Typography> 
      </Stack> : undefined}

      {profileUser.isSeller ? 
      <Stack style={{padding: 10, cursor: 'pointer', width: '100%', borderBottom: '0.5px solid lightgray'}} direction='row'>
        {/* <AddCircleOutlineIcon/> */}
        <Typography style={{marginLeft: 10, fontSize: 16}}
        onClick={handleCreateListing}>
          Create Listing
        </Typography> 
      </Stack> : undefined}

      <Stack style={{padding: 10, width: '100%', alignContent: 'center', alignItems: 'center', marginTop: 50, marginLeft: 10, textAlign: 'center'}} direction='row'>
        
        <Typography  style={{fontSize: 16, width: '100%', textAlign: 'center'}} onClick={()=>{handleSignOutClick()}}>
          Logout <ArrowForwardIcon style={{marginLeft: 5, marginTop: 15}} />
        </Typography>
        
      </Stack>

      {confirmLogout}
      {registerSellerAlert}
    </Stack>
  );
}
