import { Stack } from '@mui/system';
import React, { useMemo} from 'react';
import ExplorePostTab from './ExplorePostTab.tsx';

enum TabType {
  ITEM,
  OUTFIT,
} 


// Accessory
// Bag
// Watch
// Jewelry

function Explore() {
  const activeTab = TabType.ITEM;

  const itemTab = useMemo(
    () =>
      activeTab === TabType.ITEM && <ExplorePostTab />,
    [activeTab]
  );

  // const outfitTab = useMemo(
  //   () =>
  //     activeTab === TabType.ITEM && (
  //       <ExplorePostTab/>
  //     ),
  //   [activeTab]
  // );

  return (
    <Stack style={{width: '100vw'}}>
      {itemTab}
    </Stack>
  );
}

export default Explore;
