import { Container, useMediaQuery, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';

interface MainContainerProps {
  readonly children: ReactNode;
}

export default function MainContainer({ children }: MainContainerProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container
      style={(() => {
        if (isSmallScreen)
          return {
            paddingBottom: '70px',
            paddingLeft: '0',
            paddingRight: '0',
            maxWidth: '100vw',
            fontFamily: 'Futura',
            alignContent: 'center',
            alignItems: 'center'
          };
        return {
          paddingBottom: '70px',
          width: '100vw',
          fontFamily: 'Futura',
          alignContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
          paddingLeft: 0,
          paddingRight: 0,
          marginLeft: 0,
          marginRight: 0
        };
      })()}
    >
      {children}
    </Container>
  );
}
