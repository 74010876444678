import { Authenticator, ThemeProvider, Theme,
  useTheme, } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Navigate } from 'react-router-dom';
import React from 'react';
import Logo from '../../components/Logo.tsx';
import { Stack } from '@mui/material';
import NoDrops from '../../components/NoDrops.tsx';
import { Typography } from 'antd';

const formFields = {
  signIn: {
    username: {
      order: 1,
      label: 'Username',
      isRequired: true,
      placeholder: 'Enter your username',
    },
    password: {
      order: 2,
      label: 'Password',
      isRequired: true,
      placeholder: 'Enter your password',
    },
  },
  
  signUp: {
    preferred_username: {
      order: 1,
      label: 'Username',
      isRequired: true,
      placeholder: 'Enter your username (cannot be changed)',
    },
    username: {
      order: 2,
      label: 'Confirm Username',
      isRequired: true,
      placeholder: 'Enter your username again (cannot be changed)',
    },
    email: {
      order: 3,
      label: 'Email',
      isRequired: true,
      placeholder: 'Enter your email',
    },
    password: {
      order: 4,
      label: 'Password',
      isRequired: true,
      placeholder: 'Enter your password',
    },
    confirm_password: {
      order: 5,
      label: 'Confirm Password',
      isRequired: true,
      placeholder: 'Re-enter your password',
    },
  },
};


function Login() {
  const { tokens } = useTheme();
  const theme: Theme = {
    name: 'Auth Example Theme',
    tokens: {
      components: {
        authenticator: {
          router: {
            boxShadow: `0 0 16px ${tokens.colors.overlay['10']}`,
            borderWidth: '0',
          },
        },
        button: {
          primary: {
            backgroundColor: tokens.colors.neutral['100'],
          },
          link: {
            color: tokens.colors.neutral['100'],
          },
        },
        fieldcontrol: {
          _focus: {
            boxShadow: `0 0 0 2px ${tokens.colors.neutral['100']}`,
          },
        },
        tabs: {
          item: {
            color: tokens.colors.neutral['100'],
            _active: {
              borderColor: tokens.colors.neutral['100'],
              color: tokens.colors.neutral['100'],
            },
          },
        },
      },
    },
  };

  return (
    <ThemeProvider theme={theme}>
    <Stack style={{width: '100%', padding: 30, alignContent: 'center', alignItems: 'center'}}>
    <Logo/>

    {/* <Stack style={{marginTop: 20}}>
    <NoDrops backgroundColor='white' textColor='black'/>
    </Stack> */}
    </Stack>
    <Authenticator className='authenticator' 
    formFields={formFields}
    initialState='signIn'
    >
      <Navigate to={`/myaccount/`} replace />
    </Authenticator>
    </ThemeProvider>
  );
}

export default Login;
