import { Box, Button, MobileStepper, Stack } from '@mui/material';
import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PostType } from '../../API.ts';
import PostForm from '../../components/PostForm.tsx';
import { CreatePostProvider } from '../../contexts/CreatePostContext.tsx';
import { useAlert, useCreatePost } from '../../hooks/index.ts';
import ImageSelector from './ImageSelector.tsx';
// import { useAuth } from '../../contexts/AuthContext.tsx';
// import { UserContext } from '../../contexts/UserContext';
import {useAuth} from '../../hooks/index.ts';
import { UserContext } from '../../contexts/UserContext.tsx';
import DropForm from './DropSelection.tsx';

const stripe = require('stripe')('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');

interface StepButton {
  readonly label: string;
  readonly disabled: boolean;
  readonly onClick?: () => void;
}

interface StepProps {
  readonly label: string;
  readonly component: ReactNode;
  readonly backButton: StepButton;
  readonly nextButton: StepButton;
}


function CreatePost() {
  const navigate = useNavigate();
  const [RCFiles, setRCFiles] = useState<any>([]);
  const [RCStyleFiles, setRCStyleFiles] = useState<any>([]);
  const [activeStep, setActiveStep] = useState(0);
  const {user: authUser} = useAuth();
  const {nextDrop, userLoading} = useContext(UserContext);
  const [disabled, setDisabled] = useState(false);

  const { addAlert } = useAlert();
  const {
    files,
    styleFiles,
    type,
    dropInputs,
    inputs,
    itemPosts,
    taggedPosts,
    getDropInputValue,
    setInputValue,
    setDropInputValue,
    setFiles,
    setStyleFiles, 
    setTaggedPosts,
    validateInputs,
    validateDropInputs,
    savePost,
    putDrop
  } = useCreatePost();

  const handleBack = useCallback(
    () => setActiveStep((prevActiveStep) => prevActiveStep - 1),
    []
  );

  const handleNext = useCallback(
    () => setActiveStep((prevActiveStep) => prevActiveStep + 1),
    []
  );


  const handleSubmit = useCallback(async () => {
    const isValid = validateInputs();
    // setDropInputValue('dateTime', dropInputs.dateTime?.value.toISOString())
    let useDrop;
    // console.log('next drop', nextDrop);

    if (nextDrop) {
      useDrop = nextDrop;
    } else {
      const isDropValid = validateDropInputs();
      console.log(isDropValid);
      if (!isDropValid){
        addAlert('error', 'Please fill out all required inputs');
        setDisabled(false);
        return;
      } 
      const drop = await putDrop();
      useDrop = drop;
    }
    if (!isValid){
      addAlert('error', 'Please fill out all required inputs');
      setDisabled(false);
      return;
    } 
    const post = await savePost(useDrop);

    setDisabled(false);
    addAlert('success', 'Your post has been shared.');
    navigate(`/post/${post.id}`);
  }, [validateInputs, validateDropInputs, dropInputs, setDropInputValue, savePost, addAlert, navigate, setDisabled]);

  const step1: StepProps = useMemo(
    () => ({
      label: 'Upload an image',
      component: (
      <Stack style={{width: '100vw', minHeight: '100vh'}}>
        <ImageSelector RCFiles={RCFiles} setRCFiles={setRCFiles} RCStyleFiles={RCStyleFiles as any} setRCStyleFiles={setRCStyleFiles}/>
      </Stack>
      ),
      backButton: {
        label: 'Start',
        disabled: true,
        onClick: handleBack,
      },
      nextButton: {
        label: 'Next',
        disabled: RCFiles.length + RCStyleFiles.length < 5 || RCFiles.length < 5,
        onClick: handleNext,
      },
    }),
    [files, styleFiles, handleNext, dropInputs, setDropInputValue, setFiles, setStyleFiles]
  );

  const step0: StepProps = useMemo(
    () => ({
      label: 'Set Your Drop',
      component: (
        <DropForm 
          dropInputs={dropInputs}
          setDropInputValue={setDropInputValue} 
          getDropInputValue={getDropInputValue}/>),
      backButton: {
        label: 'Back',
        disabled: false,
        onClick: handleBack,
      },
      nextButton: {
        label: 'Next', //type && type === PostType.ITEM ? 'Next' : 'Done',
        disabled: !nextDrop && (!getDropInputValue('name') || !getDropInputValue('launchTime')), //type && type === PostType.ITEM ? !inputs.name?.value || !inputs.category?.value || !inputs.designer?.value : true,
        onClick: handleNext,
      },

    }), [handleNext, dropInputs, setDropInputValue]
  )

  const step3: StepProps = useMemo(
    () => ({
      label: 'Add post details',
      component: (
        <PostForm
          type={type || PostType.ITEM}
          inputs={inputs}
          itemPosts={itemPosts}
          taggedPosts={taggedPosts}
          handleSubmit={handleSubmit}
          setTaggedPosts={setTaggedPosts}
          setInputValue={setInputValue}
          dropInputs={dropInputs}
          setDropInputValue={setDropInputValue}
          disabled={disabled}
          setDisabled={setDisabled}
        />
      ),
      backButton: {
        label: 'Back',
        disabled: false,
        onClick: handleBack,
      },
      nextButton: {
        label: type && type === PostType.ITEM ? 'Next' : 'Done',
        disabled: type && type === PostType.ITEM ? !inputs.name?.value || !inputs.category?.value || !inputs.designer?.value : true,
        onClick: handleSubmit,
      },
    }),
    [
      type,
      inputs,
      itemPosts,
      taggedPosts,
      setTaggedPosts,
      setInputValue,
      handleBack,
      handleNext,
      handleSubmit,
    ]
  ); 


  const steps: StepProps[] = useMemo(
    () => [step1, step0, step3],
    [step0, step1, step3]
  );

  const currentStep = steps[activeStep];

  if (userLoading) return null;

  return (
    <Stack style={{width: '100vw', alignItems: 'center'}}>
      <MobileStepper
        variant='progress'
        steps={3}
        position='static'
        activeStep={activeStep}
        style={{alignSelf: 'center'}}
        backButton={
          <Button
            size='small'
            onClick={currentStep.backButton.onClick}
            disabled={currentStep.backButton.disabled}
          >
            {currentStep.backButton.label}
          </Button>
        }
        nextButton={
          <Button
            size='small'
            onClick={currentStep.nextButton.onClick}
            disabled={currentStep.nextButton.disabled}
          >
            {currentStep.nextButton.label}
          </Button>
        }
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minWidth: 300,
          width: '100%',
          alignSelf: 'center',
          alignContent: 'center'
        }}
      >
        {currentStep.component}
      </Box>
    </Stack>
  );
}

export default function CreatePostWrapper() {
  return (
    <CreatePostProvider>
      <CreatePost />
    </CreatePostProvider>
  );
}