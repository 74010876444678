import React, { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider, Typography, createTheme } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/system';
// import { useAuth } from '../../contexts/AuthContext.tsx'; 
import useAuth from '../../hooks/useAuth.ts';
import AppBar from '../protected/AppBar.tsx';
import Container from '../protected/Container.tsx';
import Navigation from '../protected/Navigation.tsx';
import { UserProvider } from '../../contexts/UserContext.tsx';
import { ConfigProvider } from 'antd';
import Footer from '../../components/Footer.tsx';


function PublicLayout() {
  const { user, authMode, cognitoUserInfo, checkUser } = useAuth();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navRef = useRef<HTMLDivElement | null>(null);

  const theme2 = createTheme({
    palette: {
      primary: {
        main: 'rgb(0, 0, 0)',
      },
      secondary: {
        main: 'rgb(0, 0, 0)',
      },
    },
  });

  useEffect(() => {
      checkUser();

  }, []);

  if (user)
    return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: 'black',
          borderRadius: 2,

          // Alias Token
        },
      }}
    >
      <ThemeProvider theme={theme2}>
      <UserProvider cognitoUserInfo={cognitoUserInfo}>
      <AppBar />
      <Container>
        <Outlet />
        <Footer />
     </Container>
      {/* {isSmallScreen ? <Navigation ref={navRef} /> : null} */}
      </UserProvider>
          </ThemeProvider>
    </ConfigProvider>

    )
  else return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: 'black',
          borderRadius: 2,

          // Alias Token
        },
      }}
    >
      <ThemeProvider theme={theme2}>
      <AppBar />
      <Container>
        <Outlet />
        <Footer/>
      </Container>
          </ThemeProvider>
    </ConfigProvider>
    )
  

}

export default PublicLayout;
