import React, { createContext, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { AuthUser, getCurrentUser, fetchUserAttributes, signIn, signOut, FetchUserAttributesOutput, updatePassword, type UpdatePasswordInput } from 'aws-amplify/auth';
import { CognitoUserAttributes, CognitoUserInfo } from '../utils/types';

interface ContextType {
  readonly cognitoUserInfo?: FetchUserAttributesOutput | null | undefined;
  readonly user?: AuthUser | null;
  readonly authMode?: string;
  readonly userLoading: Boolean;
  readonly handleSignIn: (username: string, password: string) => void;
  readonly handleSignOut: () => void;
  readonly loadCognitoUserInfo: () => void;
  readonly checkUser: () => void;
  readonly handleUpdatePassword: (oldPassword: string, newPassword: string) => void;

}


const defaultContextValue = {
  handleSignIn: async () => {},
  handleSignOut: async () => {},
  userLoading: false,
  loadCognitoUserInfo: () => {},
  checkUser: () => {},
  handleUpdatePassword: () => {},

};
export const AuthContext = createContext<ContextType>(defaultContextValue);

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState<AuthUser | null>();
  const [userLoading, setUserLoading] = useState<Boolean>(true);
  const [cognitoUserInfo, setCognitoUserInfo] = useState<FetchUserAttributesOutput | null>();
  const [authMode, setAuthMode] = useState<string>();

  const loadCognitoUserInfo = useCallback(async () => {
    fetchUserAttributes()
      .then((userInfo) => {
        setCognitoUserInfo(userInfo);
      })
      .finally(() => {
        setUserLoading(false);
      });
  }, []);

  useEffect(() => {
    checkUser(); // On component mount, check if the user is already authenticated
  }, []);

  const handleUpdatePassword = useCallback(async (oldPassword: string,
    newPassword: string
  ) => {
    try {
      await updatePassword({ oldPassword, newPassword });
    } catch (err) {
      console.log(err);
    }
  }, [])

  const checkUser = useCallback(async ()=>{
    try {
      setUserLoading(true);
      const userData = await getCurrentUser();
      await loadCognitoUserInfo();
      setAuthMode('userPool');
      setUser(userData);
      setUserLoading(false);
    } catch (error) {
      setAuthMode('iam');
      setUser(null);
      setUserLoading(false);
    }
  }, [user, getCurrentUser, setUser, loadCognitoUserInfo]) 

  const handleSignIn = useCallback(async (username, password) => {
    try {
      await signIn({username, password}).then((response) => {checkUser()})
        .finally(() => console.log('Done'));
    } catch (error) {
      console.error('Error signing in:', error);
    }
  }, [signIn, checkUser, loadCognitoUserInfo])

  const handleSignOut = useCallback(async () => {
    try {
      await signOut();
      setAuthMode('iam');
      setUser(null);
      setCognitoUserInfo(null);

    } catch (error) {
      console.error('Error signing out:', error);
    }
  }, [signOut, setUser, setCognitoUserInfo])


  const value = useMemo(
    () => ({
      user,
      handleSignIn,
      handleSignOut,
      userLoading,
      cognitoUserInfo,
      loadCognitoUserInfo,
      authMode,
      checkUser,
      handleUpdatePassword, 
    }),
    [
      user,
      handleSignIn,
      handleSignOut,
      userLoading,
      cognitoUserInfo,
      loadCognitoUserInfo,
      authMode,
      checkUser,
      handleUpdatePassword
    ]
  );

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
